<!-- inactive remediation with dependency confirmation model-->
<modal #disableWithDependencyModal style="overflow-y: scroll;" data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]=" false ">
		<h4 class="modal-title ">Disable {{ modal.title }}</h4>
	</modal-header>
	<modal-body>
		<p *ngIf="modal.title === 'Org Code' && hasOtherChanges">You are not allowed to disable this {{modal.bodyText}} due to following dependencies. Other changes will be applied.</p>
		<p *ngIf="modal.title === 'Org Code' && !hasOtherChanges">You are not allowed to disable this {{modal.bodyText}} due to following dependencies.</p>
		<p *ngIf="modal.title !== 'Org Code'">Are you sure you want to disable this {{ modal.bodyText }}?</p>
    
    	<p *ngIf="modal.title !== 'Org Code'">Following dependencies are found.</p>
		<ul class="list-group ">
			<li class="list-group-item " [ngClass]="{ 'list-group-item-warning': item.type !='' && item.type !='more' , 'list-group-item-primary' : item.value !='' } "
			 *ngFor="let item of modal.dependency ">
				<div *ngIf="item.type !='' && item.type !='more' ">
					<b>{{item.type}}</b>
				</div>
				<div *ngIf="item.value !=''">
					<p class="break-out">
						<i>{{ item.value }}</i>
					</p>
				</div>
			</li>
		</ul>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button *ngIf="modal.title !== 'Org Code'" type="button " class="btn btn-danger " data-dismiss="modal " (click)="disableWithDependencyModal.dismiss();statusChanege() ">Disable</button>
		<button  *ngIf="modal.title !== 'Org Code'" type="button " class="btn btn-secondary " data-dismiss="modal " (click)="disableWithDependencyModal.dismiss() ">Cancel</button>
		<button *ngIf="modal.title === 'Org Code'" type="button " class="btn " data-dismiss="modal " (click)="disableWithDependencyModal.dismiss() ">OK</button>
	</modal-footer>

</modal>

<modal #deleteCancellationModal style="overflow-y: scroll;" data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">Remove {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p> You are not allowed to remove this {{modal.bodyText}} due to following dependencies. </p>
		<ul class="list-group ">
			<li class="list-group-item " [ngClass]="{ 'list-group-item-warning': item.type !='' && item.type !='more' , 'list-group-item-primary' : item.value !='' } "
			 *ngFor="let item of modal.dependency ">
				<div *ngIf="item.type !='' && item.type !='more' ">
					<b>{{item.type}}</b>
				</div>
				<div *ngIf="item.value !=''">
					<p class="break-out">
						<i>{{ item.value }}</i>
					</p>
				</div>
			</li>
		</ul>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn " data-dismiss="modal " (click)="deleteCancellationModal.dismiss() ">OK</button>
	</modal-footer>
</modal>

<modal #copytModal style="overflow-y: scroll;" data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">copy {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p> You are not allowed to copy following items due to org code mismatch. </p>
		<ul class="list-group ">
			<li class="list-group-item " [ngClass]="{ 'list-group-item-warning': item.type !='' && item.type !='more' , 'list-group-item-primary' : item.value !='' } "
			 *ngFor="let item of modal.dependency ">
				<div *ngIf="item.type !='' && item.type !='more' ">
					<b>{{item.type}}</b>
				</div>
				<div *ngIf="item.value !=''">
					<p class="break-out">
						<i>{{ item.value }}</i>
					</p>
				</div>
			</li>
		</ul>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn " data-dismiss="modal " (click)="copytModal.dismiss() ">OK</button>
		<button type="button" class="btn" (click)="exportToExcel('Missing items of the job profile copy', modal.allItems)">
			<i class="fa fa-file-excel-o fa-fw"></i>&nbsp;Export to Excel
		</button>
		<button type="button" class="btn" (click)="print(modal.allItems)">
			<i class=" fa fa-print fa-fw "></i>&nbsp;Print
		</button>
	</modal-footer>
</modal>


<modal #deleteModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">Remove {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p> Are you sure you want to remove this {{modal.bodyText}}? </p>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn btn-danger " data-dismiss="modal " (click)="deleteModal.dismiss();delete() ">Remove</button>
		<button type="button " class="btn btn-secondary " data-dismiss="modal " (click)="deleteModal.dismiss() ">Cancel</button>
	</modal-footer>
</modal>

<modal #statusChangeModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">{{statusTitle}} {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p>Are you sure you want to {{statusText}} this {{modal.bodyText}}?</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button *ngIf="modal.status==0 " type="button " class="btn btn-success " data-dismiss="modal " (click)="statusChangeModal.dismiss();statusChanege() ">Enable</button>
		<button *ngIf="modal.status==1 " type="button " class="btn btn-danger " data-dismiss="modal " (click)="statusChangeModal.dismiss();statusChanege() ">Disable</button>
		<button type="button " class="btn btn-secondary " data-dismiss="modal " (click)="statusChangeModal.dismiss() ">Cancel</button>
	</modal-footer>
</modal>


<modal #dependencyModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">{{modal.title}} Dependencies</h4>
	</modal-header>
	<modal-body>
		<p>Org code change is restricted due to following dependencies of this {{modal.bodyText}} </p>
		<ul class="list-group ">
			<li class="list-group-item " [ngClass]="{ 'list-group-item-warning': item.type !='' && item.type !='more' , 'list-group-item-primary' : item.value !='' } "
			 *ngFor="let item of modal.dependency ">
				<div *ngIf="item.type !='' && item.type !='more' ">
					<b>{{item.type}}</b>
				</div>
				<div *ngIf="item.value !=''">
					<p class="break-out">
						<i>{{ item.value }}</i>
					</p>
				</div>
			</li>
		</ul>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn " data-dismiss="modal " (click)="dependencyModal.dismiss() ">OK</button>
	</modal-footer>
</modal>

<modal #showHideChangeModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">{{statusTitle}} {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p>Are you sure you want to {{statusText}} this {{modal.bodyText}}?</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button *ngIf="modal.status==0 " type="button " class="btn btn-success " data-dismiss="modal " (click)="showHideChangeModal.dismiss();showHideChanege() ">Show</button>
		<button *ngIf="modal.status==1 " type="button " class="btn btn-danger " data-dismiss="modal " (click)="showHideChangeModal.dismiss();showHideChanege() ">Hide</button>
		<button type="button " class="btn btn-secondary " data-dismiss="modal " (click)="showHideChangeModal.dismiss() ">Cancel</button>
	</modal-footer>
</modal>

<modal #defaultChangeModal data-backdrop="static " data-keyboard=false>
	<modal-header [show-close]="false ">
		<h4 class="modal-title ">Change Default {{modal.title}}</h4>
	</modal-header>
	<modal-body>
		<p>Are you sure you want to change the default to this {{modal.bodyText}}?</p>
	</modal-body>
	<modal-footer [show-default-buttons]="false ">
		<button type="button " class="btn btn-warning " data-dismiss="modal " (click)="defaultChangeModal.dismiss();setDefaultReason() ">Change</button>		
		<button type="button " class="btn btn-secondary " data-dismiss="modal " (click)="defaultChangeModal.dismiss() ">Cancel</button>
	</modal-footer>
</modal>