import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";

//model files
import { JobProfile } from './jobprofile.model';
// import { JPCCModel } from './jpccmodel.model';
import { CoreCompetency } from '../complib/corecompetency/corecompetency.model';
import { Task } from '../complib/task/task.model';
import { Performance } from '../complib/performance/performance.model';
import { Knowledge } from '../complib/knowledge/knowledge.model';
import { CheckList } from '../complib/checklist/checklist.model';
import { RegulatoryAgency } from '../complib/regulatoryagency/regulatoryagency.model'
import { remediation } from '../complib/remediation/remediation';
import { JobProfileService } from './jobprofile.service';
import { PerformanceService } from '../complib/performance/performance.service';
import { AlertService } from '../util/alert/alert.service';
import { SharedService } from '../home/shared.service';
import { UserService } from '../user/user.service';
import { KnowledgeService } from '../complib/knowledge/knowledge.service';
import { ChecklistService } from '../complib/checklist/checklist.service';
import { RemediationService } from '../complib/remediation/remediation.service';

import { OrgcodeService } from '../orgcode/orgcode.service';
import { DisciplineService } from '../discipline/discipline.service';
import { CoreCompetencyService } from '../complib/corecompetency/corecompetency.service';
import { TaskService } from '../complib/task/task.service';
import { CustomModalService } from "../util/custommodal/custommodal.service";
import { NoWhitespaceDirective } from "../util/validator/nowhitespace.directive"
import { Constants } from "../util/const/constants"
import { SanitizationService } from '../sanitizer.service';

@Component({
	selector: 'app-addeditjobprofile',
	templateUrl: './addeditjobprofile.component.html',
	styleUrls: ['./addeditjobprofile.component.scss']
})
export class AddEditJobProfileComponent implements OnInit {

	addJobProfileForm: FormGroup;
	editJobProfileForm: FormGroup;

	public jobProfileId: string;

	public jobProfileOperation: string = 'ADD';
	public componentTopic: string = 'Add New Job Profile'

	public showDisabledForm: boolean = false;
	public savedDescription: string;
	public savedOrgCode: string;
	public showTableData: boolean = false;
	public tableTopic: string = "";
	//used to check which item that user is going to add from table
	public selectedItem: string = "";

	//use these variable to load add new components dynamically
	public showingComponent: string = 'AddJobProfile';
	public selectedMenuItem: string = "";

	//check capability to add new items
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public isAddCompLib: boolean;

	public jobProfileModel: JobProfile;
	public selectedCCModel: any;
	public selectedTaskModel: any;
	public selectedPerformanceModel: any;
	public selectedSkillModel: any;

	public selectedOrgId: string;
	public selectedDisciplineId: string;

	public orgList: Array<any> = [];
	public disciplineList: Array<any> = [];
	public jobProfileList: Array<any> = [];

	public showingItems: any = []
	public status: any = 0;
	public copyClicked: boolean = false;
	public copyToJpId: any = -1;
	isLoading = false;

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private userService: UserService,
		private jobProfileService: JobProfileService,
		private performanceService: PerformanceService,
		private orgCodeService: OrgcodeService,
		private disciplineService: DisciplineService,
		private corecompetencyServive: CoreCompetencyService,
		private taskService: TaskService,
		private knowledgeService: KnowledgeService,
		private checkListService: ChecklistService,
		private remediationService: RemediationService,
		private customModalService: CustomModalService,
		private fb: FormBuilder,
		private sanitizer: SanitizationService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}
		this.isAddCompLib = this.userService.isCapabilityAssigned('AddCompLib', this.selectedUserRole[0][1]);

		this.jobProfileOperation = this.jobProfileService.getjobProfileOperation()
		if (this.jobProfileOperation == 'ADD') {
			this.componentTopic = 'Add New Job Profile';
			this.jobProfileModel = new JobProfile('');
			this.jobProfileModel.coreCompetencyList = [];
		} else {
			//console.log('EDIT' + JSON.stringify(this.jobProfileService.getSelectedJobProfileForEdit()).toString())
			this.componentTopic = 'Edit Job Profile'
			this.jobProfileModel = this.jobProfileService.getSelectedJobProfileForEdit();
			this.status = this.jobProfileModel.jobProfileStatus;
			this.jobProfileId = this.jobProfileModel.jobProfileId
			this.showDisabledForm = true;
			this.savedDescription = this.jobProfileModel.description;
			this.savedOrgCode = this.jobProfileModel.orgCodeText;
		}

		this.getActiveOrgCodes();
		this.getActiveDisciplines();
		this.getAllJobProfiles();
		this.selectedMenuItem = localStorage.getItem('selectedMenuItem');

	}

	copyExistingJobProfile() {
		this.copyClicked = true;

	}

	public selectedJobProfile(value: any) {
		this.copyToJpId = value.id;

	}

	copy() {
		this.isLoading = true
		this.jobProfileService.CopyActiveElementsOfJobProfile(this.jobProfileId, this.copyToJpId, this.jobProfileModel.orgCodeId)
			.subscribe(
			data => {				
				this.copyToJpId = -1;
				if (data.outPutItmes.length > 0) {
					this.customModalService.invokeCopy(data.outPutItmes, "Job Profile", "-1", this,
						"copy", null, data.allOutputItems);
				}
				this.copyClicked = false;
				let items = JSON.parse(localStorage.getItem('showingItems'))
				let ccList = this.jobProfileModel.coreCompetencyList;
				for (let i = 0; i < data.outputValue.length; i++) {
					let ccItem = null;
					let task = null;
					let performance = null;
					let knowledge = null;
					let checkList = null;
					if (data.outputValue[i].type == 'cc') {
						ccItem = new CoreCompetency(data.outputValue[i]["ccItem"].id, data.outputValue[i]["ccItem"].text);
						ccItem.taskList = [];
						ccItem.taskTotal = this.generateTasktotal(data.outputValue[i].taskList);
						ccList.push(ccItem);
					}
					else {
						if(data.outputValue[i].type == 'task'){
							ccItem = ccList.filter(item => item.id == data.outputValue[i]["ccItem"].id)[0];
							let preTaskTotal;
							if(ccItem.taskTotal == null){
								preTaskTotal = 0
							}else{
								preTaskTotal = ccItem.taskTotal
							}
							let taskTotal = this.generateTasktotal(data.outputValue[i].taskList) + preTaskTotal
							ccItem.taskTotal =  taskTotal;
						}
					}
					if (data.outputValue[i].type == 'cc' || data.outputValue[i].type == 'task') {
						let taskGroupTasks = [];
						for (let j = 0; j < data.outputValue[i]["taskList"].length; j++) {
							let itemTask = null;
							if (data.outputValue[i]["taskList"][j].isTaskGroup == 0) {
								itemTask = new Task(data.outputValue[i]["taskList"][j].id, data.outputValue[i]["taskList"][j].text, false, [], [], []);
								itemTask.regulatoryList = []
								itemTask.knowledgeQuestionList = []
								itemTask.taskList = []
								itemTask.orderNum = data.outputValue[i]["taskList"][j].orderNum
								itemTask.isTaskGroup = data.outputValue[i]["taskList"][j].isTaskGroup;
								itemTask.criticalityValue = data.outputValue[i]["taskList"][j].criticality
								itemTask.taskGroupId = data.outputValue[i]["taskList"][j].taskGroupId
								itemTask.tgId = data.outputValue[i]["taskList"][j].TGId
								itemTask.jpccId = data.outputValue[i]["taskList"][j].JPCCId
								itemTask.jptId = data.outputValue[i]["taskList"][j].JPTId
							}
							else {
								itemTask = new Task(data.outputValue[i]["taskList"][j].JPTId);
								itemTask.orderNum = data.outputValue[i]["taskList"][j].orderNum;
								itemTask.isTaskGroup = true
								itemTask.criticalityValue = 1;
								itemTask.tgId = data.outputValue[i]["taskList"][j].TGId
								itemTask.taskGroup = data.outputValue[i]["taskList"][j].TaskGroupText
								itemTask.jpccId = data.outputValue[i]["taskList"][j].JPCCId
								itemTask.criticalityValue = data.outputValue[i]["taskList"][j].criticality
								itemTask.taskList = []
							}

							if (ccItem != undefined) {
								if (itemTask.taskGroupId == null) {
									ccItem.taskList.push(itemTask)
								}
								else {
									taskGroupTasks.push(itemTask)
								}
							}
						}

						for (let j = 0; j < taskGroupTasks.length; j++) {
							if (ccItem != undefined) {								
								let taskGroup = null
								if (data.outputValue[i].type == 'task') {
									taskGroup = ccItem.taskList.filter(item => item.id == taskGroupTasks[j].taskGroupId)[0];
								}
								else {
									taskGroup = ccItem.taskList.filter(item => item.id == taskGroupTasks[j].taskGroupId)[0];
								}
								if (taskGroup != undefined) {
									taskGroup.taskList.push(taskGroupTasks[j])
								}
							}
						}
					
					}
					else {
						if (data.outputValue[i]["taskList"][0].taskGroupId == "null" || data.outputValue[i]["taskList"][0].taskGroupId == null)  // normal task
						{
							task = ccItem.taskList.filter(item => item.id == data.outputValue[i]["taskList"][0].id)[0];
						}
						else { // task group
							let taskGroup = ccItem.taskList.filter(item => item.id == data.outputValue[i]["taskList"][0].taskGroupId)[0];
							if (taskGroup != undefined) {
								task = taskGroup.taskList.filter(item => item.id == data.outputValue[i]["taskList"][0].id)[0];
							}
						}
					}

					if (data.outputValue[i].type == 'cc' || data.outputValue[i].type == 'task' || data.outputValue[i].type == 'performance') {

						for (let j = 0; j < data.outputValue[i]["regulatoryAgencyList"].length; j++) {
							let itemRegulatory = new RegulatoryAgency(data.outputValue[i]["regulatoryAgencyList"][j].id, data.outputValue[i]["regulatoryAgencyList"][j].description, 
							data.outputValue[i]["regulatoryAgencyList"][j].shortName, data.outputValue[i]["regulatoryAgencyList"][j].text);							
							itemRegulatory.jptId = data.outputValue[i]["regulatoryAgencyList"][j].JPTId
							itemRegulatory.jppId = data.outputValue[i]["regulatoryAgencyList"][j].JPPId
						
							if (task != undefined) {
								task.regulatoryList.push(itemRegulatory)
							}
							else {
								let newTask = ccItem.taskList.filter(item => item.jptId == itemRegulatory.jptId)[0]; // normal Task
								if (newTask == undefined) { // task Group Task 

									newTask = ccItem.taskList.filter(item => item.jptId == itemRegulatory.jptId)[0]; // normal Task
									if (newTask == undefined) {
										let taskGroups = ccItem.taskList.filter(item => item.isTaskGroup == 1);
										for (let tg = 0; tg < taskGroups.length; tg++) {
											newTask = taskGroups[tg].taskList.filter(item => item.jptId == itemRegulatory.jptId)[0];
											//console.log(task)
											if (newTask != undefined) {
												newTask.regulatoryList.push(itemRegulatory);
												break;
											}
										}
									}
									else {
										newTask.regulatoryList.push(itemRegulatory);
									}
								}
								else {
									newTask.regulatoryList.push(itemRegulatory);
								}
							}
						}

						for (let j = 0; j < data.outputValue[i]["performanceList"].length; j++) {
							let itemPerformance = new Performance(data.outputValue[i]["performanceList"][j].id, data.outputValue[i]["performanceList"][j].text, true, [], []);
							itemPerformance.orderNum = data.outputValue[i]["performanceList"][j].orderNum;
							itemPerformance.jptId = data.outputValue[i]["performanceList"][j].JPTId
							itemPerformance.jppId = data.outputValue[i]["performanceList"][j].JPPId
							//itemPerformance.jpccId = data.outputValue[i]["performanceList"][j].JPCCId
							if (task != undefined) {
								task.performanceList.push(itemPerformance)
							}
							else {
								let newTask = ccItem.taskList.filter(item => item.jptId == itemPerformance.jptId)[0]; // normal Task
								if (newTask == undefined) { // task Group Task 

									newTask = ccItem.taskList.filter(item => item.jptId == itemPerformance.jptId)[0]; // normal Task
									if (newTask == undefined) {
										let taskGroups = ccItem.taskList.filter(item => item.isTaskGroup == 1);
										for (let tg = 0; tg < taskGroups.length; tg++) {
											newTask = taskGroups[tg].taskList.filter(item => item.jptId == itemPerformance.jptId)[0];
											//console.log(task)
											if (newTask != undefined) {
												newTask.performanceList.push(itemPerformance);
												break;
											}
										}
									}
									else {
										newTask.performanceList.push(itemPerformance);
									}
								}
								else {
									newTask.performanceList.push(itemPerformance);
								}
							}
						}

						for (let j = 0; j < data.outputValue[i]["knowledgeList"].length; j++) {
							let knowledgeItem: Knowledge = new Knowledge(data.outputValue[i]["knowledgeList"][j].text, 0);
							knowledgeItem.id = data.outputValue[i]["knowledgeList"][j].id
							knowledgeItem.remediationList = []
							knowledgeItem.orderNum = data.outputValue[i]["knowledgeList"][j].orderNum;
							knowledgeItem.isTestQuestion = data.outputValue[i]["knowledgeList"][j].IsTestQuestion;
							knowledgeItem.jptId = data.outputValue[i]["knowledgeList"][j].JPTId
							knowledgeItem.jpkId = data.outputValue[i]["knowledgeList"][j].JPKId
							if (task != undefined) {
								if (knowledgeItem.isTestQuestion == 1) {
									task.knowledgeQuestionList.push(knowledgeItem);
								}
								else {
									task.knowledgeList.push(knowledgeItem);
								}
							}
							else {
								let newTask = ccItem.taskList.filter(item => item.jptId == knowledgeItem.jptId)[0]; // normal Task
								if (newTask == undefined) { // task Group Task 

									newTask = ccItem.taskList.filter(item => item.jptId == knowledgeItem.jptId)[0]; // normal Task
									if (newTask == undefined) {
										let taskGroups = ccItem.taskList.filter(item => item.isTaskGroup == 1);
										for (let tg = 0; tg < taskGroups.length; tg++) {
											newTask = taskGroups[tg].taskList.filter(item => item.jptId == knowledgeItem.jptId)[0];
											//console.log(task)
											if (newTask != undefined) {
												if (knowledgeItem.isTestQuestion == 1) {
													newTask.knowledgeQuestionList.push(knowledgeItem);
												}
												else {
													newTask.knowledgeList.push(knowledgeItem);
												}
												break;
											}
										}
									}
									else {
										if (knowledgeItem.isTestQuestion == 1) {
											newTask.knowledgeQuestionList.push(knowledgeItem);
										}
										else {
											newTask.knowledgeList.push(knowledgeItem);
										}
									}
								}
								else {
									if (knowledgeItem.isTestQuestion == 1) {
										newTask.knowledgeQuestionList.push(knowledgeItem);
									}
									else {
										newTask.knowledgeList.push(knowledgeItem);
									}
								}
							}
						}

						for (let j = 0; j < data.outputValue[i]["taskRemediationList"].length; j++) {
							let taskremediation: remediation = new remediation(data.outputValue[i]["taskRemediationList"][j].RemediationId, '', data.outputValue[i]["taskRemediationList"][j].text, '', '', '', '', '', '', []);
							taskremediation.jptid = data.outputValue[i]["taskRemediationList"][j].JPTId;
							taskremediation.text = data.outputValue[i]["taskRemediationList"][j].text
							if (task != undefined) {
								task.remediationList.push(taskremediation);
							}
							else {
								let newTask = ccItem.taskList.filter(item => item.jptId == taskremediation.jptid)[0]; // normal Task
								if (newTask == undefined) { // task Group Task 
									let taskGroups = ccItem.taskList.filter(item => item.isTaskGroup == 1);									
									for (let tg = 0; tg < taskGroups.length; tg++) {
										newTask = taskGroups[tg].taskList.filter(item => item.jptId == taskremediation.jptid)[0];
										//	console.log(task)
										if (newTask != undefined) {
											newTask.remediationList.push(taskremediation)
											break;
										}
									}
								}
								else {
									newTask.remediationList.push(taskremediation);
								}
							}
						}
					}
					else {
						if (data.outputValue[i]["performanceList"].length > 0) {
							performance = task.performanceList.filter(item => item.id == data.outputValue[i]["performanceList"][0].id)[0];
						}
						if (data.outputValue[i]["knowledgeList"].length > 0) {
							knowledge = task.knowledgeList.filter(item => item.id == data.outputValue[i]["knowledgeList"][0].id)[0];
							if (knowledge == undefined) {
								knowledge = task.knowledgeQuestionList.filter(item => item.id == data.outputValue[i]["knowledgeList"][0].id)[0];
							}
						}
					}

					if (data.outputValue[i].type == 'cc' || data.outputValue[i].type == 'task' || data.outputValue[i].type == 'performance' || data.outputValue[i].type == 'checkList') {

						for (let j = 0; j < data.outputValue[i]["checkList"].length; j++) {
							let checkListItem = new CheckList(data.outputValue[i]["checkList"][j].id, data.outputValue[i]["checkList"][j].text)
							checkListItem.remediationList = [];
							checkListItem.orderNum = data.outputValue[i]["checkList"][j].orderNum
							checkListItem.jppId = data.outputValue[i]["checkList"][j].JPPId
							checkListItem.JPCLId = data.outputValue[i]["checkList"][j].JPCLId

							if (performance != undefined) {
								performance.checkList.push(checkListItem);
							}
							else {
								for (let t = 0; t < ccItem.taskList.length; t++) {
									if (ccItem.taskList[t].isTaskGroup == 0) {
										let newPerformance = ccItem.taskList[t].performanceList.filter(item => item.jppId == checkListItem.jppId)[0];
										if (newPerformance != undefined) {
											newPerformance.checkList.push(checkListItem);
											break;
										}
									}
									else {
										for (let tg = 0; tg < ccItem.taskList[t].taskList.length; tg++) {

											let newPerformance = ccItem.taskList[t].taskList[tg].performanceList.filter(item => item.jppId == checkListItem.jppId)[0];
											if (newPerformance != undefined) {
												newPerformance.checkList.push(checkListItem);
												break;
											}

										}
									}
								}
							}
						}

						for (let j = 0; j < data.outputValue[i]["performanceRemediationList"].length; j++) {
							let performanceRemediation: remediation = new remediation(data.outputValue[i]["performanceRemediationList"][j].RemediationId, '', data.outputValue[i]["performanceRemediationList"][j].text, '', '', '', '', '', '', []);
							performanceRemediation.jppid = data.outputValue[i]["performanceRemediationList"][j].JPPId;
							performanceRemediation.text = data.outputValue[i]["performanceRemediationList"][j].text;

							if (performance != undefined) {
								performance.remediationList.push(performanceRemediation);
							}
							else {
								for (let t = 0; t < ccItem.taskList.length; t++) {
									if (ccItem.taskList[t].isTaskGroup == 0) {
										let newPerformance = ccItem.taskList[t].performanceList.filter(item => item.jppId == performanceRemediation.jppid)[0];
										if (newPerformance != undefined) {
											newPerformance.remediationList.push(performanceRemediation);
											break;
										}
									}
									else {
										for (let tg = 0; tg < ccItem.taskList[t].taskList.length; tg++) {
											let newPerformance = ccItem.taskList[t].taskList[tg].performanceList.filter(item => item.jppId == performanceRemediation.jppid)[0];
											if (newPerformance != undefined) {
												newPerformance.remediationList.push(performanceRemediation);
												break;
											}

										}
									}
								}
							}
						}
					}
					else if (data.outputValue[i].type == 'checkListRem') {
						checkList = performance.checkList.filter(item => item.id == data.outputValue[i]["checkList"][0].id)[0];
					}

					if (data.outputValue[i].type == 'cc' || data.outputValue[i].type == 'task' || data.outputValue[i].type == 'performance' || data.outputValue[i].type == 'checkList' || data.outputValue[i].type == 'checkListRem') {
						for (let j = 0; j < data.outputValue[i]["checkListRemediationList"].length; j++) {
							let checkListRemediation: remediation = new remediation(data.outputValue[i]["checkListRemediationList"][j].id, '', data.outputValue[i]["checkListRemediationList"][j].Description, '', '', '', '', '', '', []);
							checkListRemediation.JPCLId = data.outputValue[i]["checkListRemediationList"][j].JPCLId;
							checkListRemediation.text = data.outputValue[i]["checkListRemediationList"][j].text;

							if (checkList != undefined) {
								checkList.remediationList.push(checkListRemediation);
							}
							else {
								let newCheckList = null;
								for (let t = 0; t < ccItem.taskList.length; t++) {
									if (ccItem.taskList[t].isTaskGroup == 0) {

										for (let p = 0; p < ccItem.taskList[t].performanceList.length; p++) {
											//console.log("chPerformance" + JSON.stringify(chPerformance))
											newCheckList = ccItem.taskList[t].performanceList[p].checkList.filter(item => item.JPCLId == checkListRemediation.JPCLId)[0];
											if (newCheckList != undefined) {
												newCheckList.remediationList.push(checkListRemediation);
												break;
											}
										}
										if (newCheckList != undefined) {
											break
										}
									}
									else {
										for (let tg = 0; tg < ccItem.taskList[t].taskList.length; tg++) {

											for (let p = 0; p < ccItem.taskList[t].taskList[tg].performanceList.length; p++) {
												//console.log("chPerformance" + JSON.stringify(chPerformance))
												newCheckList = ccItem.taskList[t].taskList[tg].performanceList[p].checkList.filter(item => item.JPCLId == checkListRemediation.JPCLId)[0];
												if (newCheckList != undefined) {
													newCheckList.remediationList.push(checkListRemediation);
													break;
												}
											}

											if (newCheckList != undefined) {
												break
											}
										}
										if (newCheckList != undefined) {
											break
										}
									}
								}
							}
						}
					}

					if (data.outputValue[i].type == 'cc' || data.outputValue[i].type == 'task' || data.outputValue[i].type == 'performance' || data.outputValue[i].type == 'knowledgeRem') {
						for (let j = 0; j < data.outputValue[i]["knowledgeRemediationList"].length; j++) {
							let kowledgeRemediation: remediation = new remediation(data.outputValue[i]["knowledgeRemediationList"][j].RemediationId, '', data.outputValue[i]["knowledgeRemediationList"][j].Description, '', '', '', '', '', '', []);
							kowledgeRemediation.jpkid = data.outputValue[i]["knowledgeRemediationList"][j].JPKId;
							kowledgeRemediation.text = data.outputValue[i]["knowledgeRemediationList"][j].text

							if (knowledge != undefined) {
								knowledge.remediationList.push(kowledgeRemediation);
							}
							else {
								for (let t = 0; t < ccItem.taskList.length; t++) {
									if (ccItem.taskList[t].isTaskGroup == 0) {
										//	console.log("performance add checklist" + JSON.stringify(ccItem.taskList[t].performanceList))
										let newKnowledge = ccItem.taskList[t].knowledgeList.filter(item => item.jpkId == kowledgeRemediation.jpkid)[0];
										//	console.log( 'newKnowledge ' + newKnowledge)
										//console.log("performance add checklist" + JSON.stringify(ccItem.taskList[t].performanceList))
										if (newKnowledge != undefined) {
											newKnowledge.remediationList.push(kowledgeRemediation);
											break;
										}
										else {
											let newKnowledge = ccItem.taskList[t].knowledgeQuestionList.filter(item => item.jpkId == kowledgeRemediation.jpkid)[0];
											if (newKnowledge != undefined) {
												newKnowledge.remediationList.push(kowledgeRemediation);
												break;
											}
										}
									}
									else {
										for (let tg = 0; tg < ccItem.taskList[t].taskList.length; tg++) {

											let newKnowledge = ccItem.taskList[t].taskList[tg].knowledgeList.filter(item => item.jpkId == kowledgeRemediation.jpkid)[0];
											if (newKnowledge != undefined) {
												newKnowledge.remediationList.push(kowledgeRemediation);
												break;
											}
											else {
												let newKnowledge = ccItem.taskList[t].taskList[tg].knowledgeQuestionList.filter(item => item.jpkId == kowledgeRemediation.jpkid)[0];
												if (newKnowledge != undefined) {
													newKnowledge.remediationList.push(kowledgeRemediation);
													break;
												}
											}

										}
									}
								}
							}
						}
					}
				}
				this.isLoading = false
				if (data.outputValue.length > 0) {
					this.alertService.clear();
					this.alertService.success(Constants.SUCCESS_COPY_JOB_PROFILE)
				}
				else {
					this.alertService.clear();
					this.alertService.success(Constants.EMPTY_COPY_JOB_PROFILE)
				}
			},
			error => {
				this.isLoading = false
			}
			);
	}

	private generateTasktotal(taskList: any): any {
		let tempTaskList = []
		taskList.forEach(task => {
			if (task.taskGroupId == null) {
				tempTaskList.push(task.id)
			} else {
			
			}
		});
		return tempTaskList.length
	}

	onSubmitEditCopy() {
		this.isLoading = true;
		this.copy();
		this.isLoading = false;
	}

	onSubmitAddCopy() {
		this.isLoading = true;
		this.copy();
		this.isLoading = false;
	}

	public removedJobProfile(value: any): void {
		this.copyToJpId = -1;
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.addJobProfileForm = this.fb.group({
			description: new FormControl(null, Validators.required),
			orgId: new FormControl(null, Validators.required),
			disciplineId: new FormControl(null, Validators.required),
			frequency: new FormControl(null, [
				Validators.required
			])
		}, { validator: validFrequency('frequency') });
		this.editJobProfileForm = this.fb.group({
			editDescription: new FormControl(this.jobProfileModel.description, Validators.required),
			editDisciplineId: new FormControl(null, Validators.required),
			editFrequency: new FormControl(this.jobProfileModel.frequnecy, [
				Validators.required
			])
		}, { validator: validFrequency('editFrequency') });

		this.sharedService.getHideValue()
			.subscribe(item => this.hideValueFunction());

	}

	public hideValueFunction() {
		this.showingComponent = 'AddJobProfile'
		let items = JSON.parse(localStorage.getItem('showingItems'))
		if (items.length == 0) {

		} else {
			this.showingItems = items
		}
	}

	private getAllJobProfiles() {
		this.jobProfileService.getAllJobProfiles()
			.subscribe(
			data => {
				this.jobProfileList = [];				
				//this.selectedJobprofileId = '';
				for (var i = 0; i < data.jobprofileList.length; i++) {
					if (this.jobProfileId != data.jobprofileList[i].Id) {
						this.jobProfileList.push({ id: data.jobprofileList[i].Id, text: data.jobprofileList[i].Description + " - " + data.jobprofileList[i].OrgCode });
					}
				}
			},
			error => {
				
			}
			);
	}

	//get all active org codes
	private getActiveOrgCodes() {
		this.orgCodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				// console.log('[getActiveOrgCodes] data : ' + JSON.stringify(data) + '/(count)' + data.orgcode.length);
				this.orgList = [];
				for (let i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({
						id: data.orgcode[i].Id,
						text: data.orgcode[i].Description
					});
				}
				this.selectedOrgId = null;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}
	//get all active disciplines
	private getActiveDisciplines() {
		this.disciplineService.getActiveDisciplines()
			.subscribe(
			data => {
				// console.log('[getActiveDisciplines] data : ' + JSON.stringify(data) + '/(count)' + data.disciplineList.length);
				this.disciplineList = [];
				for (let i = 0; i < data.disciplineList.length; i++) {
					this.disciplineList.push({
						id: data.disciplineList[i].Id,
						text: data.disciplineList[i].Discipline
					});					
					if (this.jobProfileOperation != 'ADD' && (this.jobProfileModel.disciplineId == data.disciplineList[i].Id)) {						
						this.editJobProfileForm.get("editDisciplineId").setValue([{ id: data.disciplineList[i].Id, text: data.disciplineList[i].Discipline }])
					}
				}

			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	//submit main job profile properties to the DB
	onSubmit() {
		const description = this.sanitizer.sanitize(this.addJobProfileForm.value.description);
		let orgId = null;
		if (this.addJobProfileForm.get("orgId").value != null) {
			if (this.addJobProfileForm.get("orgId").value.length > 0) {
				orgId = this.addJobProfileForm.get("orgId").value[0].id;
			}
		}
		let disciplineId = null;
		if (this.addJobProfileForm.get("disciplineId").value != null) {
			if (this.addJobProfileForm.get("disciplineId").value.length > 0) {
				disciplineId = this.addJobProfileForm.get("disciplineId").value[0].id;
			}
		}
		const frequency = Number(this.addJobProfileForm.value.frequency);

		this.savedDescription = description;
		this.savedOrgCode = this.sanitizer.sanitize(this.orgList.find(x => x.id === orgId).text);
		const disciplineText = this.sanitizer.sanitize(this.disciplineList.find(x => x.id === disciplineId).text);

		const tempJobProfile = new JobProfile('', description, false, orgId, this.savedOrgCode,
			disciplineId, disciplineText, frequency, []);
		this.jobProfileModel = tempJobProfile;

		this.jobProfileService.addNewJobProfile(tempJobProfile)
			.subscribe(
			data => {
				this.jobProfileId = data.jobProfileId;
				this.jobProfileModel.jobProfileId = data.jobProfileId;
				this.alertService.clear();
				this.alertService.success(data.message);
				this.addJobProfileForm.reset();
				this.jobProfileService.setSelectedJobProfileForEdit(this.jobProfileModel);
				this.showDisabledForm = true;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	//submit edited job profile data to the DB
	onSubmitEdit() {
		// console.log('[onSubmitEdit] ' + this.editJobProfileForm.value.editDisciplineId);
		const description = this.sanitizer.sanitize(this.editJobProfileForm.value.editDescription);
		let disciplineId = null;
		if (this.editJobProfileForm.get("editDisciplineId").value != null) {
			if (this.editJobProfileForm.get("editDisciplineId").value.length > 0) {
				disciplineId = this.editJobProfileForm.get("editDisciplineId").value[0].id;
			}
		}
		const frequency = Number(this.editJobProfileForm.value.editFrequency);

		this.savedDescription = description;
		this.savedOrgCode = this.sanitizer.sanitize(this.jobProfileModel.orgCodeText);
		const disciplineText = this.sanitizer.sanitize(this.disciplineList.find(x => x.id === disciplineId).text);

		const tempJobProfile = new JobProfile(this.jobProfileModel.jobProfileId, description, this.jobProfileModel.jobProfileStatus,
			this.jobProfileModel.orgCodeId, this.jobProfileModel.orgCodeText, disciplineId, disciplineText, frequency);
		// console.log('[onSubmitEdit] ' + JSON.stringify(tempJobProfile).toString())
		this.jobProfileService.updateJobProfile(tempJobProfile)
			.subscribe(
			data => {
				this.jobProfileModel.description = this.sanitizer.sanitize(this.editJobProfileForm.value.editDescription);
				this.jobProfileModel.disciplineId = this.editJobProfileForm.get("editDisciplineId").value[0].id;
				this.jobProfileModel.frequnecy = this.editJobProfileForm.get("editFrequency").value;
				this.alertService.clear();
				this.alertService.success(data.message);
				this.showDisabledForm = true;
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			);
	}

	valid() {		
		if (this.editJobProfileForm.get("editDisciplineId").value[0] != undefined && this.editJobProfileForm.get("editDisciplineId").value != null && !this.editJobProfileForm.hasError('invalidFrequency') &&
			(this.editJobProfileForm.value.editDescription != this.jobProfileModel.description || (this.editJobProfileForm.get("editDisciplineId").value != null &&
				this.editJobProfileForm.get("editDisciplineId").value[0] != undefined && this.editJobProfileForm.get("editDisciplineId").value[0].id != this.jobProfileModel.disciplineId) || this.editJobProfileForm.get("editFrequency").value != this.jobProfileModel.frequnecy)) {
			return true;
		}
		return false;
	}

	//navigate to other components
	selectedComponentItem(itemName: string) {
		if (this.jobProfileOperation != 'ADD') {
			this.showDisabledForm = true;
		} else {
			this.sharedService.change(itemName);
		}
	}

	change() {
		this.copyClicked = false;
	}

	//Add new core competency button click event
	public addNewBtnClick(component: any) {
		this.showingComponent = component;
		// console.log('[addNewBtnClick] (selectedMenuItem) : ' + this.selectedMenuItem);
	}


	public editJPBtnClickEvent() {
		this.showDisabledForm = false;
	}

	public limitKeyPress(value, maxLength) {
		if (value != undefined && value.toString().length >= maxLength) {
			event.preventDefault();
		}
	}

	showJobProfileList() {
		this.sharedService.change("AllJobProfile")
	}

}

export function validFrequency(frequency: string) {
	return (group: FormGroup): { [key: string]: any } => {
		let freqValue = group.controls[frequency];
		if (!(freqValue.value > 0 && freqValue.value < 100)) {
			return {
				invalidFrequency: true
			};
		}
	}
}
