import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { ChecklistService } from './checklist.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';
import { UserService } from '../../user/user.service';
import { CustomModalService } from "../../util/custommodal/custommodal.service";
import { CheckList } from './checklist.model';
import { ChecklistTagService } from '../../tag/checklisttag/checklisttag.service';
import { TagInputModule } from 'ngx-chips';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-edit-checklist',
	templateUrl: './editchecklist.component.html'
})

export class EditChecklistComponent implements OnInit {
	editChecklistForm: FormGroup;
	public selectedOrgId: string;
	public orgList: Array<any> = [];
	public selectedTags = [];
	public activeTagList = [];
	items = [];
	public editChecklist: CheckList;
	public dependencyList: Array<any> = [];
	itemType = "Checklist";

	constructor(private router: Router, private sharedService: SharedService, private alertServices: AlertService,
		private checklistService: ChecklistService, private orgcodeService: OrgcodeService,
		private checklistTagService: ChecklistTagService,
		private userService: UserService, private customModalService: CustomModalService,private sanitizer: SanitizationService 
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.editChecklist = checklistService.getSelectedChecklistForEdit();
		this.checkForDependencies();
		this.editChecklist.tagList.forEach(tag => {
			let item = { value: tag.value, id: tag.id }
			this.items.push(item)
		});
	}

	checkForDependencies() {
		this.customModalService.invoke(
			this.itemType,
			this.editChecklist.id,
			this,
			"edit", null
		);
	}

	valid() {
		let result = false;

		if (this.editChecklistForm.get("checklist").status == "VALID") {
			result = true;
		}
		return result;
	}

	ngOnInit() {
		this.loadActiveChecklistTags();
		this.editChecklistForm = new FormGroup({
			id: new FormControl(this.editChecklist.id, Validators.required),
			checklist: new FormControl(this.editChecklist.text, Validators.required),
			orgId: new FormControl(null),
			checklistTag: new FormControl('')
		});

		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
					if (this.editChecklist.orgId != null) {
						if (data.orgcode[i].Id == this.editChecklist.orgId) {
							this.editChecklistForm.get("orgId").setValue([{ id: data.orgcode[i].Id, text: data.orgcode[i].Description }])
						}
					}
				}

				this.selectedOrgId = this.editChecklistForm.value.orgId;
				//console.log(this.selectedOrgId);
			},
			error => {
				//console.log(error);
			}
			);
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedtoUpdateChecklist()) {

			let orgId = null;
			if (this.editChecklistForm.get("orgId").value != null) {
				if (this.editChecklistForm.get("orgId").value.length > 0) {
					orgId = this.editChecklistForm.get("orgId").value[0].id;
				}
			}

			let tempChecklist = new CheckList(this.editChecklist.id, this.sanitizer.sanitize(this.editChecklistForm.value.checklist), orgId);
			tempChecklist.tagList = this.items;
			//console.log('[onSubmit]' + JSON.stringify(tempChecklist).toString());
			this.checklistService.editChecklist(tempChecklist)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success(data.message);
					this.editChecklist.text = this.editChecklistForm.value.checklist;
					this.editChecklist.orgId = orgId;
					this.editChecklist.tagList = []
					this.items.forEach(tag => {
						let item = { value: tag.value, id: tag.id }
						this.editChecklist.tagList.push(item)
					});
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			//no need to update performance
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	checkNeedtoUpdateChecklist(): boolean {

		let orgId = null;
		if (this.editChecklistForm.get("orgId").value != null) {
			if (this.editChecklistForm.get("orgId").value.length > 0) {
				orgId = this.editChecklistForm.get("orgId").value[0].id;
			}
		}
		if (this.editChecklist.text != this.editChecklistForm.value.checklist) {
			return true;
		} else if (this.compairArrayForChanges(this.editChecklist.tagList, this.items)) {
			return true;
		} else if (this.editChecklist.orgId != orgId) {
			return true;
		} else {
			return false;
		}
	}

	public compairArrayForChanges(arrayOld: any, arrayNew: any): boolean {
		//console.log('[compairArrayForChanges] (arrayOld) ' + JSON.stringify(arrayOld).toString())
		//console.log('[compairArrayForChanges] (arrayNew) ' + JSON.stringify(arrayNew).toString())
		let haveChanges: boolean = false
		if (arrayOld.length != arrayNew.length) {
			haveChanges = true
		} else {
			let missing = []
			arrayNew.forEach(newItem => {
				if (!(this.isValueExists(arrayOld, newItem.value))) {
					missing.push(newItem)
				}
			});
			if (missing.length == 0) {
				haveChanges = false
			} else {
				haveChanges = true
			}
		}
		return haveChanges;
	}

	public isValueExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.value == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	public loadActiveChecklistTags() {
		this.checklistTagService.getActiveChecklistTags()
			.subscribe(
			data => {
				//console.log('[loadActiveChecklistTags] ' + JSON.stringify(data).toString());
				this.activeTagList = []
				data.ccTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.activeTagList.push(item);
				});
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	public addTags(event: any) {
		this.items.push(event);
		// console.log('[addTags] (this.items) ' + JSON.stringify(this.items).toString());
	};

	public removeTags(event: any) {
		//console.log('[removeTags]' + JSON.stringify(event).toString());
		this.items = this.items.filter(tag => tag.id !== event.id);
	};

}
