import { Injectable } from '@angular/core';
import * as DOMPurify from 'dompurify';

@Injectable({
  providedIn: 'root'
})
export class SanitizationService {
  sanitize(input: string): string {
    
    let sanitized = DOMPurify.sanitize(input, {
      ALLOWED_TAGS: [], 
      FORBID_TAGS: ['*'], 
    });

    // remove event handler attributes 
    sanitized = sanitized.replace(/on\w+="[^"]*"/g, '');

    return sanitized;
  }
}

