import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { NAReason } from './nareason.model';
import { NAReasonService } from './nareason.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-editnareason',
	templateUrl: './editnareason.component.html'
})
export class EditNAReasonComponent implements OnInit {

	public editNAReason: NAReason;
	editNAReasonForm: FormGroup;

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private naReasonService: NAReasonService,
		private userService: UserService,
		private sanitizer: SanitizationService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		this.editNAReason = naReasonService.getSelectedReasonForEdit();
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.editNAReasonForm = new FormGroup({
			id: new FormControl(this.editNAReason.id, Validators.required),
			reason: new FormControl(this.editNAReason.text, Validators.required)
		});
	}

	//navigate to all note component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}


	onSubmit() {
		if (this.checkNeedtoUpdateReason()) {

			let tempReason = new NAReason(this.editNAReason.id, this.sanitizer.sanitize(this.editNAReasonForm.value.reason));
			this.naReasonService.updateNAReasonByReasonId(tempReason)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.editNAReason.text = this.sanitizer.sanitize(this.editNAReasonForm.value.note);

				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		} else {
			//no need to update note
			this.alertService.clear();
			this.alertService.error('There are no changes to update.');
		}
	}

	checkNeedtoUpdateReason(): boolean {
		if (this.editNAReason.text != this.editNAReasonForm.value.reason) {
			return true;
		} else {
			return false;
		}
	}

}
