import { Constants } from '../../util/const/constants';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';

import { user } from '../user';
import { UserService } from '../user.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-adduser',
	templateUrl: './adduser.component.html'
})
export class AddUserComponent implements OnInit {

	addUserForm: FormGroup;
	public orgList: Array<any> = [];
	public supervisorList: Array<any> = [];
	supervisorName: string;
	userId: string;
	assess: boolean;
	public jobprofileList: Array<any> = [];
	public selectedTimeZone: string;
	public timeZoneList: Array<any> = [];

	public selected(value: any): void {
		this.selectedTimeZone = value.text;
	}

	public removed(value: any): void {
		this.selectedTimeZone = "";
	}

	public refreshValue(value: any): void {
		this.selectedTimeZone = value.text;
	}

	constructor(
		private sharedService: SharedService, private userService: UserService, private fb: FormBuilder,
		private alertServices: AlertService, private orgcodeService: OrgcodeService, private jobProfileService: JobProfileService,private sanitizer: SanitizationService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
				}
			},
			error => {
			}
			);
	}

	public selectedOrgCode(value: any): void {
		this.getActiveJobprofile(value.id);
		this.addUserForm.get("jobprofile").setValue(null);
		this.jobprofileList = [];
	}

	public removedOrgCode(value: any): void {
		this.addUserForm.get("jobprofile").setValue(null);
		this.jobprofileList = [];
	}

	valid() {
		let result = false;

		if (this.addUserForm.get("firstName").status == "VALID" && this.addUserForm.get("lastName").status == "VALID" && this.addUserForm.get('email').status == "VALID" &&
			this.addUserForm.get("password").status == "VALID" && this.addUserForm.get("retypePwd").status == "VALID" && this.addUserForm.get("orgId").status == "VALID" && this.addUserForm.get("timeZone").status == "VALID" && this.addUserForm.get("assess").status == "VALID"
			&& this.addUserForm.get("retypePwd").value != '' && this.addUserForm.get("password").value != '' && this.addUserForm.get("password").value == this.addUserForm.get("retypePwd").value) {
			result = true;
		}
		return result;
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.

		this.userId = localStorage.getItem('userId');

		this.addUserForm = this.fb.group({
			firstName: new FormControl(null, Validators.required),
			lastName: new FormControl(null, Validators.required),
			email: new FormControl(null, [
				Validators.required,
				Validators.pattern("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])[.])+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?")
			]),
			password: new FormControl(null, Validators.required),
			retypePwd: new FormControl(null, Validators.required),
			orgId: new FormControl(null, Validators.required),
			supervisor: new FormControl(null),
			assess: new FormControl(null, Validators.required),
			jobprofile: new FormControl(null),
			timeZone: new FormControl(null, Validators.required),
			isContractUser: new FormControl(null),
		}, { validator: matchingPasswords('password', 'retypePwd') });

		// get all supervisor
		this.userService.getAllSupervisors("")
			.subscribe(
			data => {
				this.supervisorList = [];
				for (var i = 0; i < data.supervisorsdetails.length; i++) {
					this.supervisorName = data.supervisorsdetails[i].FirstName + ' ' + data.supervisorsdetails[i].LastName;
					this.supervisorList.push({ id: data.supervisorsdetails[i].Id, text: this.supervisorName });
				}
			},
			error => {
			}
			);

		let countries = localStorage.getItem('timeZones');
		if (countries != '') {
			let countryData = countries.split('}')
			for (var i = 0; i < countryData.length - 2; i++) {
				let timeZoneData = countryData[i].split(':');
				let country = timeZoneData[3].split(',')[0].slice(1, -1);
				let timeZones = timeZoneData[4].split(',');
				let zones: Array<any> = []
				for (var j = 0; j < timeZones.length; j++) {
					if (timeZones.length == 1) {
						let zoneText = timeZones[j].slice(2, -2)
						zones.push({ id: i + j, text: zoneText });
					}
					else if (j == 0) {
						let zoneText = timeZones[j].slice(2, -1)
						zones.push({ id: i + j, text: zoneText });
					}
					else if (j == timeZones.length - 1) {
						let zoneText = timeZones[j].slice(1, -2)
						zones.push({ id: i + j, text: zoneText });
					}
					else {
						let zoneText = timeZones[j].slice(1, -1)
						zones.push({ id: i + j, text: zoneText });
					}
				}
				this.timeZoneList.push({ id: i + 1, text: country, children: zones });
			}
			this.timeZoneList = this.timeZoneList.sort((a, b) => { return a.text.localeCompare(b.text) });
		}
	}

	//submit function of add user form
	save() {
		let jobprofileId = null;
		if (this.addUserForm.get("jobprofile").value != null) {
			if (this.addUserForm.get("jobprofile").value.length > 0) {
				jobprofileId = this.addUserForm.get("jobprofile").value[0].id;
			}
		}

		let supervisorId = [];
		if (this.addUserForm.get("supervisor").value != null) {
			if (this.addUserForm.get("supervisor").value.length > 0) {
				for(var i =0; i < this.addUserForm.get("supervisor").value.length; i++){
					supervisorId.push(this.addUserForm.get("supervisor").value[i].id)
				}				
			}
		}

		let orgId = null;
		if (this.addUserForm.get("orgId").value != null) {
			if (this.addUserForm.get("orgId").value.length > 0) {
				orgId = this.addUserForm.get("orgId").value[0].id;
			}
		}

		const tempUser = new user(this.addUserForm.value.email.toLowerCase(), this.addUserForm.value.password);
		tempUser.firstName = this.sanitizer.sanitize(this.addUserForm.value.firstName);
		tempUser.lastName = this.sanitizer.sanitize(this.addUserForm.value.lastName);
		tempUser.orgId = orgId;
		tempUser.supIds = supervisorId;
		tempUser.isAssesed = this.addUserForm.value.assess;
		tempUser.jobProfieId = jobprofileId;
		tempUser.timeZone = this.selectedTimeZone;
		tempUser.isContract = this.addUserForm.value.isContractUser != null ? this.addUserForm.value.isContractUser : false;

		if (this.addUserForm.value.assess == "True" && tempUser.jobProfieId == null) {
			this.alertServices.clear();
			this.alertServices.error("Job profile is mandatory for assess users.");
		} else {
			this.userService.addNewUser(tempUser)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.sharedService.change('AllUsers');
				},
				error => {
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		}
	}

	cancel() {
		this.sharedService.change('AllUsers');
	}

	saveAndNew() {
		// console.log('AddUserComponent: (save/submit) : ' + this.addUserForm.value.firstName + " / " + this.addUserForm.value.lastName + " / "
		// + this.addUserForm.value.email + ' / ' + this.addUserForm.value.password +' / '+ this.addUserForm.value.orgId );
		let jobprofileId = null;
		if (this.addUserForm.get("jobprofile").value != null) {
			if (this.addUserForm.get("jobprofile").value.length > 0) {
				jobprofileId = this.addUserForm.get("jobprofile").value[0].id;
			}
		}
		
		let supervisorId = [];
		if (this.addUserForm.get("supervisor").value != null) {
			if (this.addUserForm.get("supervisor").value.length > 0) {
				for(var i =0; i < this.addUserForm.get("supervisor").value.length; i++){
					supervisorId.push(this.addUserForm.get("supervisor").value[i].id)
				}				
			}
		}

		let orgId = null;
		if (this.addUserForm.get("orgId").value != null) {
			if (this.addUserForm.get("orgId").value.length > 0) {
				orgId = this.addUserForm.get("orgId").value[0].id;
			}
		}

		const tempUser = new user(this.addUserForm.value.email.toLowerCase(), this.addUserForm.value.password);
		tempUser.firstName = this.sanitizer.sanitize(this.addUserForm.value.firstName);
		tempUser.lastName = this.sanitizer.sanitize(this.addUserForm.value.lastName);
		tempUser.orgId = orgId;
		tempUser.supIds = supervisorId;
		tempUser.isAssesed = this.addUserForm.value.assess;
		tempUser.jobProfieId = jobprofileId;
		tempUser.timeZone = this.selectedTimeZone;
		tempUser.isContract = this.addUserForm.value.isContractUser != null ? this.addUserForm.value.isContractUser : false;

		if (this.addUserForm.value.assess == "True" && tempUser.jobProfieId == null) {
			this.alertServices.clear();
			this.alertServices.error("Job profile is mandatory for assess users.");
		} else {
			this.userService.addNewUser(tempUser)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success(data.message);
					// this.selectedTimeZone = '';
					// this.addUserForm.reset();
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					// console.log(err);
					this.alertServices.handleError(err);
				}
				);
		}
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	private getActiveJobprofile(orgId: string) {
		this.jobProfileService.getActiveJobprofilesAboveOrgCode(orgId)
			.subscribe(
			data => {
				this.jobprofileList = [];
				//this.selectedJobprofileId = '';
				for (var i = 0; i < data.jobProfileList.length; i++) {
					this.jobprofileList.push({ id: data.jobProfileList[i].Id, text: data.jobProfileList[i].Description });
				}
			},
			error => {
			}
			);
	}

}

export function matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
	return (group: FormGroup): { [key: string]: any } => {
		let password = group.controls[passwordKey];
		let confirmPassword = group.controls[confirmPasswordKey];
		if (confirmPassword.value != '' && password.value != '' && password.value !== confirmPassword.value) {
			return {
				mismatchedPasswords: true
			};
		}
	}
}
