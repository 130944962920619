import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from '@angular/http';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ng2-bootstrap/pagination';
import { NgGridModule } from 'angular2-grid';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MyDatePickerModule } from 'mydatepicker';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AutosizeModule } from 'ngx-autosize';
import { SelectModule } from 'ng2-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DatePipe } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SanitizationService } from './sanitizer.service';

// home folder
import { HomeComponent } from "./home/home.component"
import { ItemDirective } from './home/item.directive';
import { ItemService } from './home/item.service';
import { SharedService } from './home/shared.service';
import { ItemsComponent } from './home/item.component';
import { HeaderComponent } from './home/header/header.component';
import { SidebarComponent } from './home/sidebar/sidebar.component';

//user folder
import { UserComponent } from './user/user.component';
import { ProfileComponent } from './user/profile.component';
import { ResetPasswordComponent } from './user/resetpassword.component';
import { ForgotpasswordComponent } from './user/forgotpassword.component';
import { UserRoleComponent } from "./user/userrole.component";
import { UserService } from './user/user.service';
import { LoginComponent } from './user/login.component';
import { KeyCodeComponent } from './user/keycode.component';
//users folder
import { AllUsersComponent } from './user/users/allusers.component';
import { AddUserComponent } from './user/users/adduser.component';
import { EditUserComponent } from './user/users/edituser.component';
import { AssignUserRoleComponent } from './user/users/assignuserrole.component';

//dashboard folder
import { StaticDashboardComponent } from './dashboard/staticdashboard.component'
import { ProgressAssessmentComponent } from './dashboard/widgets/assessment/progress/progressassessment.component';
import { AssessmentSummaryComponent } from './dashboard/widgets/assessment/summary/assessmentsummary.component';
import { RankingComponent } from './dashboard/widgets/ranking/ranking.component';
import { GlobalAssessmentProgressComponent } from './dashboard/widgets/assessment/globalprogress/globalassessmentprogress.component';
import { ScheduledAssessmentComponent } from './dashboard/widgets/assessment/scheduled/scheduledassessment.component';
import { ActiveAssessmentComponent } from './dashboard/widgets/assessment/active/activeassessment.component';
import { routing } from "./app.routing";
import { Ng2Bs3ModalModule } from 'ng2-bs3-modal/ng2-bs3-modal';
import { AlertComponent } from './util/alert/alert.component';
import { AlertService } from './util/alert/alert.service';
import { WidgetService } from './dashboard/widget.service';
import { AssessmentHistoryComponent } from './dashboard/widgets/assessment/history/assessmenthistory.component';
import { CompetencySnapshotComponent } from './dashboard/widgets/assessment/competencysnapshot/competencysnapshot.component';
import { CompetencyPercentageComponent } from './dashboard/widgets/competencypercentage/competencypercentage.component';
import { UpComingAssessmentsComponent } from './dashboard/upcomingassessments/upcomingassessments.component';
import { AssessmentProgressComponent } from './dashboard/assessmentprogress/assessmentprogress.component';
import { EmployeesCompPercentageComponent } from './dashboard/widgets/employeescompetencypercentage/employeescomppercentage.component';
import { EmployeeAssessmentProgressComponent } from './dashboard/widgets/employeeassessmentprogress/employeeassessmentprogress.component';
import { EmployeeAssessmentsComponent } from './dashboard/widgets/employeeassessments/employeeassessments.component';

//orgcode folder
import { OrgCodeComponent } from './orgcode/orgcode.component';
import { OrgcodeService } from './orgcode/orgcode.service';

//orgtype folder
import { OrgTypeComponent } from './orgtype/orgtype.component';
import { OrgtypeService } from './orgtype/orgtype.service';
import { AddOrgTypeComponent } from './orgtype/addorgtype.component'
import { EditOrgTypeComponent } from './orgtype/editorgtype.component'

//knowledge
import { KnowledgesComponent } from './complib/knowledge/allknowledges.component';
import { AddKnowledgeComponent } from './complib/knowledge/addknowledge.component';
import { EditKnowledgeComponent } from './complib/knowledge/editknowledge.component';
import { KnowledgeService } from './complib/knowledge/knowledge.service';

//task
import { AllTaskComponent } from './complib/task/alltask.component';
import { AddTaskComponent } from './complib/task/addtask.component';
import { EditTaskComponent } from './complib/task/edittask.component';
import { TaskService } from './complib/task/task.service';
import { AllTaskGroupComponent } from "./complib/task/taskgroup/alltaskgroup.component";
import { AddTaskGroupComponent } from "./complib/task/taskgroup/addtaskgroup.component";
import { EditTaskGroupComponent } from "./complib/task/taskgroup/edittaskgroup.component";

//performance
import { AllPerformanceComponent } from './complib/performance/allperformance.component';
import { AddPerformanceComponent } from './complib/performance/addperformance.component';
import { EditPerformanceComponent } from './complib/performance/editperformance.component';
import { PerformanceService } from './complib/performance/performance.service';

//note
import { AllNoteComponent } from './complib/note/allnote.component';
import { AddNoteComponent } from './complib/note/addnote.component';
import { EditNoteComponent } from './complib/note/editnote.component';
import { NoteService } from './complib/note/note.service';

//reason for N/A
import { AllNAReasonComponent } from './complib/nareason/allnareason.component'
import { AddNAReasonComponent } from './complib/nareason/addnareason.component'
import { EditNAReasonComponent } from './complib/nareason/editnareason.component'
import { NAReasonService } from './complib/nareason/nareason.service';

//Core competency
import { AllCoreCompetencyComponent } from './complib/corecompetency/allcorecompetency.component';
import { AddCoreCompetencyComponent } from './complib/corecompetency/addcorecompetency.component';
import { CoreCompetencyService } from './complib/corecompetency/corecompetency.service';
import { EditCoreCompetencyComponent } from './complib/corecompetency/editcorecompetency.component';
import { ReorderCoreCompetencyComponent } from './complib/corecompetency/reordercorecompetency/reordercorecompetency.component';

//Checklist
import { AllChecklistComponent } from './complib/checklist/allchecklist.component';
import { AddChecklistComponent } from './complib/checklist/addchecklist.component';
import { EditChecklistComponent } from './complib/checklist/editchecklist.component';
import { ChecklistService } from './complib/checklist/checklist.service';

//discipline
import { DisciplineComponent } from './discipline/discipline.component';
import { DisciplineService } from './discipline/discipline.service';
import { AddDisciplineComponent } from './discipline/adddiscipline.component';
import { EditDisciplineComponent } from './discipline/editdiscipline.component';

//Remediation
import { RemediationComponent } from './complib/remediation/remediation.component';
import { RemediationService } from './complib/remediation/remediation.service';
import { AddRemediationComponent } from './complib/remediation/addremediation.component';
import { EditRemediationComponent } from './complib/remediation/editremediation.component';

//job profile
import { AllJobProfileComponent } from './jobprofile/alljobprofile.component';
import { AddEditJobProfileComponent } from './jobprofile/addeditjobprofile.component';
import { JobProfileService } from './jobprofile/jobprofile.service';
import { OrderArrayByType } from './util/orderarraybytype.pipe'
import { JPHiercachyComponent } from './jobprofile/jphierarchy.component';
import { PreventParentClickEvent } from './util/preventparentclick.directive'
//job profile comparison
import { CompairJobProfileComponent } from './jobprofile/compairjobprofile/compairjobprofile.component'
import { ReadOnlyJPHComponent } from './jobprofile/compairjobprofile/rojphierarchy.component'
//job progression planning
import { JobProgressionPlanningComponent } from "./jobprofile/jobprogressionplanning/jobprogressionplanning.component";

//schedule assessment calendar
import { AssessmentCalendarComponent } from './assessment/assessmentcalendar.component';
import { AssessmentService } from './assessment/assessment.service';
import { ScheduleAssessmentComponent } from './assessment/scheduleassessment.component';
import { Assessment } from './assessment/allassessment.component';
import { PerformAssessment } from './assessment/performassessment/performassessment.component'
import { PAHierarchyComponent } from './assessment/performassessment/pahierarchy.component'
import { ConvertDateTime } from './util/convertdatetime.pipe'
import { SelfAssessment } from './assessment/selfassessment/selfassessment.component';
import { SelfAssessmentReview } from './assessment/selfassessmentreview/selfassessmentreview.component';
import { CandidateAssessmentComponent } from './assessment/candidateassessment/candidateassessment.component';
import { CandidateCompetencyComponent } from './assessment/candidatecompetency/candidatecompetency.component';
import { AssessmentSignature } from './assessment/signature/assessmentsignature.component';

//succession planning
import { SuccessionPlanningComponent } from './assessment/successionplanning/successionplanning.component'

//competency analysis tool
import { CompetencyAnalysisToolComponent } from './assessment/competencyanalysistool/competencyanalysis.component'

//Core competency Tag
import { AllCoreCompetencyTagComponent } from './tag/corecompetencytag/allcorecompetencytag.component';
import { AddCoreCompetencyTagComponent } from './tag/corecompetencytag/addcorecompetencytag.component';
import { CoreCompetencyTagService } from './tag/corecompetencytag/corecompetencytag.service';
import { EditCoreCompetencyTagComponent } from './tag/corecompetencytag/editcorecompetencytag.component';

//task tag
import { AllTaskTagComponent } from './tag/tasktag/alltasktag.component';
import { AddTaskTagComponent } from './tag/tasktag/addtasktag.component';
import { EditTaskTagComponent } from './tag/tasktag/edittasktag.component';
import { TaskTagService } from './tag/tasktag/tasktag.service';

//performance Tag
import { AllPerformanceTagComponent } from './tag/performancetag/allperformancetag.component';
import { AddPerformanceTagComponent } from './tag/performancetag/addperformancetag.component';
import { EditPerformanceTagComponent } from './tag/performancetag/editperformancetag.component';
import { PerformanceTagService } from './tag/performancetag/performancetag.service';

//Checklist Tag
import { AllChecklistTagComponent } from './tag/checklisttag/allchecklisttag.component';
import { AddChecklistTagComponent } from './tag/checklisttag/addchecklisttag.component';
import { EditChecklistTagComponent } from './tag/checklisttag/editchecklisttag.component';
import { ChecklistTagService } from './tag/checklisttag/checklisttag.service';

//remediation tag
import { AllRemediationTagComponent } from './tag/remediationtag/allremediationtag.component';
import { AddRemediationTagComponent } from './tag/remediationtag/addremediationtag.component';
import { EditRemediationTagComponent } from './tag/remediationtag/editremediationtag.component';
import { RemediationTagService } from './tag/remediationtag/remediationtag.service';

//Knowledge Tag
import { AllKnowledgeTagComponent } from './tag/knowledgetag/allknowledgetag.component';
import { AddKnowledgeTagComponent } from './tag/knowledgetag/addknowledgetag.component';
import { KnowledgeTagService } from './tag/knowledgetag/knowledgetag.service';
import { EditKnowledgeTagComponent } from './tag/knowledgetag/editknowledgetag.component';

//regulatory agency
import { AllRegulatoryAgency } from "./complib/regulatoryagency/allregulatoryagency.component";
import { AddRegulatoryAgencyComponent } from "./complib/regulatoryagency/addregulatoryagency.component"
import { EditRegulatoryAgencyComponent } from './complib/regulatoryagency/editregulatoryagency.component'
import { RegulatoryAgencyService } from './complib/regulatoryagency/regulatoryagency.service'

//common

//dependency
import { CustomModalComponent } from './util/custommodal/custommodal.component';
import { CustomModalService } from './util/custommodal/custommodal.service';
import { DependencyService } from './util/dependency/dependency.service';

//reports
import { ReportComponent } from './report/report.component';
import { AllSupervisorReportComponent } from './report/generatereport/allsupervisorreport.component';
import { GenerateReportComponent } from './report/generatereport.component';
import { ReportService } from './report/report.service';
import { CandidatesReportComponent } from './report/generatereport/candidatesreport.component';
import { TaskReportComponent } from './report/generatereport/taskreport.component';
import { ChecklistReportComponent } from './report/generatereport/checklistreport.component';
import { PerformanceReportComponent } from './report/generatereport/performancereport.component';
import { NeverAssessedReportComponent } from './report/generatereport/neverassessed.component';
import { KnowledgeReportComponent } from './report/generatereport/knowledgereport.component';
import { RemediationReportComponent } from './report/remediationreport/remediationreport.component';
import { AssessmentRemediationReportComponent } from './report/remediationreport/assessmentremediationreport.component';
import { MissedAssessmentDataReportComponent } from './report/generatereport/missedassessmentdatareport.component'
import { MissedTasksReportComponent } from './report/generatereport/missedtasksreport.component'
import { SupervisorCandiadteReportComponent } from './report/generatereport/supervisorcandidatereport.component'
import { PercentNAReportComponent } from './report/generatereport/percentnareport.component'
import { RoleReportComponent } from './report/generatereport/rolereport.component'
import { JobProfileTasksReportComponent } from './report/generatereport/jobprofiletasks.component'
import { RegulatoryComplianceReportComponent } from './report/regulatorycompliancereport/regulatorycompliancereport.component'
import { RemediationMatrixReportComponent } from './report/remediationmatrix/remediationmatrixreport.component'
import { MissedTaskMatrixReportComponent } from './report/missedtaskmatrix/missedtaskmatrixreport.component'
import { OpportunityForDevelopmentReportComponent } from './report/generatereport/opportunityfordevelopmentreport.component'
import { KnowledgeTestByCandidateComponent } from './assessment/knowledgetestbycandidate/knowledgetestbycandidate.component'
import { KnowledgeTestByCandidateTabularComponent } from './assessment/knowledgetestbycandidate/knowledgetestbycandidatetabular.component'
import { KnowledgeTestByCandidateGraphicalComponent } from './assessment/knowledgetestbycandidate/knowledgetestbycandidategraphical.component'
import { CandidateCompetencyGraphicalViewComponent } from './assessment/candidatecompetency/candidatecompetencygraphicalview.component'

import { CommonDataConverter } from './util/commondataconverter.pipe'
import { CounterPipe } from './util/counter.pipe'

//sorting
import { SortService } from './util/sortable-table/sort.service';
import { SortableTableDirective } from './util/sortable-table/sortable-table.directive';
import { SortableColumnComponent } from './util/sortable-table/sortable-column.component';

//overlay
import { OverlayToolTipComponent } from './util/overlay/overlaytooltip.component'
import { OverlayService } from './util/overlay/overlay.service'

//change theme
import { ChangeTheme } from './theme/changetheme.component'
import { ChangeThemeService } from './theme/changetheme.service'

//validator
import { NoWhitespaceDirective } from "./util/validator/nowhitespace.directive"
import { NumberDirective } from "./util/validator/numbers-only.directive"

@NgModule({
	imports: [
		BrowserModule,
		HttpModule,
		NgbModule,
		routing,
		FormsModule,
		ReactiveFormsModule,
		Ng2Bs3ModalModule,
		Ng2TableModule,
		PaginationModule.forRoot(),
		NgGridModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory
		}),
		MyDatePickerModule,
		TagInputModule,
		BrowserAnimationsModule,
		SignaturePadModule,
		ChartsModule,
		SelectModule,
		TooltipModule.forRoot(),
		TabsModule.forRoot(),
		BsDatepickerModule.forRoot(),
		AutosizeModule,
		HighchartsChartModule,
		NgMultiSelectDropDownModule
	],

	declarations: [
		AppComponent,
		UserComponent,
		LoginComponent,
		HeaderComponent,
		SidebarComponent,
		AllUsersComponent,
		AddUserComponent,
		EditUserComponent,
		ProfileComponent,
		ResetPasswordComponent,
		ForgotpasswordComponent,
		ProgressAssessmentComponent,
		AssessmentSummaryComponent,
		RankingComponent,
		HomeComponent,
		KeyCodeComponent,
		UserRoleComponent,
		UserRoleComponent,
		AlertComponent,
		ItemsComponent,
		ItemDirective,
		OrgCodeComponent,
		OrgTypeComponent,
		AddOrgTypeComponent,
		EditOrgTypeComponent,
		KnowledgesComponent,
		AddKnowledgeComponent,
		EditKnowledgeComponent,
		AssignUserRoleComponent,
		AllTaskComponent,
		AddTaskComponent,
		EditTaskComponent,
		AllCoreCompetencyComponent,
		AddCoreCompetencyComponent,
		EditCoreCompetencyComponent,
		AllChecklistComponent,
		AddChecklistComponent,
		EditChecklistComponent,
		DisciplineComponent,
		AddDisciplineComponent,
		EditDisciplineComponent,
		AllPerformanceComponent,
		AddPerformanceComponent,
		EditPerformanceComponent,
		AllJobProfileComponent,
		AddEditJobProfileComponent,
		RemediationComponent,
		AddRemediationComponent,
		EditRemediationComponent,
		AssessmentCalendarComponent,
		ScheduleAssessmentComponent,
		OrderArrayByType,
		AllCoreCompetencyTagComponent,
		AddCoreCompetencyTagComponent,
		EditCoreCompetencyTagComponent,
		AllTaskTagComponent,
		AddTaskTagComponent,
		EditTaskTagComponent,
		AllPerformanceTagComponent,
		AddPerformanceTagComponent,
		EditPerformanceTagComponent,
		AllChecklistTagComponent,
		AddChecklistTagComponent,
		EditChecklistTagComponent,
		AllRemediationTagComponent,
		AddRemediationTagComponent,
		EditRemediationTagComponent,
		AllKnowledgeTagComponent,
		AddKnowledgeTagComponent,
		EditKnowledgeTagComponent,
		JPHiercachyComponent,
		Assessment,
		PerformAssessment,
		PAHierarchyComponent,
		PreventParentClickEvent,
		GlobalAssessmentProgressComponent,
		CustomModalComponent,
		ReportComponent,
		GenerateReportComponent,
		CandidatesReportComponent,
		ScheduledAssessmentComponent,
		AllSupervisorReportComponent,
		TaskReportComponent,
		ChecklistReportComponent,
		ActiveAssessmentComponent,
		PerformanceReportComponent,
		NeverAssessedReportComponent,
		KnowledgeReportComponent,
		RoleReportComponent,
		CompairJobProfileComponent,
		ReadOnlyJPHComponent,
		ConvertDateTime,
		AssessmentHistoryComponent,
		RemediationReportComponent,
		CompetencySnapshotComponent,
		CompetencyPercentageComponent,
		UpComingAssessmentsComponent,
		JobProgressionPlanningComponent,
		AssessmentProgressComponent,
		AssessmentRemediationReportComponent,
		EmployeesCompPercentageComponent,
		CandidateAssessmentComponent,
		SelfAssessment,
		StaticDashboardComponent,
		EmployeeAssessmentProgressComponent,
		CommonDataConverter,
		SuccessionPlanningComponent,
		MissedAssessmentDataReportComponent,
		MissedTasksReportComponent,
		PercentNAReportComponent,
		AllTaskGroupComponent,
		AddTaskGroupComponent,
		EditTaskGroupComponent,
		ReorderCoreCompetencyComponent,
		CompetencyAnalysisToolComponent,
		EmployeeAssessmentsComponent,
		CandidateCompetencyComponent,
		SortableTableDirective,
		SortableColumnComponent,
		OverlayToolTipComponent,
		AllNoteComponent,
		AddNoteComponent,
		EditNoteComponent,
		SupervisorCandiadteReportComponent,
		ChangeTheme,
		NoWhitespaceDirective,
		SelfAssessmentReview,
		AssessmentSignature,
		AllNAReasonComponent,
		AddNAReasonComponent,
		EditNAReasonComponent,
		JobProfileTasksReportComponent,
		NumberDirective,
		AllRegulatoryAgency,
		AddRegulatoryAgencyComponent,
		EditRegulatoryAgencyComponent,
		CounterPipe,
		RegulatoryComplianceReportComponent,
		RemediationMatrixReportComponent,
		MissedTaskMatrixReportComponent,
		OpportunityForDevelopmentReportComponent,
		KnowledgeTestByCandidateComponent,
		KnowledgeTestByCandidateTabularComponent,
		KnowledgeTestByCandidateGraphicalComponent,
		CandidateCompetencyGraphicalViewComponent
	],
	entryComponents: [
		AppComponent,
		AllUsersComponent,
		ResetPasswordComponent,
		AddUserComponent,
		EditUserComponent,
		RankingComponent,
		ProfileComponent,
		AssessmentSummaryComponent,
		ProgressAssessmentComponent,
		AddOrgTypeComponent,
		EditOrgTypeComponent,
		EditKnowledgeComponent,
		AssignUserRoleComponent,
		AllTaskComponent,
		AddTaskComponent,
		EditTaskComponent,
		AllCoreCompetencyComponent,
		AddCoreCompetencyComponent,
		EditCoreCompetencyComponent,
		AllChecklistComponent,
		AddChecklistComponent,
		EditChecklistComponent,
		AddDisciplineComponent,
		EditDisciplineComponent,
		AllPerformanceComponent,
		AddPerformanceComponent,
		EditPerformanceComponent,
		AllJobProfileComponent,
		AddEditJobProfileComponent,
		RemediationComponent,
		AddRemediationComponent,
		EditRemediationComponent,
		AssessmentCalendarComponent,
		ScheduleAssessmentComponent,
		AllCoreCompetencyTagComponent,
		AddCoreCompetencyTagComponent,
		EditCoreCompetencyTagComponent,
		AllTaskTagComponent,
		AddTaskTagComponent,
		EditTaskTagComponent,
		AllPerformanceTagComponent,
		AddPerformanceTagComponent,
		EditPerformanceTagComponent,
		AllChecklistTagComponent,
		AddChecklistTagComponent,
		EditChecklistTagComponent,
		AllRemediationTagComponent,
		AddRemediationTagComponent,
		EditRemediationTagComponent,
		AllKnowledgeTagComponent,
		AddKnowledgeTagComponent,
		EditKnowledgeTagComponent,
		JPHiercachyComponent,
		Assessment,
		PerformAssessment,
		PAHierarchyComponent,
		GlobalAssessmentProgressComponent,
		CustomModalComponent,
		ReportComponent,
		GenerateReportComponent,
		CandidatesReportComponent,
		ScheduledAssessmentComponent,
		AllSupervisorReportComponent,
		TaskReportComponent,
		ChecklistReportComponent,
		ActiveAssessmentComponent,
		PerformanceReportComponent,
		NeverAssessedReportComponent,
		KnowledgeReportComponent,
		RoleReportComponent,
		CompairJobProfileComponent,
		ReadOnlyJPHComponent,
		AssessmentHistoryComponent,
		RemediationReportComponent,
		CompetencySnapshotComponent,
		CompetencyPercentageComponent,
		UpComingAssessmentsComponent,
		JobProgressionPlanningComponent,
		AssessmentProgressComponent,
		AssessmentRemediationReportComponent,
		EmployeesCompPercentageComponent,
		CandidateAssessmentComponent,
		SelfAssessment,
		StaticDashboardComponent,
		EmployeeAssessmentProgressComponent,
		SuccessionPlanningComponent,
		MissedAssessmentDataReportComponent,
		MissedTasksReportComponent,
		PercentNAReportComponent,
		AllTaskGroupComponent,
		AddTaskGroupComponent,
		EditTaskGroupComponent,
		ReorderCoreCompetencyComponent,
		CompetencyAnalysisToolComponent,
		EmployeeAssessmentsComponent,
		CandidateCompetencyComponent,
		OverlayToolTipComponent,
		AllNoteComponent,
		AddNoteComponent,
		EditNoteComponent,
		SupervisorCandiadteReportComponent,
		ChangeTheme,
		SelfAssessmentReview,
		AssessmentSignature,
		AllNAReasonComponent,
		EditNAReasonComponent,
		AddNAReasonComponent,
		JobProfileTasksReportComponent,
		AllRegulatoryAgency,
		AddRegulatoryAgencyComponent,
		EditRegulatoryAgencyComponent,
		RegulatoryComplianceReportComponent,
		RemediationMatrixReportComponent,
		MissedTaskMatrixReportComponent,
		OpportunityForDevelopmentReportComponent,
		KnowledgeTestByCandidateComponent,
		KnowledgeTestByCandidateTabularComponent,
		KnowledgeTestByCandidateGraphicalComponent,
		CandidateCompetencyGraphicalViewComponent
	],
	providers: [
		AppService,
		UserService,
		AlertService,
		ItemService,
		SharedService,
		OrgcodeService,
		OrgtypeService,
		KnowledgeService,
		WidgetService,
		TaskService,
		CoreCompetencyService,
		ChecklistService,
		DisciplineService,
		RemediationService,
		PerformanceService,
		JobProfileService,
		AssessmentService,
		OrderArrayByType,
		CoreCompetencyTagService,
		TaskTagService,
		PerformanceTagService,
		ChecklistTagService,
		RemediationTagService,
		KnowledgeTagService,
		CustomModalService,
		ReportService,
		ConvertDateTime,
		DependencyService,
		CommonDataConverter,
		SortService,
		OverlayService,
		NoteService,
		ChangeThemeService,
		NAReasonService,
		DatePipe,
		CounterPipe,
		RegulatoryAgencyService,
		SanitizationService
	],
	bootstrap: [AppComponent]


})
export class AppModule { }
