import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedService } from '../home/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { UserService } from '../user/user.service';

import { discipline } from './discipline';
import { DisciplineService } from './discipline.service';
import { NoWhitespaceDirective } from "../util/validator/nowhitespace.directive"
import { SanitizationService } from '../sanitizer.service';
@Component({
	selector: 'app-editdiscipline',
	templateUrl: './editdiscipline.component.html'
})
export class EditDisciplineComponent implements OnInit {

	public editDiscipline: discipline;
	editDisciplineForm: FormGroup;

	constructor(private sharedService: SharedService,
		private disciplineService: DisciplineService,
		private alertServices: AlertService,
		private userService: UserService,
		private sanitizer: SanitizationService) {
		this.editDiscipline = disciplineService.getSelectedDisciplineForEdit();

		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.editDisciplineForm = new FormGroup({
			discipline: new FormControl(this.editDiscipline.discipline, Validators.required),
		});
	}

	onSubmit() {
		if (this.checkNeedToUpdateDiscipline()) {
			const tempDiscipline = new discipline(this.editDiscipline.Id, this.sanitizer.sanitize(this.editDisciplineForm.value.discipline), 1);
			this.disciplineService.updateDisciplingById(tempDiscipline)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success(data.message);
					this.editDiscipline.discipline = this.editDisciplineForm.value.discipline;
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			//no need to update the discipline no changes           
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	//navigate to org type component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	checkNeedToUpdateDiscipline(): boolean {
		if (this.editDiscipline.discipline != this.editDisciplineForm.value.discipline) {
			return true;
		} else {
			return false;
		}
	}
}