import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { CoreCompetencyService } from './corecompetency.service';

import { CoreCompetency } from './corecompetency.model';
import { CoreCompetencyTagService } from '../../tag/corecompetencytag/corecompetencytag.service';
import { TagInputModule } from 'ngx-chips';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-edit-core-competency',
	templateUrl: './editcorecompetency.component.html'
})

export class EditCoreCompetencyComponent implements OnInit {
	editCoreCompetency: FormGroup;

	public selectedTags = [];
	public activeTagList = [];
	public items = [];

	public ccModel: CoreCompetency;

	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private coreCompetencyService: CoreCompetencyService,
		private coreCompetencyTagService: CoreCompetencyTagService,
		private userService: UserService,
		private sanitizer: SanitizationService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.ccModel = this.coreCompetencyService.getSelectedCCModel()
		//console.log('[constructor] (ccModel) ' + JSON.stringify(this.ccModel).toString());
		this.ccModel.tagList.forEach(tag => {
			let item = { value: tag.value, id: tag.id }
			this.items.push(item)
		});

	}

	ngOnInit() {
		this.loadActiveCoreCompetencyTags();
		this.editCoreCompetency = new FormGroup({
			id: new FormControl(this.ccModel.id, Validators.required),
			coreCompetency: new FormControl(this.ccModel.text, Validators.required),
			coreCompetencyTag: new FormControl('')
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedtoUpdateCoreComp()) {
			let tempCCItem = new CoreCompetency(this.editCoreCompetency.value.id, this.sanitizer.sanitize(this.editCoreCompetency.value.coreCompetency))
			tempCCItem.tagList = this.items
			//console.log('[tempCCItem] ' + JSON.stringify(tempCCItem).toString());
			this.coreCompetencyService.editCoreCompetency(tempCCItem)
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully modified the core competency.");
					this.ccModel.text = this.sanitizer.sanitize(this.editCoreCompetency.value.coreCompetency);
					this.ccModel.tagList = [];
					this.items.forEach(tag => {
						let item = { value: tag.value, id: tag.id }
						this.ccModel.tagList.push(item)
					});
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			//no need to update core competency
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	public checkNeedtoUpdateCoreComp(): boolean {
		if (this.ccModel.text != this.editCoreCompetency.value.coreCompetency) {
			return true;
		} else if (this.compairArrayForChanges(this.ccModel.tagList, this.items)) {
			return true;
		} else {
			return false;
		}
	}

	public compairArrayForChanges(arrayOld: any, arrayNew: any): boolean {
		// console.log('[compairArrayForChanges] (tagList)' + JSON.stringify(this.items).toString());
		// console.log('[compairArrayForChanges] (tagList)' + JSON.stringify(this.ccModel.tagList).toString());
		let haveChanges: boolean = false;
		if (arrayOld.length != arrayNew.length) {
			haveChanges = true
		} else {
			let missing = []
			arrayNew.forEach(newItem => {
				if (!(this.isValueExists(arrayOld, newItem.value))) {
					missing.push(newItem)
				}
			});
			if (missing.length == 0) {
				haveChanges = false
			} else {
				haveChanges = true
			}
		}
		return haveChanges;
	}

	public isValueExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.value == value) {
				isExists = true;
			}
		});
		return isExists;
	}


	public loadActiveCoreCompetencyTags() {
		this.coreCompetencyTagService.getActiveCoreCompetencyTags()
			.subscribe(
			data => {
				//console.log('[loadActiveCoreCompetencyTags] ' + JSON.stringify(data).toString());
				this.activeTagList = []
				data.coreCompetencyTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.activeTagList.push(item);
				});
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.items.push(event);
	};

	public removeTags(event: any) {
		this.items = this.items.filter(tag => tag.id !== event.id);
	};
}
