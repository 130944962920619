import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";


import { TaskService } from '../task.service';
import { AlertService } from '../../../util/alert/alert.service';
import { SharedService } from '../../../home/shared.service';
import { UserService } from '../../../user/user.service';
import { JobProfileService } from '../../../jobprofile/jobprofile.service';
import { JobProfile } from '../../../jobprofile/jobprofile.model';
import { CoreCompetency } from '../../corecompetency/corecompetency.model';
import { Task } from '../task.model'
import { NoWhitespaceDirective } from "../../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../../sanitizer.service';
@Component({
	selector: 'app-addtaskgroup',
	templateUrl: './addtaskgroup.component.html'
})
export class AddTaskGroupComponent implements OnInit {

	addTaskGroupForm: FormGroup;
	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private taskService: TaskService,
		private userService: UserService,
		private jobProfileService: JobProfileService,
		private sanitizer: SanitizationService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.addTaskGroupForm = new FormGroup({
			taskGroup: new FormControl(null, Validators.required)
		});

	}

	save() {
		const taskGroupText: string = this.sanitizer.sanitize(this.addTaskGroupForm.value.taskGroup);
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			let tempTaskGroup: Task = new Task('', taskGroupText, true);
			tempTaskGroup.taskGroup = taskGroupText;
			let tempCCModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
			let jobProfileModel: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
			this.taskService.addNewTaskGroup(tempTaskGroup)
				.subscribe(
					data => {
						//	tempCCModel.taskList = [];
						tempTaskGroup.tgId = data.taskGroupId
						tempTaskGroup.isTaskGroup = true;
						tempTaskGroup.taskList = [];
						tempTaskGroup.orderNum = tempCCModel.taskList.length + 1;
						tempCCModel.taskList.push(tempTaskGroup)
						this.addTaskGroupToJPSave(jobProfileModel, tempCCModel, tempTaskGroup)
					},
					error => {
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
		}
		else {
			this.addTaskGroup(taskGroupText, true)
		}
	}

	saveAndNew() {
		const taskGroupText: string = this.sanitizer.sanitize(this.addTaskGroupForm.value.taskGroup);
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.addJPTaskGroup()
		}
		else {
			this.addTaskGroup(taskGroupText, false)
		}
	}


	private addJPTaskGroup() {
		const taskGroupText: string = this.addTaskGroupForm.value.taskGroup;
		let tempCCModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let jobProfileModel: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let tempTaskGroup: Task = new Task('', taskGroupText, true);
		tempTaskGroup.taskGroup = taskGroupText;
		this.taskService.addNewTaskGroup(tempTaskGroup)
			.subscribe(
				data => {
					// console.log('[addJPCoreCompetency] (data) ' + JSON.stringify(data).toString())					
					tempTaskGroup.tgId = data.taskGroupId;
					tempTaskGroup.isTaskGroup = true;
					tempTaskGroup.taskList = [];
					tempTaskGroup.orderNum = tempCCModel.taskList.length + 1;
					tempCCModel.taskList.push(tempTaskGroup)

					this.addTaskGroupToJP(jobProfileModel, tempCCModel, tempTaskGroup)
					// console.log('[addJPCoreCompetency] (jobProfileModel) ' + JSON.stringify(jobProfileModel).toString())
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
	}

	//add core competency to job profile
	private addTaskGroupToJP(jobProfile: JobProfile, coreCompetency: CoreCompetency, taskGroup: Task) {
		let tempJobProfile: JobProfile = new JobProfile(jobProfile.jobProfileId)
		let tempCCItem: CoreCompetency = new CoreCompetency(coreCompetency.id, coreCompetency.text, true, [])
		tempCCItem.taskList.push(taskGroup)
		tempJobProfile.coreCompetencyList = tempCCItem
		let taskTotal = coreCompetency.taskTotal + 1

		this.jobProfileService.addTaskGroupToJobProfile(tempJobProfile)
			.subscribe(
				data => {
					let id = ''
					tempCCItem.taskList = []
					//set task count
					coreCompetency.taskTotal = taskTotal
					// set task group id
					data.idList.forEach(item => {
						id = item.id
					});
					taskGroup.id = id
					tempCCItem.taskList.push(taskGroup)	
					this.alertService.clear();
					this.alertService.success("Successfully added the task group to the job profile.");
					// this.addCoreCompetency.reset();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
	}
	private addTaskGroupToJPSave(jobProfile: JobProfile, coreCompetency: CoreCompetency, taskGroup: Task) {
		let tempJobProfile: JobProfile = new JobProfile(jobProfile.jobProfileId)
		let tempCCItem: CoreCompetency = new CoreCompetency(coreCompetency.id, coreCompetency.text, true, [])
		
		tempCCItem.taskList.push(taskGroup)
		tempJobProfile.coreCompetencyList = tempCCItem
		let taskTotal = coreCompetency.taskTotal + 1

		this.jobProfileService.addTaskGroupToJobProfile(tempJobProfile)
			.subscribe(
				data => {
					//	coreCompetency.taskList.push(taskGroup);
					let id = ''
					tempCCItem.taskList = []
					//set task count
					coreCompetency.taskTotal = taskTotal
					// set task group id
					data.idList.forEach(item => {
						id = item.id
					});
					taskGroup.id = id
					tempCCItem.taskList.push(taskGroup)	
					this.alertService.clear();
					this.cancel();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
	}

	cancel() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('AllTaskGroup');
		}
	}

	private addTaskGroup(text: string, reset: boolean) {
		let tempTaskGroup: Task = new Task('', text, true);
		this.taskService.addNewTaskGroup(tempTaskGroup).subscribe(
			data => {
				// console.log('[addTask] (data) ' + JSON.stringify(data).toString())
				this.alertService.clear();
				this.alertService.success(data.message);
				if (reset) {
					this.sharedService.change('AllTaskGroup')
				}

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}
}