import { Component, OnInit } from '@angular/core'
import { ViewChild } from '@angular/core'
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms"
import { SelectComponent } from 'ng2-select'
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";

import { AssessmentModel } from '../assessment.model'
import { CheckList } from '../../complib/checklist/checklist.model'
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model'
import { Knowledge } from '../../complib/knowledge/knowledge.model'
import { Performance } from '../../complib/performance/performance.model'
import { Task } from '../../complib/task/task.model'
import { AssessmentDocument } from '../performassessment/assessmentdocument.model'
import { JobProfile } from '../../jobprofile/jobprofile.model'

import { TableComponent } from '../../util/table/table.component'

import { UserService } from '../../user/user.service'
import { AlertService } from '../../util/alert/alert.service'
import { SharedService } from '../../home/shared.service'
import { AssessmentService } from '../../assessment/assessment.service'

import { Constants } from '../../util/const/constants'
import { parseDate } from 'ngx-bootstrap/chronos';
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-score-summary',
	templateUrl: './competencyanalysis.component.html',
	styleUrls: ['./competencyanalysis.component.scss']
})
export class CompetencyAnalysisToolComponent extends TableComponent implements OnInit {
	@ViewChild('sc', { static: true }) public sc: SelectComponent
	@ViewChild('ssc', { static: true }) public ssc: SelectComponent
	@ViewChild("filterDataModal", { static: true }) filterDataModal: ModalComponent;

	public catForm: FormGroup
	public filterForm: FormGroup
	public selectedCategory: any = null
	public selectedSubCategory: any = null
	public categoryList: Array<any> = []
	public subCategoryList: Array<any> = []
	public isDisableBtn: boolean = true
	public isPrintable: boolean = false;

	public selectedUserRole = JSON.parse(localStorage.getItem('selectedUserRole'))
	public loggedUserId = localStorage.getItem('userId')
	public itemList: Array<any> = []
	public showResults: string = ''
	public competencyAnalysisData = []
	public subCategoryCAData = []

	public showItemDetail: string = ''
	public filteringText: any = null;
	noResults: boolean = false;

	public columns: Array<any> = [
		{ 'title': 'First Name', 'name': 'firstName', 'className': "accepter-col-action" },
		{ 'title': 'Last Name', 'name': 'lastName', 'className': "accepter-col-action" },
		{ 'title': 'Org Code', 'name': 'orgCode', 'className': "accepter-col-action" },
		{ 'title': 'Job Profile', 'name': 'jobProfile', 'className': "accepter-col-action" },
		{ 'title': 'Knowledge Check Only', 'name': 'isKnowledgeTest', 'className': "accepter-col-action" },
		{ 'title': 'Competency Score', 'name': 'progressBar', 'className': "accepter-col-action" },
		{ 'title': 'Completed Date', 'name': 'completedDateStr', 'className': "accepter-col-action" },
		{ 'title': 'Assessment Report', 'name': 'assessmentReport', 'className': "accepter-col-action" },
	]
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered", "col-sm-12"]
	};


	constructor(private userService: UserService, private alertService: AlertService,
		private assessmentService: AssessmentService, private sharedService: SharedService,private sanitizer: SanitizationService) {
		super();
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		if (this.selectedUserRole[0][0] != 3) {
			this.itemList = [{ 'id': '1', 'text': 'Org Code' }, { 'id': '2', 'text': 'Job Profile' }, { 'id': '3', 'text': 'Discipline' }, { 'id': '4', 'text': 'Supervisor' }, { 'id': '5', 'text': 'Core Competency' }]
		} else {
			this.itemList = [{ 'id': '1', 'text': 'Org Code' }, { 'id': '2', 'text': 'Job Profile' }, { 'id': '3', 'text': 'Discipline' }, { 'id': '5', 'text': 'Core Competency' }]
		}
		this.categoryList = this.itemList
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.filterForm = new FormGroup({
			filterOrgCode: new FormControl(null),
			filterJobProfile: new FormControl(null),
			filterDiscipline: new FormControl(null),
			filterSupervisor: new FormControl(null),
			filterCoreComp: new FormControl(null)
		})
	}

	public loadReportHTML() {
		let reportHTML = '<h1 align="center">Competency Analysis Report</h1><font size="5">';
		if (this.showResults == 'Category') {
			reportHTML = reportHTML + '<b>Grouped By: </b>' + this.selectedCategory.text + '<br>';
			if (this.isFilteringAvailable()) {
				reportHTML = reportHTML + '<b>Filtered By:</b><br>'
				if (this.filteringText.orgCode) {
					reportHTML = reportHTML + 'Org Code - ' + this.filteringText.orgCode + ", ";
				}
				if (this.filteringText.jobProfile) {
					reportHTML = reportHTML + 'Job Profile - ' + this.filteringText.jobProfile + ", ";
				}
				if (this.filteringText.discipline) {
					reportHTML = reportHTML + 'Discipline - ' + this.filteringText.discipline + ", ";
				}
				if (this.filteringText.supervisor) {
					reportHTML = reportHTML + 'Supervisor - ' + this.filteringText.supervisor + ", ";
				}
				if (this.filteringText.coreCompetency) {
					reportHTML = reportHTML + 'Core Competency - ' + this.filteringText.coreCompetency;
				}
				else {
					reportHTML = reportHTML.slice(0, reportHTML.length - 2);
				}
				reportHTML = reportHTML + "<br>";
			}
			reportHTML = reportHTML + '<br><table width="1000px" cellpadding="0" cellspacing="0" border="0"><thead><tr><th></th><th></th><th></th></tr>' +
				'</thead><tbody><tr>';
			for (let j = 0; j < this.competencyAnalysisData.length; j++) {

				reportHTML = reportHTML + '<td style="width:55%;"><div style=\"line-height: 1.2rem; font-size: 1.2rem;text-align: center; width=1200px; margin-bottom: 10px; margin-top: 10px; overflow: hidden;background-color: #bfbfbf;border-radius:.25rem;' +
					'display: -webkit-box;display: -ms-flexbox;display: flex;\">';

				if (this.competencyAnalysisData[j]["redPercentage"] != 0) {
					reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
						'background-color:#dc3545;width:' + this.competencyAnalysisData[j]["redActual"] + '%;\" role="progressbar"' +
						'>' + this.competencyAnalysisData[j]["redPercentage"] + '%</div>';
				}
				if (this.competencyAnalysisData[j]["redPercentage"] == 0) {
					reportHTML = reportHTML + '<div style=\"' +
						'background-color:#dc3545;width:' + this.competencyAnalysisData[j]["redActual"] + '%;\" role="progressbar"' +
						'></div>';
				}
				if (this.competencyAnalysisData[j]["yellowPercentage"] != 0) {
					reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
						'background-color:#ffc107;width:' + this.competencyAnalysisData[j]["yellowActual"] + '%;\" role="progressbar" ' +
						'>' + this.competencyAnalysisData[j]["yellowPercentage"] + '%</div>';
				}
				if (this.competencyAnalysisData[j]["yellowPercentage"] == 0) {
					reportHTML = reportHTML + '<div style=\"' +
						'background-color:#ffc107;width:' + this.competencyAnalysisData[j]["yellowActual"] + '%;\" role="progressbar" ' +
						'></div>';
				}
				if (this.competencyAnalysisData[j]["greenPercentage"] != 0) {
					reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
						'background-color:#28a745;width:' + this.competencyAnalysisData[j]["greenActual"] + '%;\" role="progressbar" ' +
						'>' + this.competencyAnalysisData[j]["greenPercentage"] + '%</div>';
				}
				if (this.competencyAnalysisData[j]["greenPercentage"] == 0) {
					reportHTML = reportHTML + '<div style=\"' +
						'background-color:#28a745;width:' + this.competencyAnalysisData[j]["greenActual"] + '%;\" role="progressbar" ' +
						'></div>';
				}
				reportHTML = reportHTML + '</div></td><td style="width:5%;"></td><td style="width:40%;"><font size="5">' + (this.competencyAnalysisData[j]["description"] === null ? '' : this.competencyAnalysisData[j]["description"]) + '</font></td></tr>';
			}
			reportHTML = reportHTML + "</tbody></table>";
		}
		else if (this.showResults == 'SubCategory') {
			reportHTML = reportHTML + '<b>Grouped By: </b>' + this.selectedCategory.text + ', ' + this.selectedSubCategory.text + '<br>';
			if (this.isFilteringAvailable()) {
				reportHTML = reportHTML + '<b>Filtered By: </b><br>'
				if (this.filteringText.orgCode) {
					reportHTML = reportHTML + 'Org Code - ' + this.filteringText.orgCode + ", ";
				}
				if (this.filteringText.jobProfile) {
					reportHTML = reportHTML + 'Job Profile - ' + this.filteringText.jobProfile + ", ";
				}
				if (this.filteringText.discipline) {
					reportHTML = reportHTML + 'Discipline - ' + this.filteringText.discipline + ", ";
				}
				if (this.filteringText.supervisor) {
					reportHTML = reportHTML + 'Supervisor - ' + this.filteringText.supervisor + ", ";
				}
				if (this.filteringText.coreCompetency) {
					reportHTML = reportHTML + 'Core Competency - ' + this.filteringText.coreCompetency;
				}
				else {
					reportHTML = reportHTML.slice(0, reportHTML.length - 2);
				}
				reportHTML = reportHTML + "<br>";
			}
			reportHTML = reportHTML + "<br>";
			for (let i = 0; i < this.subCategoryCAData.length; i++) {
				reportHTML = reportHTML + '<font size="5">' + (this.subCategoryCAData[i].parentDescription === null ? '' : this.subCategoryCAData[i].parentDescription) + '</font><hr><table width="1000px" cellpadding="0" cellspacing="0" border="0"><thead><tr><th></th><th></th><th></th><th></th></tr>' +
					'</thead><tbody><tr>';
				for (let j = 0; j < this.subCategoryCAData[i].childList.length; j++) {
					reportHTML = reportHTML + '<td style="width:2%;"></td><td style="width:53%;"><div style=\"line-height: 1.2rem; font-size: 1.2rem;text-align: center; width=1200px; margin-bottom: 10px; margin-top: 10px; overflow: hidden;background-color: #bfbfbf;border-radius:.25rem;' +
						'display: -webkit-box;display: -ms-flexbox;display: flex;\">';

					if (this.subCategoryCAData[i].childList[j]["redPercentage"] != 0) {
						reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
							'background-color:#dc3545;width:' + this.subCategoryCAData[i].childList[j]["redActual"] + '%;\" role="progressbar"' +
							'>' + this.subCategoryCAData[i].childList[j]["redPercentage"] + '%</div>';
					}
					if (this.subCategoryCAData[i].childList[j]["redPercentage"] == 0) {
						reportHTML = reportHTML + '<div style=\"' +
							'background-color:#dc3545;width:' + this.subCategoryCAData[i].childList[j]["redActual"] + '%;\" role="progressbar"' +
							'></div>';
					}
					if (this.subCategoryCAData[i].childList[j]["yellowPercentage"] != 0) {
						reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
							'background-color:#ffc107;width:' + this.subCategoryCAData[i].childList[j]["yellowActual"] + '%;\" role="progressbar" ' +
							'>' + this.subCategoryCAData[i].childList[j]["yellowPercentage"] + '%</div>';
					}
					if (this.subCategoryCAData[i].childList[j]["yellowPercentage"] == 0) {
						reportHTML = reportHTML + '<div style=\"' +
							'background-color:#ffc107;width:' + this.subCategoryCAData[i].childList[j]["yellowActual"] + '%;\" role="progressbar" ' +
							'></div>';
					}
					if (this.subCategoryCAData[i].childList[j]["greenPercentage"] != 0) {
						reportHTML = reportHTML + '<div style=\"float: left;height: 100%;color: #000000;' +
							'background-color:#28a745;width:' + this.subCategoryCAData[i].childList[j]["greenActual"] + '%;\" role="progressbar" ' +
							'>' + this.subCategoryCAData[i].childList[j]["greenPercentage"] + '%</div>';
					}
					if (this.subCategoryCAData[i].childList[j]["greenPercentage"] == 0) {
						reportHTML = reportHTML + '<div style=\"' +
							'background-color:#28a745;width:' + this.subCategoryCAData[i].childList[j]["greenActual"] + '%;\" role="progressbar" ' +
							'></div>';
					}
					reportHTML = reportHTML + '</div></td><td style="width:5%;"></td><td style="width:40%;"><font size="5">' + (this.subCategoryCAData[i].childList[j]["description"] === null ? '' : this.subCategoryCAData[i].childList[j]["description"]) + '</font></td></tr>';
				}
				reportHTML = reportHTML + "</tbody></table><br>";

			}
		}
		reportHTML = reportHTML + '</font>';
		return reportHTML;
	}

	public print() {
		let popupWinindow;
		let content = this.loadReportHTML();
		popupWinindow = window.open(
			"",
			"_blank",
			"width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
		);
		popupWinindow.document.open();
		popupWinindow.document.write(
			'<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
			content +
			"</html>"
		);
		popupWinindow.document.close();

	}

	public removeCategory(option: any) {
		// console.log('[removeOptionItem] (option) ' + JSON.stringify(option));
		this.showResults = ''
		this.ssc.writeValue(null)
		this.selectedCategory = null
		this.selectedSubCategory = null
		this.isDisableBtn = true
		this.isPrintable = false;
		this.alertService.clear()
	}

	public selectCategory(option: any) {
		this.selectedCategory = option
		this.isDisableBtn = false
		let tempItemList: Array<any> = []
		this.itemList.forEach(element => {
			if (option.id != element.id) {
				tempItemList.push(element)
			}
		});
		this.ssc.writeValue(null)
		this.subCategoryList = tempItemList
		this.showResults = ''
		this.isPrintable = false;
		this.alertService.clear()
	}

	public removeSubCategory(option: any) {
		this.selectedSubCategory = null
		this.showResults = ''
		this.isPrintable = false;
		this.alertService.clear()
	}

	public selectSubCategory(option: any) {
		this.selectedSubCategory = option
		this.isPrintable = false;
		this.showResults = ''
		this.alertService.clear()
	}

	public showCATResult() {
		this.alertService.clear()
		this.showItemDetail = ''
		if (this.selectedSubCategory != null) {
			//user has selected a sub category
			this.getCompetencyAnalysisDataWithSubCategory(this.selectedCategory.text, this.selectedSubCategory.text,
				this.selectedUserRole[0][1], this.loggedUserId, this.filteringText)
			this.showResults = 'SubCategory'
		} else {
			//user has selected only category
			this.getCompetencyAnalysisDataFromServer(this.selectedCategory.text, this.selectedUserRole[0][1], this.loggedUserId, this.filteringText)
		}
	}

	//get competency analysis data from server for one category only
	public getCompetencyAnalysisDataFromServer(type: string, userRole: string, userId: string, filteringText: any) {
		this.assessmentService.getCompetencyAnalysisData(type, userRole, userId, filteringText).subscribe(
			data => {
				this.competencyAnalysisData = [];
				for (var i = 0; i < data.Assessments.length; i++) {
					let total = data.Assessments[i].Red + data.Assessments[i].Green + data.Assessments[i].Yellow;
					let redActual = Math.round(((data.Assessments[i].Red / total) * 100) * 100) / 100;
					let redPercentage = Math.round((data.Assessments[i].Red / total) * 100);
					let yellowActual = Math.round(((data.Assessments[i].Yellow / total) * 100) * 100) / 100;
					let yellowPercentage = Math.round((data.Assessments[i].Yellow / total) * 100);
					let greenActual = Math.round(((data.Assessments[i].Green / total) * 100) * 100) / 100;
					let greenPercentage = Math.round((data.Assessments[i].Green / total) * 100);
					let result = this.assessmentService.getCalculatedPercentage(redActual, yellowActual, greenActual)
					redActual = result.redPercentage
					yellowActual = result.yellowPercentage
					greenActual = result.greenPercentage
					let description = data.Assessments[i].Description
					if (type == 'Supervisor') {
						if (!description) {
							description = 'No Supervisor'
						}
					}
					this.competencyAnalysisData.push({
						'id': data.Assessments[i].id, 'description': description, 'userList': data.Assessments[i].userList,
						'red': data.Assessments[i].Red, 'redPercentage': redPercentage, 'redActual': redActual,
						'yellow': data.Assessments[i].Yellow, 'yellowPercentage': yellowPercentage, 'yellowActual': yellowActual,
						'green': data.Assessments[i].Green, 'greenPercentage': greenPercentage, 'greenActual': greenActual
					})
					this.isPrintable = true;
				}
				if (data.Assessments.length == 0) {
					this.noResults = true
				} else {
					this.noResults = false
				}
				this.showResults = 'Category'
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		)
	}

	public getCompetencyAnalysisDataWithSubCategory(category: string, subCategory: string, userRole: string, userId: string, filteringText: any) {
		this.assessmentService.getCompetencyAnalysisDataWithSubCategory(category, subCategory, userRole, userId, filteringText).subscribe(
			data => {
				this.subCategoryCAData = []
				this.modifySubCategoryData(data.Assessments)
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		)
	}

	public modifySubCategoryData(data: any) {
		let tempList = []
		data.forEach(element => {
			if (this.checkValueExists(tempList, element.parentId)) {
				//element does exist in tempList so get item's child list
				let tempItem = this.getItemByIdFromArray(tempList, element.parentId)
				//check element in child list in temp item
				if (this.checkValueExists(tempItem.childList, element.parentId)) {
					// do nothing item exist as child item
				} else {
					//add child item
					let cItem = {
						'id': element.id,
						'description': element.description,
						'Red': element.Red,
						'Green': element.Green,
						'Yellow': element.Yellow,
						'userList': element.userList
					}
					tempItem.childList.push(cItem)
				}
			} else {
				//element does not exist in tempList so add the item
				if (this.selectedCategory.text == 'Supervisor') {
					if (element.parentDescription == null) {
						element.parentDescription = 'No Supervisor'
					}
				}
				let item = {
					'id': element.parentId,
					'parentDescription': element.parentDescription,
					'childList': [
						{
							'id': element.id,
							'description': element.description,
							'Red': element.Red,
							'Green': element.Green,
							'Yellow': element.Yellow,
							'userList': element.userList
						}
					]
				}
				tempList.push(item)
			}
		});

		// console.log('[modifySubCategoryData] (tempList) ' + JSON.stringify(tempList))

		tempList.forEach(parent => {
			let childItem = []
			parent.childList.forEach(child => {
				let total = child.Red + child.Green + child.Yellow;
				let redActual = Math.round(((child.Red / total) * 100) * 100) / 100;
				let redPercentage = Math.round((child.Red / total) * 100);
				let yellowActual = Math.round(((child.Yellow / total) * 100) * 100) / 100;
				let yellowPercentage = Math.round((child.Yellow / total) * 100);
				let greenActual = Math.round(((child.Green / total) * 100) * 100) / 100;
				let greenPercentage = Math.round((child.Green / total) * 100);

				let result = this.assessmentService.getCalculatedPercentage(redActual, yellowActual, greenActual)
				redActual = result.redPercentage
				yellowActual = result.yellowPercentage
				greenActual = result.greenPercentage

				let description
				if (this.selectedSubCategory.text == 'Supervisor') {
					if (child.description == null) {
						description = 'No Supervisor'
					} else {
						description = child.description
					}
				} else {
					description = child.description
				}

				childItem.push({
					'id': child.id, 'description': description, 'userList': child.userList,
					'red': child.Red, 'redPercentage': redPercentage, 'redActual': redActual,
					'yellow': child.Yellow, 'yellowPercentage': yellowPercentage, 'yellowActual': yellowActual,
					'green': child.Green, 'greenPercentage': greenPercentage, 'greenActual': greenActual,
					'expand': false, 'parentId': parent.id
				})
			})
			this.subCategoryCAData.push({
				'id': parent.id,
				'parentDescription': parent.parentDescription,
				'childList': childItem,
				'expand': true
			})
			this.isPrintable = true;
		})
		if (this.subCategoryCAData.length == 0) {
			this.noResults = true
		} else {
			this.noResults = false
		}
	}

	//check given value exists in a given array
	private checkValueExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.id == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	//find item by id in arrayList
	private getItemByIdFromArray(arrayList: any, itemId: string): any {
		return arrayList.find(item => item.id === itemId);
	}

	public showCollapsePanel(caItem: any, parentItem: any, type: string) {
		switch (type) {
			case 'Category':
				if (this.showItemDetail != caItem.id) {
					this.loadTableData(caItem)
					this.showItemDetail = caItem.id
				} else {
					this.showItemDetail = ''
				}
				break
			case 'SubCategoryParent':
				if (caItem.expand) {
					caItem.expand = false
				} else {
					caItem.expand = true
				}
				break
			case 'SubCategoryChild':
				this.loadTableData(caItem)
				if (caItem.expand) {
					caItem.expand = false
				} else {
					this.subCategoryCAData.forEach(parent => {
						parent.childList.forEach(child => {
							if (child.id != caItem.id) {
								child.expand = false
							} else {
								if (child.parentId == parent.id) {
									child.expand = false
								}
							}
						});
					});
					caItem.expand = true
				}
				break
		}

	}

	//load table data
	private loadTableData(caItem: any) {
		this.data = []
		caItem.userList.forEach(element => {
			var completedDate: Date = null;			
			if (element.assessCompDate) {
				completedDate = new Date(new Date(element.assessCompDate).getUTCFullYear(), new Date(element.assessCompDate).getUTCMonth(), new Date(element.assessCompDate).getUTCDate());				
			}
			var selfAssessmentCompletedDate: Date = null;			
			if (element.selfAssessCompDate) {
				selfAssessmentCompletedDate = new Date(new Date(element.selfAssessCompDate).getUTCFullYear(), new Date(element.selfAssessCompDate).getUTCMonth(), new Date(element.selfAssessCompDate).getUTCDate());				
			}
			this.data.push({
				'assessmentId': element.assId,
				'firstName': element.FirstName,
				'lastName': element.LastName,
				'orgCode': element.OrgCode,
				'jobProfile': element.JobProfile,
				'isKnowledgeTest': element.IsKnowledgeTest,
				'assessmetScore': element.AssessmentScore,
				'assessmentCompletedDate': completedDate,
				'completedDateStr': completedDate.toLocaleDateString(),
				'selfAssessmentCompletedDate': selfAssessmentCompletedDate,
				'progressBar': '',
				'assessmentReport': ''
			})
		});
		this.pageCount = Math.ceil(this.data.length / this.itemsPerPage);
		//console.log(JSON.stringify(this.serverResultData));
		this.onChangeTable(this.config);
		this.extendData();
	}

	//table extend data
	public extendData() {
		for (let i = 0; i < this.data.length; i++) {
			let score = this.data[i].assessmetScore
			let progressBar = '<div class="progress" style="position: relative;height: 20px;">'
			if (score != null) {
				if (score < 90 && score > 10) {
					progressBar += '<div style="width: 2px;position: absolute; left: ' + score + '%; background: #212121; height: 20px;">' +
						'<div style="position: relative;background-color: #212121; color: #fafafa;height: 16px;border-radius: 4px;margin: 2px auto;padding: 4px;float: left;">'
						+ '<p style="font-size: 0.7rem;margin-top: -4px;">' + score + '%</p></div></div>'
				} else if (score == 100) {
					progressBar += '<div style="width: 2px;position: absolute; left: ' + score + '%; background: #212121; height: 20px;">' +
						'<div style="position: relative;background-color: #212121; color: #fafafa;height: 16px;border-radius: 4px;margin: 2px auto;padding: 4px;float: right">'
						+ '<p style="font-size: 0.7rem;margin-top: -4px;">' + score + '%</p></div></div>'
				} else if (score >= 90 && score != 100) {
					progressBar += '<div style="width: 2px;position: absolute; left: ' + score + '%; background: #212121; height: 20px;">' +
						'<div style="position: relative;background-color: #212121; color: #fafafa;height: 16px;border-radius: 4px;margin: 2px auto;padding: 4px;float: right">'
						+ '<p style="font-size: 0.7rem;margin-top: -4px;">' + score + '%</p></div></div>'
				} else if (score <= 10) {
					progressBar += '<div style="width: 2px;position: absolute; left: ' + score + '%; background: #212121; height: 20px;">' +
						'<div style="position: relative;background-color: #212121; color: #fafafa;height: 16px;border-radius: 4px;margin: 2px auto;padding: 4px;float: left">'
						+ '<p style="font-size: 0.7rem;margin-top: -4px;">' + score + '%</p></div></div>'
				}
				if (score >= 80) {
					progressBar += '<div class="progress-bar-success" role="progressbar" aria-valuenow="' + score + '" aria-valuemin="0" aria-valuemax="100" style="width:' + score + '%;">' +
						'</div>'
				} else if (score >= 50 && score < 80) {
					progressBar += '<div class="progress-bar-warning" role="progressbar" aria-valuenow="' + score + '" aria-valuemin="0" aria-valuemax="100" style="width:' + score + '%;heght:22px !important;">' +
						'</div>'
				} else if (score < 50) {
					progressBar += '<div class="progress-bar-danger" role="progressbar" aria-valuenow="' + score + '" aria-valuemin="0" aria-valuemax="100" style="width:' + score + '%;heght:22px !important;">' +
						'</div>'
				}

			}

			this.data[i].progressBar = progressBar
			this.data[i].assessmentReport = '<a style="cursor: pointer" class="btn btn-sm btn-block edit"><i class="fa fa-folder " aria-hidden="true"></i></a>'
			if(this.data[i].isKnowledgeTest == 1){
				this.data[i].isKnowledgeTest = '<div class="text-center"><input type="checkbox" checked disabled></div>'
			} else {
				this.data[i].isKnowledgeTest = '<div class="text-center"><input type="checkbox" disabled></div>'
			}
		}
	}

	//table cell click events
	public onCellClick(data: any): any {
		switch (data.column) {
			case 'assessmentReport':
				this.loadCandidateAssessmentPage(data)
				break;
			default:
				break;
		}
	}

	//load candidate assessment page
	private loadCandidateAssessmentPage(data: any) {
		let assessmentId = data.row.assessmentId
		let param: any = {
			'assessmentId': assessmentId,
			'includeSelfAssessment': true
		}
		let assessmentScore = parseInt(data.row.assessmetScore);
		let userFirstName: string = data.row.firstName
		let userLastName: string = data.row.lastName
		let jobProfileDesc: string = data.row.jobProfile

		let assessmentCompletedDate: Date = data.row.assessmentCompletedDate;
			let selfAssessmentCompletedDate: Date = data.row.selfAssessmentCompletedDate;
		// if (data.row.assessmentCompletedDate == null) {
		// 	assessmentCompletedDate = null
		// } else {
		// 	assessmentCompletedDate = new Date(new Date(data.row.assessmentCompletedDate).getUTCFullYear(), new Date(data.row.assessmentCompletedDate).getUTCMonth(), new Date(data.row.assessmentCompletedDate).getUTCDate());
		// }
		// console.log('[onCellClick] (assessmentReport) ' + JSON.stringify(userFirstName) + JSON.stringify(userLastName));
		this.assessmentService.getAssessmentJobProfileHierarchy(param).subscribe(
			data => {
				let assessmentModel: AssessmentModel = new AssessmentModel(assessmentId)
				assessmentModel.assessmentScore = assessmentScore
				assessmentModel.userName = userFirstName + ' ' + userLastName
				localStorage.setItem('selectedAsseseeName', userFirstName + ' ' + userLastName)
				assessmentModel.assessmentCompletedDate = assessmentCompletedDate
				assessmentModel.selfAssessmentCompletedDate = selfAssessmentCompletedDate
				assessmentModel.jobProfileDesc = jobProfileDesc
				let jobProfile: JobProfile = new JobProfile('001')
				jobProfile.coreCompetencyList = this.generateCCListFromData(data)
				assessmentModel.jobProfile = jobProfile
				this.validateCCList(assessmentModel.jobProfile.coreCompetencyList);
				this.assessmentService.setGenerate(true)
				this.assessmentService.setSelectedAssessmentModel(assessmentModel);
				this.sharedService.change('CandidateAssessment');
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		)
	}

	//generate assessment corecompetency list from data
	private generateCCListFromData(data: any): any {
		let corecompetencyList = [];

		for (let i = 0; i < data.result.length; i++) {
			let cci = data.result[i].ccItem
			let tl = data.result[i].taskList;
			let pl = data.result[i].performanceList;
			let kl = data.result[i].knowledgeList;
			let dl = data.result[i].documentList;
			let cll = data.result[i].checkList;
			let trl = data.result[i].taskRemediationList;
			let prl = data.result[i].performanceRemediationList;
			let krl = data.result[i].knowledgeRemediationList;
			let clrl = data.result[i].checkListRemediationList;
			let kal = data.result[i].knowledgeAnswerList;

			let clml = this.generateCheckListModel(cll, clrl);
			let kml = this.generateKnowledgeListModel(kl, krl, kal);
			let pml = this.generatePerformanceListModel(pl, clml, prl)
			let dml = this.generateDocumentListModel(dl);
			let tml = this.generateTaskListModel(tl, pml, kml, trl, dml);

			let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
			ccItem.orderNum = cci.orderNum
			ccItem.assStatus = cci.status
			ccItem.assId = cci.ACCId
			ccItem.assessmentScore = cci.assessmentScore == null ? 0 : +cci.assessmentScore.toFixed(0);
			corecompetencyList.push(ccItem)

		}
		return corecompetencyList;
	}

	private generateDocumentListModel(documentList: any) {
		let documentModelList = [];
		for (let i = 0; i < documentList.length; i++) {
			let temp = documentList[i].FileName;
			let fileName = temp.substring(0, temp.lastIndexOf("."));
			let ext = temp.substring(temp.lastIndexOf(".") + 1);
			let docModel: AssessmentDocument = new AssessmentDocument(documentList[i].Id, documentList[i].assId, documentList[i].AssessmentTaskId,
				documentList[i].Category, fileName, documentList[i].Note);
			docModel.ext = ext;
			documentModelList.push(docModel);
		}
		return documentModelList;
	}

	private generateCheckListModel(checkList: any, clrList: any): any {
		let clModelList = []
		for (let i = 0; i < checkList.length; i++) {
			let clModel: CheckList = new CheckList(checkList[i].id, checkList[i].text, '', true, checkList[i].orderNum);
			clModel.remediationList = clrList.filter(item => item.ACLId === checkList[i].ACLId);
			clModel.jppId = checkList[i].APId;
			clModel.answer = checkList[i].answer;
			clModel.assId = checkList[i].ACLId;
			clModelList.push(clModel);
		}

		return clModelList;
	}

	private generateKnowledgeListModel(knowledgeList: any, knoRemList: any, knowledgeAnswerList: any): any {
		let knowledgeModelList = []
		for (let i = 0; i < knowledgeList.length; i++) {
			let kModel: Knowledge = new Knowledge(knowledgeList[i].text, 0, knowledgeList[i].id);
			kModel.remediationList = knoRemList.filter(item => item.AKId === knowledgeList[i].AKId);
			kModel.answerList = knowledgeAnswerList.filter(item => item.AKId === knowledgeList[i].AKId)
			kModel.jptId = knowledgeList[i].ATId;
			kModel.orderNum = knowledgeList[i].orderNum;
			kModel.answer = knowledgeList[i].answer;
			kModel.assId = knowledgeList[i].AKId;
			kModel.type = knowledgeList[i].Type;
			kModel.isTestQuestion = knowledgeList[i].IsTestQuestion;
			kModel.booleanAnswerCorrect = knowledgeList[i].IsCorrect;
			knowledgeModelList.push(kModel)
		}
		return knowledgeModelList;
	}

	private generatePerformanceListModel(perList: any, checkList: any, remList: any) {
		let performanceModelList = []
		for (let i = 0; i < perList.length; i++) {
			let pModel: Performance = new Performance(perList[i].id, perList[i].text, true)
			pModel.checkList = checkList.filter(item => item.jppId === perList[i].APId);
			pModel.remediationList = remList.filter(item => item.APId === perList[i].APId);
			pModel.jptId = perList[i].ATId
			pModel.orderNum = perList[i].orderNum
			pModel.answer = perList[i].answer
			pModel.assId = perList[i].APId
			pModel.assessmentNote = perList[i].note
			performanceModelList.push(pModel)
		}
		return performanceModelList;
	}

	private generateTaskListModel(taskList: any, perList: any, knoList: any, remList: any, docList: any) {
		let taskModelList = [];
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task
			if (taskList[i].isTaskGroup) {
				taskModel = new Task(taskList[i].ATId, taskList[i].text);
				taskModel.performanceList = [];
				taskModel.knowledgeList = [];
				taskModel.knowledgeQuestionList = []
				taskModel.remediationList = [];
				taskModel.documentList = []
				taskModel.taskList = []
			} else {
				taskModel = new Task(taskList[i].id, taskList[i].text);
				taskModel.performanceList = perList.filter(item => item.jptId === taskList[i].ATId);
				taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].ATId).filter(item => item.isTestQuestion == 0);
				taskModel.knowledgeQuestionList = knoList.filter(item => item.jptId === taskList[i].ATId).filter(item => item.isTestQuestion == 1);
				taskModel.remediationList = remList.filter(item => item.ATId === taskList[i].ATId);
				taskModel.documentList = docList.filter(item => item.assId === taskList[i].ATId);
				taskModel.assStatus = taskList[i].status;
				taskModel.criticalityValue = taskList[i].criticality;
				taskModel.assessmentScore = taskList[i].assessmentScore == null ? 0 : +taskList[i].assessmentScore.toFixed(0);
			}
			taskModel.jpccId = taskList[i].ACCId;
			taskModel.orderNum = taskList[i].orderNum;
			taskModel.assId = taskList[i].ATId;
			taskModel.tgId = taskList[i].TGId
			taskModel.isTaskGroup = taskList[i].isTaskGroup
			taskModel.taskGroup = taskList[i].tgText
			taskModel.taskGroupId = taskList[i].taskGroupId
			taskModel.isNA = taskList[i].isNA
			taskModelList.push(taskModel);
		}
		taskModelList = this.modifyTaskList(taskModelList)
		return taskModelList;
	}

	public modifyTaskList(taskList: any): any {
		let returnTaskList = []
		let tempTaskList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {
				returnTaskList.push(task)
			} else {
				if (task.taskGroupId != null) {
					tempTaskList.push(task)
				} else {
					returnTaskList.push(task)
				}
			}
		});
		// console.log('[modifyTaskList] (returnTaskList) : ' + JSON.stringify(returnTaskList));
		// console.log('[modifyTaskList] (tempTaskList) : ' + JSON.stringify(tempTaskList));
		tempTaskList.forEach(task => {
			if (task.taskGroupId != null && task.taskGroupId != 'null') {
				let tg = returnTaskList.find(item => item.assId === task.taskGroupId);
				tg.taskList.push(task)
			} else {
				returnTaskList.push(task)
			}
		});

		// console.log('[modifyTaskList] (returnTaskList) : ' + JSON.stringify(returnTaskList));

		return returnTaskList

	}

	/**
		 * [validate cc item for 0-knowledge and 0-performance]
		 * this is not practical because user can not create 0-k 0-p cc item
		 * @param  {any}    ccList [description]
		 * @return {[type]}        [description]
		 */
	private validateCCList(ccList: any) {
		ccList.forEach(ccItem => {
			if (ccItem.taskList.length != 0) {
				ccItem.taskList.forEach(task => {
					if (task.performanceList.length != 0) {
						//do nothing
					} else {
						if (task.knowledgeList.length != 0) {
							//do nothing
						} else {
							task.assStatus = 3
						}
					}
				});
			} else {
				ccItem.assStatus = 3
			}
		});

		ccList.forEach(ccItem => {
			let completedTaskCount: number = 0;
			ccItem.taskList.forEach(task => {
				if (task.assStatus == 3) {
					completedTaskCount++
				}
			});
			if (completedTaskCount == ccItem.taskList.length) {
				ccItem.assStatus = 3
			}
		});
	}

	public dismissModal() {
		this.filterDataModal.dismiss()
		this.showItemDetail = ''
		if (this.selectedSubCategory != null) {
			this.showResults = 'SubCategory'
		} else {
			this.showResults = 'Category'
		}
		if (this.filteringText != null) {
			if (this.filteringText.orgCode) {
				this.filterForm.get("filterOrgCode").setValue(this.filteringText.orgCode)
			}
			if (this.filteringText.jobProfile) {
				this.filterForm.get("filterJobProfile").setValue(this.filteringText.jobProfile)
			}
			if (this.filteringText.supervisor) {
				this.filterForm.get("filterSupervisor").setValue(this.filteringText.supervisor)
			}
			if (this.filteringText.discipline) {
				this.filterForm.get("filterDiscipline").setValue(this.filteringText.discipline)
			}
			if (this.filteringText.coreCompetency) {
				this.filterForm.get("filterCoreComp").setValue(this.filteringText.corecompetency)
			}
		} else {
			this.filterForm.get("filterOrgCode").setValue('')
			this.filterForm.get("filterJobProfile").setValue('')
			this.filterForm.get("filterDiscipline").setValue('')
			this.filterForm.get("filterSupervisor").setValue('')
			this.filterForm.get("filterCoreComp").setValue('')
		}
	}

	public showFilterModal() {
		this.showResults = ''
		this.filterDataModal.open()
	}

	public filterCATResult() {
		this.filteringText = {
			'discipline': this.filterForm.get("filterDiscipline").value,
			'jobProfile': this.filterForm.get("filterJobProfile").value,
			'supervisor': this.filterForm.get("filterSupervisor").value,
			'coreCompetency': this.filterForm.get("filterCoreComp").value,
			'orgCode': this.filterForm.get("filterOrgCode").value
		}
		this.isPrintable = false;
		this.showCATResult();
	}

	public clear() {
		this.filteringText = null;
		this.showResults = '';
		this.isPrintable = false;
		this.alertService.clear();
		this.filterForm.reset()
	}

	isFilteringAvailable() {
		if (this.filteringText == null) {
			return false;
		} else {
			return (this.filteringText.orgCode) || (this.filteringText.jobProfile) ||
				(this.filteringText.discipline) || (this.filteringText.supervisor) || (this.filteringText.coreCompetency);
		}
	}

	private removeFilteringText(type: string) {
		switch (type) {
			case 'orgCode':
				this.filteringText.orgCode = ''
				this.filterForm.get("filterOrgCode").setValue('')
				break;
			case 'jobProfile':
				this.filteringText.jobProfile = ''
				this.filterForm.get("filterJobProfile").setValue('')
				break;
			case 'discipline':
				this.filteringText.discipline = ''
				this.filterForm.get("filterDiscipline").setValue('')
				break;
			case 'supervisor':
				this.filteringText.supervisor = ''
				this.filterForm.get("filterSupervisor").setValue('')
				break;
			case 'coreCompetency':
				this.filteringText.coreCompetency = ''
				this.filterForm.get("filterCoreComp").setValue('')
				break;
			default:
				break;
		}
		this.showCATResult();
	}
}

