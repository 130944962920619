import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { UserService } from '../../user/user.service';
import { OrgcodeService } from '../../orgcode/orgcode.service';

import { RemediationService } from './remediation.service';
import { JobProfileService } from '../../jobprofile/jobprofile.service'

import { remediation } from './remediation';
import { CoreCompetency } from '../corecompetency/corecompetency.model';
import { Task } from '../task/task.model'
import { Performance } from '../performance/performance.model'
import { Knowledge } from '../knowledge/knowledge.model'
import { CheckList } from '../checklist/checklist.model'
import { JobProfile } from '../../jobprofile/jobprofile.model'
import { TaskTagService } from '../../tag/tasktag/tasktag.service';

import { TagInputModule } from 'ngx-chips';
import { RemediationTagService } from '../../tag/remediationtag/remediationtag.service';
import { Constants } from '../../util/const/constants'
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-add-remediation',
	templateUrl: './addremediation.component.html'
})

export class AddRemediationComponent implements OnInit {

	@ViewChild('fileInput', { static: true }) el: ElementRef;

	addRemediationForm: FormGroup;
	public typeList: any[];
	public mediumList: any[];
	public selectedOrgId: string;
	public orgList: Array<any> = [];
	filesToUpload: Array<File> = [];
	private uploadFileData: Array<any> = [];
	public autocompleteItemsAsObjects = [];
	public selectedTags = [];
	public validFilesToUpload: Array<File> = [];
	public invalidfiles: string = '';
	public selectedMenuItem = localStorage.getItem('selectedMenuItem');

	constructor(private sharedService: SharedService, private remediationService: RemediationService,
		private alertService: AlertService, private userService: UserService, private remediationTagService: RemediationTagService,
		private orgcodeService: OrgcodeService, private jobProfileService: JobProfileService,
		private rd: Renderer2,private sanitizer: SanitizationService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}

		this.filesToUpload = [];

	}

	valid() {
		let result = false;
		if (this.addRemediationForm.get("description").status == "VALID" && this.addRemediationForm.get("medium").status == "VALID" && this.addRemediationForm.get("type").status == "VALID") {
			result = true;
		}
		if (result) {
			if (this.addRemediationForm.value.note) {
				if (this.addRemediationForm.value.note.length > 0) {
					if (this.addRemediationForm.value.note.trim().length == 0) {
						result = false
					}
				}
			}
		}
		if (result) {
			if (this.addRemediationForm.value.coursedescription) {
				if (this.addRemediationForm.value.coursedescription.length > 0) {
					if (this.addRemediationForm.value.coursedescription.trim().length == 0) {
						result = false
					}
				}
			}
		}
		if (result) {
			if (this.addRemediationForm.value.coursenumber) {
				if (this.addRemediationForm.value.coursenumber.length > 0) {
					if (this.addRemediationForm.value.coursenumber.trim().length == 0) {
						result = false
					}
				}
			}
		}
		return result;
	}

	ngOnInit() {

		this.typeList = [{ id: 'Procedure', text: 'Procedure' }, { id: 'Overview', text: 'Overview' }, { id: 'Policy', text: 'Policy' }];

		this.mediumList = [{ id: 'CBT', text: 'CBT' }, { id: 'Document', text: 'Document' }, { id: 'Classroom', text: 'Classroom' }, { id: 'Note', text: 'Note' }];
		this.loadActiveRemediationTags();
		this.addRemediationForm = new FormGroup({
			medium: new FormControl(null, Validators.required),
			description: new FormControl(null, Validators.required),
			type: new FormControl(null, Validators.required),
			coursenumber: new FormControl(null),
			coursedescription: new FormControl(null),
			link: new FormControl(null),
			orgId: new FormControl(null),
			note: new FormControl(null),
			remediationTag: new FormControl(null)
		});

		if (this.selectedMenuItem == 'AddJobProfile') {
			let jpOrgId = this.jobProfileService.getSelectedJobProfileForEdit().orgCodeId;
			this.orgcodeService.getActiveOrgCodesAboveOrgCode(jpOrgId)
				.subscribe(
				data => {
					this.orgList = [];
					for (var i = 0; i < data.orgCodeList.length; i++) {
						this.orgList.push({ id: data.orgCodeList[i].Id, text: data.orgCodeList[i].Description });
					}
					// this.selectedOrgId = null;
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		}
		else {
			this.orgcodeService.getActiveOrgcodes()
				.subscribe(
				data => {
					this.orgList = [];
					for (var i = 0; i < data.orgcode.length; i++) {
						this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
					}
					this.selectedOrgId = null;
				},
				error => {
				}
				);
		}
	}

	fileChangeEvent(fileInput: any) {
		this.filesToUpload = <Array<File>>fileInput.target.files;
		this.alertService.clear();
	}


	saveAndNew() {
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.addRemediationFromJPScreen()
		} else {
			this.addRemediation()
		}
	}

	save() {
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.addRemediationFromJPScreenSave();
		} else {
			this.addRemediationSave()
		}
	}

	cancel() {
		if (this.selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('Remediation');
		}
	}

	//add remediation (not job profile)
	public addRemediation() {
		this.checkForValidFiles();
		let orgId = null;
		if (this.addRemediationForm.get("orgId").value != null) {
			if (this.addRemediationForm.get("orgId").value.length > 0) {
				orgId = this.addRemediationForm.get("orgId").value[0].id;
			}
		}

		if (this.filesToUpload.length == 0) {
			if ((this.invalidfiles != '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			} else if ((this.invalidfiles == '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS);
			} else {
				const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
					this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
					this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
				tempremediation.tagList = this.selectedTags;
				this.remediationService.addRemediation(tempremediation)
					.subscribe(
					data => {
						this.alertService.clear();
						if (this.invalidfiles != '') {
							let message = data.message + ' except invalid files ' + this.invalidfiles;
							this.alertService.success(message);
						}
						else {
							this.alertService.success(data.message);
						}
					},
					error => {
						this.showError(error.json())
					}
					);
			}
		} else {
			// this.uploadFileData = [];
			// if ((this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
			// && ( this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '' ) && !this.checkForValidFiles()) {
			// 	this.alertService.clear();
			// 	this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			// }
			// else {
			this.remediationService.makeFileRequest([], this.filesToUpload)
				.subscribe(
				data => {
					const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
						this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
						this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
					tempremediation.tagList = this.selectedTags;
					this.remediationService.addRemediationWithUploadFile(tempremediation, data.file)
						.subscribe(
						data => {
							this.alertService.clear();
							if (this.invalidfiles != '') {
								let message = data.message + ' except invalid files ' + this.invalidfiles;
								this.alertService.success(message);
							}
							else {
								this.alertService.success(data.message);
							}
							// this.clearFrom()
						},
						error => {
							this.showError(error.json())
						}
						);
				},
				error => {
				}
				);
			//}
		}
	}

	public addRemediationSave() {
		this.checkForValidFiles();
		let orgId = null;
		if (this.addRemediationForm.get("orgId").value != null) {
			if (this.addRemediationForm.get("orgId").value.length > 0) {
				orgId = this.addRemediationForm.get("orgId").value[0].id;
			}
		}
		if (this.filesToUpload.length == 0) {
			if ((this.invalidfiles != '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			}
			else if ((this.invalidfiles == '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS);
			} else {
				const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
					this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
					this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
				tempremediation.tagList = this.selectedTags;
				this.remediationService.addRemediation(tempremediation)
					.subscribe(
					data => {
						this.alertService.clear();
						this.alertService.success(data.message);
						this.cancel();
					},
					error => {
						this.showError(error.json())
					}
					);
			}
		} else {
			// // this.uploadFileData = [];
			// // if ((this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
			// // && ( this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '' ) && !this.checkForValidFiles()) {
			// // 	this.alertService.clear();
			// // 	this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			// // }
			// else {
			this.remediationService.makeFileRequest([], this.filesToUpload)
				.subscribe(
				data => {
					const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
						this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
						this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
					tempremediation.tagList = this.selectedTags;
					this.remediationService.addRemediationWithUploadFile(tempremediation, data.file)
						.subscribe(
						data => {
							this.alertService.clear();
							if (this.invalidfiles != '') {
								let message = data.message + ' except invalid files ' + this.invalidfiles;
								this.alertService.success(message);
								this.cancel();
							}
							else {
								this.alertService.success(data.message);
								this.cancel();
							}
							// this.clearFrom()
						},
						error => {
							this.showError(error.json())
						}
						);
				},
				error => {
				}
				);
			//}
		}
	}

	//remove files with unsupported extentions from upload list
	private checkForValidFiles() {
		let fileTypes = ['xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'pdf'];
		this.invalidfiles = '';
		this.validFilesToUpload = [];
		let fileNameparts = [];
		for (var i = 0; i < this.filesToUpload.length; i++) {
			fileNameparts = this.filesToUpload[i].name.split('.');
			if (fileTypes.indexOf(fileNameparts[fileNameparts.length - 1]) >= 0) {
				this.validFilesToUpload.push(this.filesToUpload[i])
			}
			else {
				if (this.invalidfiles != '') {
					this.invalidfiles = this.invalidfiles + ' , ';
				}
				this.invalidfiles = this.invalidfiles + this.filesToUpload[i].name;
			}
		}
		this.filesToUpload = this.validFilesToUpload;
		return this.filesToUpload.length > 0 ? true : false;
	}

	public addRemediationFromJPScreen() {
		this.checkForValidFiles();
		let orgId = null;
		if (this.addRemediationForm.get("orgId").value != null) {
			if (this.addRemediationForm.get("orgId").value.length > 0) {
				orgId = this.addRemediationForm.get("orgId").value[0].id;
			}
		}
		let remItem: any;
		let rtype: string = this.jobProfileService.getRemediationType()

		if (this.filesToUpload.length == 0) {
			if ((this.invalidfiles != '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			} else if ((this.invalidfiles == '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS);
			} else {
				const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
					this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
					this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
				tempremediation.tagList = this.selectedTags;
				this.remediationService.addRemediation(tempremediation)
					.subscribe(
					data => {
						remItem = { 'id': data.remediationId, 'text': this.sanitizer.sanitize(this.addRemediationForm.value.description) }
						this.addRemediationToJobProfile(rtype, remItem)
					},
					error => {
						this.showError(error.json())
					}
					);
			}
		} else {
			// this.uploadFileData = [];
			// if ((this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
			// && ( this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '' ) && !this.checkForValidFiles()) {
			// 	this.alertService.clear();
			// 	this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			// }
			// else {
			this.remediationService.makeFileRequest([], this.filesToUpload)
				.subscribe(
				data => {
					const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
						this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
						this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
					tempremediation.tagList = this.selectedTags;
					this.remediationService.addRemediationWithUploadFile(tempremediation, data.file)
						.subscribe(
						data => {
							remItem = { 'id': data.remediationId, 'text': this.sanitizer.sanitize(this.addRemediationForm.value.description) }
							this.addRemediationToJobProfile(rtype, remItem)
						},
						error => {
							this.showError(error.json())
						}
						);
				},
				error => {
				}
				);
			//}
		}
	}

	public addRemediationFromJPScreenSave() {
		this.checkForValidFiles();
		let orgId = null;
		if (this.addRemediationForm.get("orgId").value != null) {
			if (this.addRemediationForm.get("orgId").value.length > 0) {
				orgId = this.addRemediationForm.get("orgId").value[0].id;
			}
		}
		let remItem: any;
		let rtype: string = this.jobProfileService.getRemediationType()

		if (this.filesToUpload.length == 0) {
			if ((this.invalidfiles != '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			}
			else if ((this.invalidfiles == '') && (this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
				&& (this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '')) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS);
			} else {
				const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
					this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
					this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
				tempremediation.tagList = this.selectedTags;
				this.remediationService.addRemediation(tempremediation)
					.subscribe(
					data => {
						remItem = { 'id': data.remediationId, 'text': this.sanitizer.sanitize(this.addRemediationForm.value.description) }
						this.addRemediationToJobProfileSave(rtype, remItem)
					},
					error => {
						this.showError(error.json())
					}
					);
			}
		} else {
			// this.uploadFileData = [];
			// if ((this.addRemediationForm.value.link == null || this.addRemediationForm.value.link == '') && (this.addRemediationForm.value.note == null || this.addRemediationForm.value.note == '')
			// && ( this.addRemediationForm.value.coursenumber == null || this.addRemediationForm.value.coursenumber == '' || this.addRemediationForm.value.coursedescription == null || this.addRemediationForm.value.coursedescription == '' ) && !this.checkForValidFiles()) {
			// 	this.alertService.clear();
			// 	this.alertService.error(Constants.ERROR_PROVIDE_REQUIRED_FIELDS_WITH_VALID_FILE);
			// }
			// else {
			this.remediationService.makeFileRequest([], this.filesToUpload)
				.subscribe(
				data => {
					const tempremediation = new remediation('', this.addRemediationForm.get("medium").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.description),
						this.addRemediationForm.get("type").value[0].id, this.sanitizer.sanitize(this.addRemediationForm.value.coursenumber), this.sanitizer.sanitize(this.addRemediationForm.value.coursedescription),
						this.sanitizer.sanitize(this.addRemediationForm.value.link), orgId, this.sanitizer.sanitize(this.addRemediationForm.value.note), '')
					tempremediation.tagList = this.selectedTags;
					this.remediationService.addRemediationWithUploadFile(tempremediation, data.file)
						.subscribe(
						data => {
							remItem = { 'id': data.remediationId, 'text': this.sanitizer.sanitize(this.addRemediationForm.value.description) }
							this.addRemediationToJobProfileSave(rtype, remItem)
						},
						error => {
							this.showError(error.json())
						}
						);
				},
				error => {
				}
				);
			//	}
		}
	}

	public updateLink(input: any) {
		this.alertService.clear();
	}

	public addRemediationToJobProfile(remType: string, remModel: any) {
		switch (remType) {
			case 'taskRemediation':
				this.addTaskRemediation(remModel)
				break
			case 'performanceRemediation':
				this.addPerformanceRemediation(remModel)
				break
			case 'knowledgeRemediation':
				this.addKnowledgeRemediation(remModel)
				break
			case 'checkListRemediation':
				this.addCheckListRemediation(remModel)
				break
			default:
				break

		}
	}

	public loadActiveRemediationTags() {
		this.remediationTagService.getActiveRemediationTags()
			.subscribe(
			data => {
				this.autocompleteItemsAsObjects = []
				data.remediationTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.autocompleteItemsAsObjects.push(item);
				});

			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.selectedTags.push(event);
	};

	public removeTags(event: any) {
		this.selectedTags = this.selectedTags.filter(tag => tag.id !== event.id);
	};
	// add task remediation to job profile
	public addTaskRemediation(remModel: any) {
		let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let taskModel: Task = this.jobProfileService.getSelectedTaskModel()

		let tempJP: JobProfile = new JobProfile(jobProfile.jobProfileId)
		let tempTask: Task = new Task(taskModel.id, taskModel.text, true, [], [], [])
		tempTask.remediationList.push(remModel)
		let tempCC: CoreCompetency = new CoreCompetency(ccModel.id, ccModel.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addTaskRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				taskModel.remediationList.push(remModel)
				this.alertService.success('Successfully added Remediation to the Task in the job profile.');
				// this.clearFrom()
			},
			error => {
				this.showError(error.json())
			})
	}

	//add performance remediation to job profile
	public addPerformanceRemediation(remModel: any) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let per: Performance = this.jobProfileService.getSelectedPerformanceModel()

		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempPer: Performance = new Performance(per.id, per.text, true, [], [])
		tempPer.remediationList.push(remModel)
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.performanceList = tempPer
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addPerformanceRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				per.remediationList.push(remModel);
				this.alertService.success('Successfully added Remediation to the Performance in the job profile.');
				// this.clearFrom();
			},
			error => {
				this.showError(error.json());
			});
	}

	//add knowledge remediation to job profile
	public addKnowledgeRemediation(remModel: any) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let know: Knowledge = this.jobProfileService.getSelectedKnowledgeModel()
		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempKno: Knowledge = new Knowledge(know.knowledge, 0)
		tempKno.id = know.id
		tempKno.remediationList = []
		tempKno.remediationList.push(remModel)
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.knowledgeList = tempKno
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addKnowledgeRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				know.remediationList.push(remModel)
				this.alertService.success('Successfully added Remediation to the Knowledge in the job profile.');
				// this.clearFrom()
			},
			error => {
				this.showError(error.json())
			}
			)
	}

	//add check list remediation to job profile
	public addCheckListRemediation(remModel: any) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let per: Performance = this.jobProfileService.getSelectedPerformanceModel()
		let cl: CheckList = this.jobProfileService.getSelectedCheckListModel()

		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempCL: CheckList = new CheckList(cl.id)
		tempCL.remediationList = []
		tempCL.remediationList.push(remModel)
		let tempPer: Performance = new Performance(per.id, per.text, true)
		tempPer.checkList = tempCL
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.performanceList = tempPer
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addCheckListRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				cl.remediationList.push(remModel)
				this.alertService.success('Successfully added Remediation to the CheckList in the job profile.');
				// this.clearFrom()
			},
			error => {
				this.showError(error.json())
			}
			)
	}

	public clearFrom() {
		this.addRemediationForm.reset();
		this.selectedOrgId = '';
		this.filesToUpload = [];
		this.el.nativeElement.value = "";
	}

	public showError(err) {
		this.alertService.clear();
		this.alertService.handleError(err);
	}

	public addRemediationToJobProfileSave(remType: string, remModel: any) {
		switch (remType) {
			case 'taskRemediation':
				this.addTaskRemediationSave(remModel)
				break
			case 'performanceRemediation':
				this.addPerformanceRemediationSave(remModel)
				break
			case 'knowledgeRemediation':
				this.addKnowledgeRemediationSave(remModel)
				break
			case 'checkListRemediation':
				this.addCheckListRemediationSave(remModel)
				break
			default:
				break

		}
	}

	public addTaskRemediationSave(remModel: any) {
		let jobProfile: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let ccModel: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let taskModel: Task = this.jobProfileService.getSelectedTaskModel()

		let tempJP: JobProfile = new JobProfile(jobProfile.jobProfileId)
		let tempTask: Task = new Task(taskModel.id, taskModel.text, true, [], [], [])
		tempTask.remediationList.push(remModel)
		let tempCC: CoreCompetency = new CoreCompetency(ccModel.id, ccModel.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addTaskRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				taskModel.remediationList.push(remModel)
				this.alertService.clear();
				this.cancel();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			})
	}

	//add performance remediation to job profile
	public addPerformanceRemediationSave(remModel: any) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let per: Performance = this.jobProfileService.getSelectedPerformanceModel()

		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempPer: Performance = new Performance(per.id, per.text, true, [], [])
		tempPer.remediationList.push(remModel)
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.performanceList = tempPer
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addPerformanceRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				per.remediationList.push(remModel);
				this.alertService.clear();
				this.cancel();
			},
			error => {
				this.showError(error.json());
			});
	}

	//add knowledge remediation to job profile
	public addKnowledgeRemediationSave(remModel: any) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let know: Knowledge = this.jobProfileService.getSelectedKnowledgeModel()
		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempKno: Knowledge = new Knowledge(know.knowledge, 0)
		tempKno.id = know.id
		tempKno.remediationList = []
		tempKno.remediationList.push(remModel)
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.knowledgeList = tempKno
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addKnowledgeRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				know.remediationList.push(remModel)
				this.alertService.clear();
				this.cancel();
			},
			error => {
				this.showError(error.json())
			}
			)
	}

	//add check list remediation to job profile
	public addCheckListRemediationSave(remModel: any) {
		let jp: JobProfile = this.jobProfileService.getSelectedJobProfileForEdit()
		let cc: CoreCompetency = this.jobProfileService.getSelectedJPCCModel()
		let task: Task = this.jobProfileService.getSelectedTaskModel()
		let per: Performance = this.jobProfileService.getSelectedPerformanceModel()
		let cl: CheckList = this.jobProfileService.getSelectedCheckListModel()

		let tempJP: JobProfile = new JobProfile(jp.jobProfileId)
		let tempCL: CheckList = new CheckList(cl.id)
		tempCL.remediationList = []
		tempCL.remediationList.push(remModel)
		let tempPer: Performance = new Performance(per.id, per.text, true)
		tempPer.checkList = tempCL
		let tempTask: Task = new Task(task.id, task.text)
		tempTask.performanceList = tempPer
		let tempCC: CoreCompetency = new CoreCompetency(cc.id, cc.text)
		tempCC.taskList = tempTask
		tempJP.coreCompetencyList = tempCC

		this.jobProfileService.addCheckListRemediationToJobProfile(tempJP)
			.subscribe(
			data => {
				cl.remediationList.push(remModel)
				this.alertService.clear();
				this.cancel();
			},
			error => {
				this.showError(error.json())
			}
			)
	}


}
