import { Component, OnInit, ViewChildren, QueryList, ElementRef, Renderer2, ContentChildren, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { AlertService } from "../../util/alert/alert.service";
import { SharedService } from "../../home/shared.service";
import { TaskService } from '../../complib/task/task.service';
import { TableComponent } from '../../util/table/table.component'
import { IMyDpOptions, IMyInputFieldChanged, IMyDateModel } from 'mydatepicker';
import { Constants } from '../../util/const/constants';
import { OrgcodeService } from '../../orgcode/orgcode.service'
import { OrderArrayByType } from '../../util/orderarraybytype.pipe';
import { RegulatoryAgencyService } from '../../complib/regulatoryagency/regulatoryagency.service';
import { ReportService } from "../report.service";
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: "app-missed-task-matrix-report",
	templateUrl: "./missedtaskmatrixreport.component.html",
	styleUrls: ['./missedtaskmatrixreport.component.scss']
})

export class MissedTaskMatrixReportComponent implements OnInit, AfterViewInit {
	public orgList: Array<any> = [];
	public missedTaskMatrixData = [];
	public allMissedTaskList = [];
	public taskListWithoutDuplicate = [];
	public allUsersWithMissedTask = [];
	public filteredData: Array<any> = [];
	public palceholderText: any;
	public selectedOrgId: string = null;
	public isQueryRemediationmatrix: boolean = false;
	public isLoading: boolean = false;
	public noResults: boolean = false;
	public showResult: boolean = false;
	public maxCounter;
	public userCount: number = 0;
	public supervisorId = '';
	public allClicked: boolean = true
	public rgList: Array<any> = [];
	public selectRgList: Array<any> = [];
	public palceholderTextReg: any;
	public selectedItems = []
	public isDataExist: boolean = false;
	public selectedOrgCode: any
	public selectedRegList = []
	public printData = []
	public newPrintData = []
	public regList = []
	public filterRegList = []

	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	numPages: number = 1;

	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');

	public missedTaskMatrixForm: FormGroup
	@ViewChildren('firstCell') firstCell: QueryList<ElementRef>;
	@ViewChildren('jobProfile') jobProfile: QueryList<ElementRef>;
	@ViewChildren('secondCell') secondCell: QueryList<ElementRef>;
	@ViewChildren('userName') userName: QueryList<ElementRef>;
	@ViewChildren('row') row: QueryList<ElementRef>;
	@ViewChildren('rem') rem: QueryList<ElementRef>;

	constructor(private taskService: TaskService,
		private alertService: AlertService, private orgcodeService: OrgcodeService, private renderer: Renderer2,
		private changeDetectorRef: ChangeDetectorRef, private pipe: OrderArrayByType,
		private regulatoryAgencyService: RegulatoryAgencyService, private reportService: ReportService,private sanitizer: SanitizationService) {

		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
			if (this.selectedUserRole[0][1] == 'Supervisor') {
				this.supervisorId = localStorage.getItem('userId');
			}
		}
	}

	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.taskListWithoutDuplicate = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	ngOnInit() {
		//load active org code
		this.loadActiveOrgCode()
		//load all reg list
		this.loadAllRegulatoryAgencies()
		//define form
		this.missedTaskMatrixForm = new FormGroup({
			missedTaskMatrix: new FormControl(null),
			includeChildren: new FormControl(false),
			regulatoryAgency: new FormControl(null)
		});
	}

	private loadAllRegulatoryAgencies() {
		this.regulatoryAgencyService.getAllRegulatoryAgency()
			.subscribe(
			data => {
				this.rgList = [];
				this.selectRgList = []
				for (var i = 0; i < data.regulatoryAgencyList.length; i++) {
					this.rgList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].Description + " - " + data.regulatoryAgencyList[i].ShortName });
					this.selectRgList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].ShortName })
				}
				if (this.rgList.length > 0) {
					this.palceholderTextReg = 'Select regulatory agencies'
				} else {
					this.palceholderTextReg = 'No items available'
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});

	}

	// public removeRegulatoryAgency(event: any) {	
	// 	this.selectedItems = this.selectedItems.filter(item => item !== event.id)
	// 	this.showResult = false
	// 	this.noResults = false
	// 	this.missedTaskMatrixData = []
	// 	this.isDataExist = false

	// }

	public selectRegulatoryAgency(event: any) {
		this.selectedItems = event
		this.noResults = false
		this.showResult = false
		this.isDataExist = false
	}


	ngAfterViewInit() {

	}

	ngAfterViewChecked() {
		this.firstCell.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.firstCell = comps
		});

		this.secondCell.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.secondCell = comps
		});
		this.row.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.row = comps
		});

		this.userName.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.userName = comps;
			this.renderer.setStyle(
				this.secondCell.first.nativeElement,
				'height',
				comps.first.nativeElement.offsetHeight + 'px'
			);
		});

		this.jobProfile.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.jobProfile = comps;
			this.renderer.setStyle(
				this.firstCell.first.nativeElement,
				'height',
				comps.first.nativeElement.offsetHeight + 'px'
			);
		});

		this.rem.changes.subscribe((comps: QueryList<ElementRef>) => {
			this.rem = comps;
			let rowIndex = 0;
			let remIndex = 0;
			comps.forEach((element, index) => {
				if (index == remIndex) {
					// console.log(index + " == " + remIndex)
					let rowHeight = this.row.filter((ele, i) => i === rowIndex)[0].nativeElement.offsetHeight
					// console.log(rowHeight)
					this.renderer.setStyle(
						element.nativeElement,
						'height',
						rowHeight + 'px')
					remIndex = remIndex + this.userCount
					rowIndex++
				}
			}
			);
		});
	}

	showAll() {
		this.allClicked = true
		this.selectedItems = []
		this.noResults = false
		this.showResult = false
		this.isDataExist = false

	}

	showRegulatoryAgency() {
		this.allClicked = false
		this.selectedItems = []
		this.noResults = false
		this.showResult = false
		this.isDataExist = false
	}

	//call form submission
	onSubmit() {
		this.noResults = false
		this.missedTaskMatrixData = []
		this.taskListWithoutDuplicate = []
		this.printData = []
		this.newPrintData = []
		this.showResult = false
		this.isLoading = true
		var selectedItemsIds = []
		if (this.selectedItems.length > 0) {
			this.selectedItems.forEach(element => {
				selectedItemsIds.push(element.id)
			})
		}
		this.taskService.getMissedTaskByLastAssessmentOfUser(this.selectedOrgId, this.missedTaskMatrixForm.get("includeChildren").value,
			this.supervisorId, selectedItemsIds)
			.subscribe(
			data => {
				this.isLoading = false
				this.missedTaskMatrixData = []
				this.allMissedTaskList = []
				this.taskListWithoutDuplicate = []
				this.allUsersWithMissedTask = []
				this.filterRegList = []
				if (data.userList.length > 0) {
					this.noResults = false
					this.showResult = true
					this.userCount = data.userList.length
					for (var i = 0; i < data.userList.length; i++) {
						let userList = []
						let id = data.userList[i].userId
						let userFirstName = data.userList[i].userFirstName
						let userLastName = data.userList[i].userLastName
						let jobprofile = data.userList[i].jobProfileDescription
						let jobProfileId = data.userList[i].jobProfieId
						let missedTaskList = data.userList[i].missedTaskList
						let userFullName = userFirstName + " " + userLastName
						let supervisor = data.userList[i].supervisor
						let contractor = data.userList[i].contractor
						let orgCode = data.userList[i].orgCode
						let assessmentCompletedDate = null
						if (data.userList[i].assessmentCompletedDate == null) {
							assessmentCompletedDate = null
						} else {
							assessmentCompletedDate = new Date(new Date(data.userList[i].assessmentCompletedDate).getUTCFullYear(), new Date(data.userList[i].assessmentCompletedDate).getUTCMonth(), new Date(data.userList[i].assessmentCompletedDate).getUTCDate());
						}
						var userItem = {
							id, userFirstName, userLastName, userFullName, missedTaskList, jobprofile,
							supervisor, contractor, orgCode, assessmentCompletedDate
						}
						userList.push(userItem)
						this.allUsersWithMissedTask.push(userItem)
						missedTaskList.forEach(item => {
							// var regList = []
							// regList.push(item.Regulatories)
							// let regList = item.Regulatories
							// if(!this.checkAvailableTask(id, item.Regulatories)){
							//push regulatories 
							this.filterRegList.push({ 'id': item.Id, 'regulatories': item.Regulatories })
							// }
							this.allMissedTaskList.push({ 'itemId': item.Id, 'id': item.TaskId, 'description': item.Task })
						});
						//getting same job profile data
						if (!this.addToExisting(jobProfileId, userItem)) {
							this.missedTaskMatrixData.push({ jobProfileId, jobprofile, userList })
						}

					}

					this.taskListWithoutDuplicate = this.getFrequencyOfMissedTask(this.allMissedTaskList, this.allUsersWithMissedTask)
					this.printData = this.generatePrintData(this.allMissedTaskList, this.allUsersWithMissedTask)
					if (this.printData.length > 0) {
						for (var i = 0; i < this.printData.length; i++) {
							let currentUserId = this.printData[i].userList[0].userId
							this.newPrintData.push({
								'description': this.printData[i].description, 'id': this.printData[i].id, 'itemId': this.printData[i].itemId,
								'firstName': this.printData[i].userList[0].firstName, 'lastName': this.printData[i].userList[0].lastName,
								'jobprofile': this.printData[i].userList[0].jobprofile, 'score': this.printData[i].score,
								'taskCount': this.printData[i].taskCount, 'supervisor': this.printData[i].userList[0].supervisor,
								'contractor': this.printData[i].userList[0].contractor, 'orgCode': this.printData[i].userList[0].orgCode,
								'assessmentCompletedDate': this.printData[i].userList[0].assessmentCompletedDate
							})
							for (var j = 1; j < this.printData[i].userList.length; j++) {
								if (this.printData[i].userList[j].userId === currentUserId) {

								} else {
									currentUserId = this.printData[i].userList[j].userId
									this.newPrintData.push({
										'description': this.printData[i].description, 'id': this.printData[i].id, 'itemId': this.printData[i].itemId,
										'firstName': this.printData[i].userList[j].firstName, 'lastName': this.printData[i].userList[j].lastName,
										'jobprofile': this.printData[i].userList[j].jobprofile, 'score': this.printData[i].score,
										'taskCount': this.printData[i].taskCount, 'supervisor': this.printData[i].userList[j].supervisor,
										'contractor': this.printData[i].userList[j].contractor, 'orgCode': this.printData[i].userList[j].orgCode,
										'assessmentCompletedDate': this.printData[i].userList[j].assessmentCompletedDate
									})
								}

							}
						}
					}
					this.newPrintData = this.pipe.transform(this.newPrintData, 'alphabeticMissedTaskMatrixPrint')
					this.filteredData = this.taskListWithoutDuplicate
					this.filteredData = this.pipe.transform(this.filteredData, 'alphabeticMissedTaskMatrix')
					this.pageChanged();
					this.isDataExist = true

				} else {
					this.noResults = true
					this.showResult = false
					this.isDataExist = false
				}
			},
			error => {
				this.isLoading = false
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
				this.isDataExist = false
			});
	}

	//getting same job profile data
	addToExisting(jobProfileId: any, userItem: any) {
		var found: boolean = false;
		for (var i = 0; i < this.missedTaskMatrixData.length; i++) {
			var temp = this.missedTaskMatrixData[i].jobProfileId
			if (temp == jobProfileId) {
				found = true
				this.missedTaskMatrixData[i].userList.push(userItem)
				break
			}
		}
		return found;
	}

	// checkAvailableTask(taskId: any, rList) {
	// 	var found: boolean = false;
	// 	for (var i = 0; i < this.filterRegList.length; i++) {
	// 		var temp = this.filterRegList[i].id
	// 		if (temp == taskId) {
	// 			found = true
	// 			this.filterRegList[i].regList.push(rList)
	// 			break
	// 		}
	// 	}
	// 	return found;
	// }	

	//get remediation frequency count and higest count
	private getFrequencyOfMissedTask(tasklist: any, userList: any) {
		let visited = -1
		let fr = []
		let maxCounter = 0;
		for (var i = 0; i < tasklist.length; i++) {
			let count = 1
			let counter = 1;
			for (let j = i + 1; j < tasklist.length; j++) {
				if (tasklist[i].id == tasklist[j].id) {
					count++
					counter++;
					//To avoid counting same element again  
					fr[j] = visited
				}
			}
			if (fr[i] != visited) {
				fr[i] = count
			}
			if (maxCounter < counter) {
				maxCounter = counter;
			}
		}
		for (var i = 0; i < fr.length; i++) {
			if (fr[i] != visited) {
				let userTaskList = this.getMissedtaskUserList(tasklist[i].id, userList)
				let score = Math.round((fr[i] / maxCounter) * 100)
				this.taskListWithoutDuplicate.push({ 'id': tasklist[i].id, 'description': tasklist[i].description, 'taskCount': fr[i], 'score': score, 'userList': userTaskList })
			}
		}
		return this.taskListWithoutDuplicate
	}

	private generatePrintData(tasklist: any, userList: any) {
		let visited = -1
		let fr = []
		let maxCounter = 0;
		for (var i = 0; i < tasklist.length; i++) {
			let count = 1
			let counter = 1;
			for (let j = i + 1; j < tasklist.length; j++) {
				if (tasklist[i].id == tasklist[j].id) {
					count++
					counter++;
					//To avoid counting same element again  
					fr[j] = visited
				}
			}
			if (fr[i] != visited) {
				fr[i] = count
			}
			if (maxCounter < counter) {
				maxCounter = counter;
			}
		}
		for (var i = 0; i < fr.length; i++) {
			if (fr[i] != visited) {
				let userTaskList = this.getMissedtaskPrintUserList(tasklist[i].id, userList)
				let score = Math.round((fr[i] / maxCounter) * 100)
				this.printData.push({
					'itemId': tasklist[i].itemId, 'id': tasklist[i].id, 'description': tasklist[i].description, 'regulatories': tasklist[i].regulatories,
					'taskCount': fr[i], 'score': score, 'userList': userTaskList
				})
			}
		}
		return this.printData
	}

	private getMissedtaskUserList(taskId: any, userList: any) {
		var taskUserlist = []
		for (var i = 0; i < userList.length; i++) {
			userList[i].missedTaskList.forEach(item => {
				if (item.TaskId == taskId) {
					taskUserlist.push({ 'userId': userList[i].id })
				}
			});
		}
		return taskUserlist
	}

	private getMissedtaskPrintUserList(taskId: any, userList: any) {
		var taskUserlist = []
		for (var i = 0; i < userList.length; i++) {
			userList[i].missedTaskList.forEach(item => {
				if (item.TaskId == taskId) {
					taskUserlist.push({
						'userId': userList[i].id, 'firstName': userList[i].userFirstName, 'lastName': userList[i].userLastName,
						'jobprofile': userList[i].jobprofile, 'supervisor': userList[i].supervisor, 'orgCode': userList[i].orgCode,
						'contractor': userList[i].contractor, 'assessmentCompletedDate': userList[i].assessmentCompletedDate
					})
				}
			});
		}
		return taskUserlist
	}

	//start cell coloring logic
	getStyle(userId: any, taskList: any, score: any) {
		let style = ''
		let found: boolean = false;
		for (let i = 0; i < taskList.length; i++) {
			if (taskList[i].userId == userId) {
				found = true
				break
			}
		}
		style = this.selectStyle(found, score)
		return style
	}

	private selectStyle(found: boolean, score: any) {
		let style = ''
		if (found == true) {
			if (1 <= score && score <= 33) {
				style = " text-center cell-green";
			} else if (34 <= score && score <= 66) {
				style = " text-center cell-yellow";
			} else if (67 <= score && score <= 100) {
				style = " text-center cell-red";
			} else {
				style = ""
			}
		} else {
			style = ''
		}
		return style
	}

	private loadActiveOrgCode() {
		this.orgcodeService.getActiveOrgcodes()
			.subscribe(
			data => {
				this.orgList = [];
				for (var i = 0; i < data.orgcode.length; i++) {
					this.orgList.push({ id: data.orgcode[i].Id, text: data.orgcode[i].Description });
				}
				if (this.orgList.length > 0) {
					this.palceholderText = 'Select org code'
				} else {
					this.palceholderText = 'No items available'
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});

	}

	public removeOrgCode(event: any) {
		this.isQueryRemediationmatrix = false
		this.noResults = false
		this.missedTaskMatrixData = []
		this.showResult = false
		this.selectedOrgCode = null
	}

	public selectOrgCode(event: any) {
		this.isQueryRemediationmatrix = true
		this.selectedOrgId = event.id
		this.noResults = false
		this.selectedOrgCode = event.text
	}

	public onResize(event: any) {
		this.noResults = false
		let temp = this.missedTaskMatrixData
		let tempRemList = this.taskListWithoutDuplicate
		this.missedTaskMatrixData = []
		this.taskListWithoutDuplicate = []
		this.showResult = false
		this.isLoading = true
		this.missedTaskMatrixData = temp
		this.taskListWithoutDuplicate = tempRemList
		this.isLoading = false
		this.showResult = true

		this.renderer.setStyle(
			this.secondCell.first.nativeElement,
			'height',
			this.userName.first.nativeElement.offsetHeight + 'px'
		);

		this.renderer.setStyle(
			this.firstCell.first.nativeElement,
			'height',
			this.jobProfile.first.nativeElement.offsetHeight + 'px'
		);

		let rowIndex = 0;
		let remIndex = 0;
		this.rem.forEach((element, index) => {
			if (index == remIndex) {
				// console.log(index + " == " + remIndex)
				let rowHeight = this.row.filter((ele, i) => i === rowIndex)[0].nativeElement.offsetHeight
				// console.log(rowHeight)
				this.renderer.setStyle(
					element.nativeElement,
					'height',
					rowHeight + 'px')
				remIndex = remIndex + this.userCount
				rowIndex++
			}
		}
		);

	}

	print() {
		let popupWinindow;
		let content = this.loadHtmlData()
		popupWinindow = window.open(
			"",
			"_blank",
			"width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
		);
		popupWinindow.document.open();
		popupWinindow.document.write(
			'<html><head><link rel="stylesheet" type="text/css" href="style.css" />' +
			'<style>.print-center{text-align: center !important; }</style>' +
			'<style>.cell-green {-webkit-print-color-adjust: exact !important; background-color: #4dff4d !important;}</style>' +
			'<style>.cell-yellow {-webkit-print-color-adjust: exact !important; background-color: #ffff4d !important;}</style>' +
			'<style>.cell-red {-webkit-print-color-adjust: exact !important; background-color: #ff4d4d !important;}</style>' +
			'<style>.center { margin-left: auto; margin-right: auto; }</style>' +
			'</head><body onload="window.print()">' +
			content +
			"</html>"
		);
		popupWinindow.document.close();
	}

	loadHtmlData() {
		let reportHTML = '<h1>Missed Task Matrix</h1>';
		reportHTML += '<div style="font-size: 1.1rem">Org Code - ' + this.selectedOrgCode + '</div>'
		var shortName = []
		if (this.selectedItems.length > 0) {
			this.selectedItems.forEach(item => {
				shortName.push(this.selectRgList.filter(temp => temp.id == item.id));
			})
			var items = ''
			shortName.forEach(item => {
				item.forEach(element => {
					if (items) {
						items += ", "
					}
					items += element.text
				});
			})
			reportHTML += '<div style="font-size: 1.1rem">Regulatory Agency(s): ' + items + '</div>'
		}

		reportHTML += "<div style='padding-top:20px'><table width=1000px' border=1 style='border-collapse:collapse;'> " +
			"<thead>" +
			"<tr style='width=100%'>";
		reportHTML += "<th style='width:20%'> Missed Task</th>";
		reportHTML += "<th style='width:20%'> Job Profile </th>";
		reportHTML += "<th style='width:20%'> First Name </th>";
		reportHTML += "<th style='width:20%'> Last Name </th>";
		reportHTML += "<th style='width:20%'> Total </th>";
		reportHTML += "</tr></thead>"
		reportHTML += "<tbody> ";
		reportHTML += "<tr>";

		for (let i = 0; i < this.newPrintData.length; i++) {
			reportHTML += "<td style='width:20%'>" + this.newPrintData[i].description;
			reportHTML += "<td style='width:20%'>" + this.newPrintData[i].jobprofile
			reportHTML += "<td style='width:20%'>" + this.newPrintData[i].firstName;
			reportHTML += "<td style='width:20%'>" + this.newPrintData[i].lastName;
			if (1 <= this.newPrintData[i].score && this.newPrintData[i].score <= 33) {
				reportHTML += "<td style='width:20%' class='print-center cell-green'>" + this.newPrintData[i].taskCount;
			} else if (34 <= this.newPrintData[i].score && this.newPrintData[i].score <= 66) {
				reportHTML += "<td style='width:20%' class='print-center cell-yellow'>" + this.newPrintData[i].taskCount;
			} else if (67 <= this.newPrintData[i].score && this.newPrintData[i].score <= 100) {
				reportHTML += "<td style='width:20%' class='print-center cell-red'>" + this.newPrintData[i].taskCount;
			}
			reportHTML += "</td></tr>";
		}
		reportHTML += "</tbody></table></div>"
		return reportHTML
	}

	exportToExcel() {
		var data = []
		let outputName = ''
		outputName = "MissedTaskMatrix_" + this.selectedOrgCode
		this.newPrintData.forEach(item => {
			let rList = []
			var items = ''

			rList = this.filterRegList.filter(temp => temp.id == item["itemId"]);

			var temp = {
				'Missed Task': item["description"],
				'Job Profile': item["jobprofile"],
				'First Name': item["firstName"],
				'Last Name': item["lastName"],
				'Org Code': item["orgCode"],
				'Regulatory Agency(s)': rList[0].regulatories == null ? '' : rList[0].regulatories,
				'Assessment Completed Date': item["assessmentCompletedDate"],
				'Supervisor': item["supervisor"] == null ? '' : item["supervisor"],
				'Contractor': item["contractor"] == 1 ? 'Yes' : 'No',
				'Percentage': item["score"] + '%',
				'Total': item["taskCount"]
			}
			data.push(temp)
		})
		this.reportService.exportAsExcelFile(data, outputName);
	}
}