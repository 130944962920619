
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { CustomModalService } from "./custommodal.service";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import { CustomModal } from "./custommodal.modal";
import { AlertService } from '../alert/alert.service';
import { Constants } from '../const/constants';
import * as $ from 'jquery';
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: "app-modal",
	templateUrl: "custommodal.component.html",
	styleUrls: ["./custommodal.component.scss"]
})

export class CustomModalComponent implements OnInit {
	@ViewChild("disableWithDependencyModal", { static: true })
	disableWithDependencyModal: ModalComponent;
	@ViewChild("deleteCancellationModal", { static: true })
	deleteCancellationModal: ModalComponent;
	@ViewChild("deleteModal", { static: true })
	deleteModal: ModalComponent;
	@ViewChild("statusChangeModal", { static: true })
	statusChangeModal: ModalComponent;
	@ViewChild("showHideChangeModal", { static: true })
	showHideChangeModal: ModalComponent;
	@ViewChild("dependencyModal", { static: true })
	dependencyModal: ModalComponent;
	@ViewChild("copytModal", { static: true })
	copytModal: ModalComponent;
	@ViewChild("defaultChangeModal", { static: true })
	defaultChangeModal: ModalComponent;

	private element: JQuery;
	public modal: CustomModal;
	public statusText: any;
	public statusTitle: any;
	public hasOtherChanges: boolean;

	constructor(
		private alertService: AlertService,
		private CustomModalService: CustomModalService,
		private el: ElementRef,
		private sanitizer: SanitizationService
	) {
		this.element = $(el.nativeElement);
	}

	ngOnInit() {
		// move element to bottom of page (just before </body>) so it can be displayed above everything else
		this.element.appendTo("body");

		// add self (this modal instance) to the modal service so it's accessible from controllers
		this.modal = <CustomModal>{ title: "", dependency: null, itemId: "", component: null, bodyText: "" };
		this.CustomModalService.add(this);
	}

	public statusChanege() {
		this.modal.component.changeStatus(this.modal.itemId, this.modal.status);
	}

	public delete() {
		this.modal.component.delete();
	}

	public showHideChanege() {
		this.modal.component.showHideEvent(this.modal.itemId, this.modal.status);
	}

	public setDefaultReason() {
		this.modal.component.setDefaultReason(this.modal.itemId);
	}

	public open(modalId: string) {
		if (modalId == 'statusChange') {
			if (this.modal.status == 0 || this.modal.dependency == null || this.modal.dependency.length == 0) {
				if (this.modal.title == 'Org Code') {
					this.modal.component.changeStatus(this.modal.itemId, this.modal.component.editOrgcodeForm.value.status);
				}
				else {
					this.statusTitle = this.modal.status == 0 ? "Enable" : "Disable";
					this.statusText = this.statusTitle.toLowerCase();
					this.statusChangeModal.open();
				}
			}
			else if (this.modal.dependency != null && this.modal.dependency.length > 0) {
				if (this.modal.title == 'Org Code') {	
					if(this.modal.component.selectedPreviousEditOrgcode!= this.modal.component.editOrgcodeForm.value.editorgcode || this.modal.component.selectedEditOrgtype != this.modal.component.editOrgcodeForm.value.editorgtype){
						if(this.modal.component.selectedStatus == 1 && this.modal.component.editOrgcodeForm.value.status == 0){
							this.hasOtherChanges = true;
							this.modal.component.close();
							this.disableWithDependencyModal.open('lg');
							setTimeout(() => {
								this.modal.component.changeStatus(this.modal.itemId, this.modal.component.selectedStatus);
							  }, 2000);

						}
						
					}	else {
						this.hasOtherChanges = false;
						this.modal.component.close();
						this.disableWithDependencyModal.open('lg');
					}			
					// this.modal.component.close();

				} else {
					this.disableWithDependencyModal.open('lg');
				}
				
			}
		}
		else if (modalId == 'delete') {			
			if (this.modal.dependency != null && this.modal.dependency.length > 0) {
				if (this.modal.title == 'Role') {
					this.deleteCancellationModal.open('lg');
					this.modal.status.checked = true;
					this.modal.component.access = true;
				}
				else if (this.modal.title == 'Attribute') {
					this.alertService.clear();
					this.alertService.error(Constants.ERROR_ONGOING_ASSESSMENTS);
					this.modal.status[0].checked = true;
					this.modal.status[1].checked = false;
				}
				else {
					this.deleteCancellationModal.open('lg');
				}
			}
			else if (this.modal.title != 'Role' && this.modal.title != 'Attribute') {				
				this.deleteModal.open();
			}
		}
		else if (modalId == 'showHide') {
			this.statusTitle = this.modal.status == 0 ? "Show" : "Hide";
			this.statusText = this.statusTitle.toLowerCase();
			this.showHideChangeModal.open();
		}
		else if (modalId == 'edit') {
			this.dependencyModal.open('lg');
		}
		else if (modalId == 'copy') {
			this.copytModal.open('lg');
		} else if (modalId == 'defaultNA') {
			this.defaultChangeModal.open('lg');
		}
	}

	exportToExcel(reportName: string, excelData: any[]) {
		var data = []
		excelData.forEach(item => {
			var temp = {
				"": item["type"],
				" ": item["value"]
			}
			data.push(temp)
		})
		this.CustomModalService.exportAsExcelFile(data, reportName);
	}

	print(excelData: any[]) {
		let popupWinindow;
		let content = this.loadReportHTML(excelData);
		popupWinindow = window.open(
			"",
			"_blank",
			"width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
		);
		popupWinindow.document.open();
		popupWinindow.document.write(
			'<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
			content +
			"</html>"
		);
		popupWinindow.document.close();
	}

	public loadReportHTML(excelData: any[]) {
		let reportHTML =
				"<h1> Missing items of the job profile copy </h1>";
			
		if (excelData.length > 0) {
			reportHTML = reportHTML + '<table width="100%" border=1 style="border-collapse:collapse;" ><tr></tr>'
			for (let j = 0; j < excelData.length; j++) {
				if(excelData[j].type != ''){
					reportHTML = reportHTML + "<tr><td><b>" + excelData[j].type + "<b></td></tr>"
				}
				if(excelData[j].value != ''){
					reportHTML = reportHTML + "<tr><td>" + excelData[j].value + "</td></tr>"
				}
			}
			
			reportHTML = reportHTML + "</table>"
		}
		return reportHTML;
	}
	
}
