import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { SharedService } from '../../home/shared.service';
import { AlertService } from '../../util/alert/alert.service';
import { PerformanceTagService } from './performancetag.service';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-editperformancetag',
	templateUrl: './editperformancetag.component.html'
})

export class EditPerformanceTagComponent implements OnInit {
	editPerformanceTag: FormGroup;
	constructor(private router: Router,
		private sharedService: SharedService,
		private alertServices: AlertService,
		private performanceTagService: PerformanceTagService,
		private userService: UserService,
		private sanitizer: SanitizationService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.editPerformanceTag = new FormGroup({
			id: new FormControl(this.performanceTagService.curPerId, Validators.required),
			performanceTag: new FormControl(this.performanceTagService.curPerformance, Validators.required)
		});
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedToUpdatePerformance()) {
			this.performanceTagService.editPerformanceTag(this.editPerformanceTag.value.id, this.sanitizer.sanitize(this.editPerformanceTag.value.performanceTag))
				.subscribe(
				data => {
					this.alertServices.clear();
					this.alertServices.success("Successfully modified the performance tag.");
					this.performanceTagService.curPerformance = this.editPerformanceTag.value.performanceTag;
				},
				error => {
					this.alertServices.clear();
					let err = error.json();
					this.alertServices.handleError(err);
				}
				);
		} else {
			this.alertServices.clear();
			this.alertServices.error('There are no changes to update.');
		}
	}

	checkNeedToUpdatePerformance() {
		if (this.performanceTagService.curPerformance != this.editPerformanceTag.value.performanceTag) {
			return true;
		} else {
			return false;
		}
	}
}