import {
	Component,
	ViewContainerRef,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import {
	FormGroup,
	FormControl,
	Validators,
	FormBuilder
} from "@angular/forms";

import { OrgcodeService } from "./orgcode.service";
import { orgcode } from "./orgcode";
import { UserService } from "../user/user.service";
import { AlertService } from "../util/alert/alert.service";
import { OrgtypeService } from "../orgtype/orgtype.service";
import { CustomModalService } from "../util/custommodal/custommodal.service";
import { NoWhitespaceDirective } from "../util/validator/nowhitespace.directive"
import { SanitizationService } from '../sanitizer.service';
declare var $: any;

@Component({
	selector: "app-orgcode",
	templateUrl: "./orgcode.component.html",
	styleUrls: []
})
export class OrgCodeComponent {
	@ViewChild("addOrgcodemodel", { static: true }) addOrgcodemodel: ModalComponent;
	@ViewChild("editOrgcodemodel", { static: true }) editOrgcodemodel: ModalComponent;

	orgtypes: any[];
	editorOrgTypes: any[]
	statusOptions:any[]
	selectedOrgtype;
	selectedEditOrgcode;
	selectedPreviousEditOrgcode;
	selectedPreviousEditOrgtype;
	selectedEditOrgtype: String;
	selectedEditStatus: String;
	selectedStatus: String;
	selectededitorgcodeId;
	addOrgcodeForm: FormGroup;
	editOrgcodeForm: FormGroup;
	isAddOrgCodeAllowed: boolean = false;
	isDeleteOrgCodeAllowed: boolean = false;
	isModifyOrgCodeAllowed: boolean = false;
	isQuerOrgCodeAllowed: boolean = false;
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem("selectedUserRole");
	private data: Array<any> = [];
	public editOrgcode: orgcode;


	constructor(
		private customModalService: CustomModalService,
		private orgcodeService: OrgcodeService,
		private userService: UserService,
		private alertServices: AlertService,
		private orgtypeService: OrgtypeService,
		private formBuilder: FormBuilder,
		private sanitizer: SanitizationService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	getOrgtype() {
		this.orgtypeService.geActiveOrgtype()
			.subscribe(
			data => {
				this.orgtypes = [];
				for (var i = 0; i < data.orgType.length; i++) {
					this.orgtypes.push({ id: data.orgType[i].Id, text: data.orgType[i].Orgtype });
				}
				this.editorOrgTypes = data.orgType;
			})
	}

	ngOnInit() {
		this.statusOptions = [
			{ id: 1, label: 'Active' },
			{ id: 0, label: 'Inactive' }
		  ];

		this.getOrgtype();
		if (this.selectedUserRole) {
			this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		}

		//get capabilities
		this.isAddOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"AddOrgCode",
			this.selectedUserRole[0][1]
		);
		this.isDeleteOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"DeleteOrgCode",
			this.selectedUserRole[0][1]
		);
		this.isModifyOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"ModifyOrgCode",
			this.selectedUserRole[0][1]
		);
		this.isQuerOrgCodeAllowed = this.userService.isCapabilityAssigned(
			"QueryOrgCode",
			this.selectedUserRole[0][1]
		);

		//check query capability
		if (this.isQuerOrgCodeAllowed) {
			this.getOrgcode();
		} else {
			//Query capability is not assigned
		}

		this.addOrgcodeForm = this.formBuilder.group({
			orgcode: new FormControl(null, Validators.required),
			orgtype: new FormControl(null, Validators.required)
		});

		this.editOrgcodeForm = new FormGroup({
			editorgcode: new FormControl(null, Validators.required),
			editorgtype: new FormControl(Validators.required),
			status: new FormControl(Validators.required),
			editorgcodeId: new FormControl()
		});
	}

	getOrgcode() {
		this.orgcodeService.getOrgcode().subscribe(
			data => {
				this.data = [];
				for (let i = 0; i < data.orgCode.length; i++) {
					if (data.orgCode[i].status == 1) {
						this.data.push({
							id: data.orgCode[i].id,
							parent: data.orgCode[i].parent,
							text: data.orgCode[i].text,
							li_attr: { title: data.orgCode[i].orgtype },
							icon: "fa fa-folder-o",
							state: {'opened': true}
						});
					} else {
						this.data.push({
							id: data.orgCode[i].id,
							parent: data.orgCode[i].parent,
							text: data.orgCode[i].text,
							li_attr: { title: data.orgCode[i].orgtype },
							icon: "fa fa-times",
							state: {'opened': true}
						});
					}
				}
				$("#tree").jstree("destroy");
				$("#tree")
					.jstree({themes: {
							variant: 'normal'
						},
						'core': {
							data: this.data,
							check_callback: true							
						},
						types: {
							default: {
								"icon" : "fa fa-file-o "
							}
						},
						plugins: ["dnd", "search", "state", "types", "wholerow", "contextmenu"],
					})
					// .on('open_node.jstree', function (e, data) { data.instance.set_icon(data.node, "fa fa-folder-open-o");
					// })
					//.on('close_node.jstree', function (e, data) { data.instance.set_icon(data.node, "fa fa-folder-o");
					// })
					
			},
			error => { }
		);
	}

	addNode() {
		//create first root node
		if (this.data.length == 0) {
			this.addOrgcodemodel.open();
		} else {
			var parentId = $("#tree").jstree("get_selected");
			var status = $("#tree").jstree("get_node", parentId).icon;			
			if (parentId == "") {
				this.alertServices.clear();
				this.alertServices.error(
					"Please select parent org code before adding."
				);
			} 
			if (status == "fa fa-times") {
				this.alertServices.clear();
				this.alertServices.error("Cannot add a child to an inactive org code.");			
			}else {
				this.addOrgcodemodel.open();
			}
		}
	}

	addNodeSave() {
		var parentId = $("#tree").jstree("get_selected");
		var orgTypeId = this.addOrgcodeForm.get("orgtype").value[0].id
		var description = this.sanitizer.sanitize(this.addOrgcodeForm.value.orgcode);
		const tempOrgCode = new orgcode("", orgTypeId, description, parentId);
		this.orgcodeService.addOrgCode(tempOrgCode).subscribe(
			data => {
				this.addOrgcodemodel.close();
				this.refreshTree();
				setTimeout(() => $("#tree").jstree("open_node", parentId), 250);
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.addOrgcodeForm.reset();
			},
			error => {
				this.addOrgcodemodel.close();
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	renameNode() {
		var pId = $("#tree").jstree("get_selected");
		if (pId == "") {
			this.alertServices.clear();
			this.alertServices.error("Please select org code for edit.");
		} else {
			var selectedNodeId = $("#tree").jstree("get_selected");
			this.orgcodeService.getOrgcodeById(selectedNodeId).subscribe(
				data => {
					this.selectedEditOrgcode = data.Orgcode;
					this.selectedPreviousEditOrgcode = data.Orgcode;
					this.selectedPreviousEditOrgtype = data.Orgtype;
					this.selectedEditOrgtype = data.Orgtype;
					this.selectedEditStatus = data.Status;
					this.selectedStatus = data.Status;
					this.selectededitorgcodeId = data.Id;
					this.editOrgcodeForm.patchValue({
						status: this.selectedStatus
					  });
					this.editOrgcodemodel.open();
				},
				error => { }
			);
			
		}
	}

	private changeStatus(orgCodeId: string, status: number) {  // status pass to align with dependency common model
		const tempOrgcode = new orgcode(
			orgCodeId,
			this.editOrgcodeForm.value.editorgtype,
			this.sanitizer.sanitize(this.editOrgcodeForm.value.editorgcode),
			"",
			status
		);
		this.orgcodeService.updateOrgcode(tempOrgcode).subscribe(
			data => {
				// this.refreshTree();
				this.editOrgcodemodel.close();
				this.alertServices.clear();
				this.alertServices.success(data.message);
				this.refreshTree();
			},
			error => {
				this.editOrgcodemodel.close();
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	editNodeSave() {
		if (this.checkNeedToUpdateOrgcode()) {
			// const orgCodeChanged = this.selectedEditOrgcode !== this.selectedPreviousEditOrgcode;
			// const orgTypeChanged = this.selectedEditOrgtype !== this.editOrgcodeForm.value.editorgtype;
			// if (orgCodeChanged || orgTypeChanged) {
			// 	this.changeStatus(this.editOrgcodeForm.value.editorgcodeId, this.editOrgcodeForm.value.status);
			// }
			if (this.selectedStatus != this.editOrgcodeForm.value.status && this.selectedStatus == '1') {
				let itemType = "Org Code";
				this.customModalService.invoke(
					itemType,
					this.editOrgcodeForm.value.editorgcodeId,
					this,
					"statusChange", this.selectedStatus
				);
			}
			else {
				this.changeStatus(this.editOrgcodeForm.value.editorgcodeId, this.editOrgcodeForm.value.status);
			}
		} else {
			//no need to update the user no changes
			this.alertServices.clear();
			this.alertServices.error("There are no changes to update.");
		}
	}

	checkNeedToUpdateOrgcode(): boolean {
		if (this.selectedEditOrgcode != this.selectedPreviousEditOrgcode) {
			return true;
		} else if (
			this.selectedEditOrgtype != this.editOrgcodeForm.value.editorgtype
		) {
			return true;
		} else if (this.selectedStatus != this.editOrgcodeForm.value.status) {
			return true;
		} else {
			return false;
		}
	}

	deleteNode() {
		var id = $("#tree").jstree("get_selected");
		if (id == "") {
			this.alertServices.clear();
			this.alertServices.error("Please select org code for delete.");
		} else {
			let itemType = "Org Code";
			this.customModalService.invoke(
				itemType,
				id,
				this,
				"delete", null
			);
		}
	}

	private delete() {
		var pId = $("#tree").jstree("get_selected");
		const tempOrgCode = new orgcode(pId, "", "", "");
		this.orgcodeService.deleteOrgcode(tempOrgCode).subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				$("#tree").jstree("delete_node", pId);
				this.refreshTree();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
		);
	}

	private refreshTree() {
		var Id = $("#tree").jstree("get_selected");
		this.orgcodeService.getOrgcode().subscribe(
			data => {
				this.data = [];
				for (let i = 0; i < data.orgCode.length; i++) {
					if (data.orgCode[i].status == 1) {
						this.data.push({
							id: data.orgCode[i].id,
							parent: data.orgCode[i].parent,
							text: data.orgCode[i].text,
							li_attr: { title: data.orgCode[i].orgtype },
							icon: "fa fa-folder-o"
						});
					} else {
						this.data.push({
							id: data.orgCode[i].id,
							parent: data.orgCode[i].parent,
							text: data.orgCode[i].text,
							li_attr: { title: data.orgCode[i].orgtype },
							icon: "fa fa-times"
						});
					}
				}

				$("#tree").jstree(true).settings.core.data = this.data;
				$("#tree")
					.jstree(true)
					.refresh();
			},
			error => { }
		);
	}

	public close() {
		this.editOrgcodemodel.close();
	}
}
