import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedService } from '../home/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { UserService } from '../user/user.service';

import { discipline } from './discipline';
import { DisciplineService } from './discipline.service';
import { NoWhitespaceDirective } from "../util/validator/nowhitespace.directive"
import { SanitizationService } from '../sanitizer.service';
@Component({
	selector: 'app-discipline',
	templateUrl: './adddiscipline.component.html'
})

export class AddDisciplineComponent implements OnInit {

	addDisciplineForm: FormGroup;

	constructor(private sharedService: SharedService, private disciplineService: DisciplineService, private alertServices: AlertService, private userService: UserService,
			private sanitizer: SanitizationService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.addDisciplineForm = new FormGroup({
			discipline: new FormControl(null, Validators.required)
		});
	}

	//submit function of add discipline form
	// onSubmit() {
	saveAndNew() {
		const tempDiscipline = new discipline('', this.sanitizer.sanitize(this.addDisciplineForm.value.discipline), 1);
		this.disciplineService.addDiscipline(tempDiscipline)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				// this.addDisciplineForm.reset();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	save() {
		const tempDiscipline = new discipline('', this.sanitizer.sanitize(this.addDisciplineForm.value.discipline), 1);
		this.disciplineService.addDiscipline(tempDiscipline)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.sharedService.change('Discipline');
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	cancel() {
		this.sharedService.change('Discipline');
	}
}