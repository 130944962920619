import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NAReasonService } from './nareason.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { NAReason } from './nareason.model';
import { UserService } from '../../user/user.service';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-addnareason',
	templateUrl: './addnareason.component.html'
})

export class AddNAReasonComponent implements OnInit {

	addNewReasonForm: FormGroup;

	public autocompleteItemsAsObjects = [];
	public selectedTags = [];

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private naReasonService: NAReasonService,
		private userService: UserService,
		private sanitizer: SanitizationService
	) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.addNewReasonForm = new FormGroup({
			reason: new FormControl(null, Validators.required)
		});
	}

	//navigate to other component
	cancel() {
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		if (selectedMenuItem == 'AddJobProfile') {
			this.sharedService.hideComponent('AddJobProfile');
		} else {
			this.sharedService.change('AllNAReason');
		}
	}

	saveAndNew() {
		let reason: string = this.sanitizer.sanitize(this.addNewReasonForm.value.reason);
		const selectedMenuItem = localStorage.getItem('selectedMenuItem');
		this.addNAReason(reason)
	}

	save() {
		let reason: string = this.sanitizer.sanitize(this.addNewReasonForm.value.reason);
		let tempReason = new NAReason('', reason);
		this.naReasonService.addNAReason(tempReason)
			.subscribe(
			data => {
				// console.log('[addJPNote] (data) ' + JSON.stringify(data).toString())
				this.alertService.clear();
				this.alertService.success(data.message);
				this.cancel();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	//add note to DB (not job profile)
	private addNAReason(reasonText: string) {
		let tempReason = new NAReason('', reasonText);
		this.naReasonService.addNAReason(tempReason)
			.subscribe(
			data => {
				// console.log('[addJPNote] (data) ' + JSON.stringify(data).toString())
				this.alertService.clear();
				this.alertService.success(data.message);
				// this.addNewNoteForm.reset();
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}
}
