import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { JobProfile } from '../../jobprofile/jobprofile.model';
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model';
import { Task } from '../../complib/task/task.model';
import { Knowledge } from '../../complib/knowledge/knowledge.model';
import { Performance } from '../../complib/performance/performance.model';
import { CheckList } from '../../complib/checklist/checklist.model';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { RemediationService } from '../../complib/remediation/remediation.service';
import { UserService } from '../../user/user.service';
import { OrderArrayByType } from '../../util/orderarraybytype.pipe';
import { Constants } from '../../util/const/constants';
import { AssessmentService } from '../assessment.service';
import { Opportunity } from '../performassessment/opportunity.model';
import { AssessmentModel } from '../assessment.model';
import { AssessmentDocument } from '../performassessment/assessmentdocument.model';
import * as FileSaver from "file-saver";
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment';
import { RegulatoryAgencyService } from '../../complib/regulatoryagency/regulatoryagency.service';
import { ReportService } from "../../report/report.service";
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: "app-candidate-assessment",
	templateUrl: "./candidateassessment.component.html",
	styleUrls: ["./candidateassessment.component.scss"]
})
export class CandidateAssessmentComponent implements OnInit {
	//check capablity to add new items
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public assessmentModel: AssessmentModel = null;
	public candidateStrengthsForm: FormGroup;
	//this variable use to show/hide data panelpanel
	public showCCDetail: string = '';
	public showTaskDetail: string = '';
	public showTaskGroupDetail: string = ''
	public showPerformanceDetail: string = '';
	public showKnowledgeDetail: string = '';
	public showCheckListDetail: string = '';
	public showTaskRemediationDetail: string = '';
	public showPerformanceRemediationDetail: string = '';
	public showCheckListRemediationDetail: string = '';
	public showKnowledgeRemediationDetail: string = '';
	public showDocumentDetail: string = '';
	public showingDetatilPanel: string = '';
	public assessmentId: any = '';
	public jobProfileId: any = '';
	public remediationAttachmentList: any = [];
	public selectedTaskRemediationAttachment: any = [];
	public selectedPerformanceRemediationAttachment: any = [];
	public selectedChecklistRemediationAttachment: any = [];
	public selectedKnowledgeRemediationAttachment: any = [];
	public showCandidateStrengthPanel: boolean = false
	public userId: any = null;
	public attachments: any;
	public showofdPanel: boolean = false;
	public opportunityForm: FormGroup;
	public userName: any = '';
	public isLoading: boolean = false;
	public showGenerate: boolean = false
	public type: string = null
	public taskTotalList: Array<any> = [];
	public taskNATotalList: Array<any> = [];
	public visualImagesPath: string = environment.server_URL + '/images/knowledge/'
	public expandAllTasks: boolean = false
	public allClicked: boolean = true
	public regAgencyList: Array<any> = [];
	public regShortList: Array<any> = []
	selectedItems = [];
	data: any = null
	noResults: boolean = false
	placeholderText: any = ''
	total: any = ''
	correct: any = ''

	constructor(
		private remediationService: RemediationService,
		private userService: UserService,
		private sharedService: SharedService,
		private alertService: AlertService,
		private assessmentService: AssessmentService,
		private router: Router,
		private regulatoryAgencyService: RegulatoryAgencyService,
		private pipe: OrderArrayByType,
		private reportService: ReportService,
		private sanitizer: SanitizationService
	) {

		this.assessmentModel = assessmentService.getSelectedAssessmentModel();
		this.showGenerate = assessmentService.getGenerate()
		this.type = assessmentService.getType()
		if (!this.type) {
			if (!userService.isValidLoggedIn()) {
				userService.redirectToLoginScreen();
			}
		} else {
			if (!userService.alreadyLoggedIn()) {
				this.router.navigateByUrl('/user/login?type=' + this.type + '&assessmentId=' + this.assessmentModel.id);
			}
		}
		this.assessmentModel.jobProfile = new JobProfile(this.assessmentModel.jobProfileId);
		if (localStorage.getItem('selectedAsseseeName') != null) {
			this.userName = this.assessmentModel.userName != null ? this.assessmentModel.userName : localStorage.getItem('selectedAsseseeName');
			this.userId = this.assessmentModel.userId != null ? this.assessmentModel.userId : localStorage.getItem('selectedAsseseeUserId');
		} else {
			this.userName = localStorage.getItem('userName');
			this.userId = localStorage.getItem('userId');
		}

		this.createJobProfileHierarchy();
		this.loadAllRegulatoryAgencies()
	}

	ngOnInit() {

		this.candidateStrengthsForm = new FormGroup({
			candidateStrengths: new FormControl({ value: null, disabled: true })
		});

		this.opportunityForm = new FormGroup({
			category: new FormControl(null),
			opportunityText: new FormControl(null)
		});

	}

	private showCandidateStrength() {
		if (this.showofdPanel) {
			this.showofdPanel = !this.showofdPanel;
		}
		this.showCandidateStrengthPanel = !this.showCandidateStrengthPanel;
		if (this.showCandidateStrengthPanel) {
		}
	}

	private showHideOpportunityPanel() {
		if (this.showCandidateStrengthPanel) {
			this.showCandidateStrengthPanel = !this.showCandidateStrengthPanel;
		}
		this.showofdPanel = !this.showofdPanel;
	}

	/** get assesssment opportunity detail */
	private getOppportunityForAssessment(assessmentId: any) {
		this.assessmentService.getOpportunityForAssessment(assessmentId)
			.subscribe(
			data => {
				if (data.opportunityList.length != 0) {
					this.assessmentModel.opportunityList = this.generateOpportunityListModel(data.opportunityList);
				}
				else {
					this.assessmentModel.opportunityList = [];
				}
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
			)
	}


	private generateOpportunityListModel(opportunityList: any) {
		let opportunityModelList = [];

		for (let i = 0; i < opportunityList.length; i++) {
			let opportunityModel: Opportunity = new Opportunity(
				opportunityList[i].category,
				opportunityList[i].CategoryNo,
				opportunityList[i].OpportunityValue,
				opportunityList[i].AssessmentId,
				opportunityList[i].OrderNo
			);
			opportunityModelList.push(opportunityModel)

		}
		return opportunityModelList;
	}


	private createJobProfileHierarchy() {
		let param: any = {
			'assessmentId': this.assessmentModel.id,
			'includeSelfAssessment': true
		}
		console.log(this.assessmentModel.id)
		this.isLoading = true
		this.assessmentService.getAssessmentJobProfileHierarchy(param).subscribe(
			data => {
				this.alertService.clear();
				this.data = data
				this.assessmentModel.jobProfile.coreCompetencyList = this.generateCCListFromData(data)
				// console.log('[createJobProfileHierarchy] (coreCompetencyList) ' + JSON.stringify(this.assessmentModel.jobProfile.coreCompetencyList))	
				this.assessmentModel.assessmentScore = this.assessmentModel.assessmentScore == null ? 0 : + this.assessmentModel.assessmentScore;
				this.validateCCList(this.assessmentModel.jobProfile.coreCompetencyList);
				this.getOppportunityForAssessment(this.assessmentModel.id);
				this.candidateStrengthsForm.get('candidateStrengths').setValue(this.assessmentModel.candidateStrengths);
				this.assessmentService.getSelfAssessmentScore(this.assessmentModel.id)
					.subscribe(
					data => {
						this.isLoading = false
						this.total = data.total;
						this.correct = data.correct
					},
					error => {
						this.isLoading = false
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
					)
			},
			error => {
				this.isLoading = false
				this.assessmentModel.jobProfile.coreCompetencyList = [];
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	cancelCandidateStrengths() {
		this.showCandidateStrengthPanel = false;
	}

	//generate assessment corecompetency list from data
	private generateCCListFromData(data: any): any {
		let corecompetencyList = [];

		for (let i = 0; i < data.result.length; i++) {
			let cci = data.result[i].ccItem
			let tl = data.result[i].taskList;
			let rel = data.result[i].regulatoryAgencyList;
			let pl = data.result[i].performanceList;
			let kl = data.result[i].knowledgeList;
			let dl = data.result[i].documentList;
			let cll = data.result[i].checkList;
			let trl = data.result[i].taskRemediationList;
			let prl = data.result[i].performanceRemediationList;
			let krl = data.result[i].knowledgeRemediationList;
			let clrl = data.result[i].checkListRemediationList;
			let kal = data.result[i].knowledgeAnswerList;

			let clml = this.generateCheckListModel(cll, clrl);
			let kml = this.generateKnowledgeListModel(kl, krl, kal);
			let pml = this.generatePerformanceListModel(pl, clml, prl)
			let dml = this.generateDocumentListModel(dl);
			let tml = this.generateTaskListModel(tl, pml, kml, trl, dml, rel);

			if (tml.length > 0) {
				let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
				ccItem.orderNum = cci.orderNum
				ccItem.assStatus = cci.status
				ccItem.assId = cci.ACCId
				ccItem.taskTotal = this.generateTotalTaskList(tml)
				ccItem.nATask = this.generateTotalNaTaskList(tml)
				ccItem.ccLevelTaskTotal = this.generateCCLevelTotaltask(tml)
				ccItem.assessmentScore = cci.assessmentScore == null ? 0 : +cci.assessmentScore.toFixed(0);
				ccItem.taskList = this.pipe.transform(ccItem.taskList, 'orderNum')
				corecompetencyList.push(ccItem)
			}
		}
		corecompetencyList = this.pipe.transform(corecompetencyList, 'orderNum')
		return corecompetencyList;
	}


	private generateDocumentListModel(documentList: any) {
		let documentModelList = [];
		for (let i = 0; i < documentList.length; i++) {
			let temp = documentList[i].FileName;
			let fileName = temp.substring(0, temp.lastIndexOf("."));
			let ext = temp.substring(temp.lastIndexOf(".") + 1);
			let docModel: AssessmentDocument = new AssessmentDocument(documentList[i].Id, documentList[i].assId, documentList[i].AssessmentTaskId,
				documentList[i].Category, fileName, documentList[i].Note);
			docModel.ext = ext;
			documentModelList.push(docModel);
		}
		return documentModelList;
	}


	private generateCheckListModel(checkList: any, clrList: any): any {
		let clModelList = []
		for (let i = 0; i < checkList.length; i++) {
			let clModel: CheckList = new CheckList(checkList[i].id, checkList[i].text, '', true, checkList[i].orderNum);
			clModel.remediationList = clrList.filter(item => item.ACLId === checkList[i].ACLId);
			clModel.jppId = checkList[i].APId;
			clModel.answer = checkList[i].answer;
			clModel.assId = checkList[i].ACLId;
			clModel.reasonNA = checkList[i].reasonNA;
			if (!clModel.reasonNA) {
				clModel.reasonNA = '';
			}
			clModelList.push(clModel);
		}

		return clModelList;
	}

	private generateKnowledgeListModel(knowledgeList: any, knoRemList: any, knowledgeAnswerList: any): any {
		let knowledgeModelList = []
		for (let i = 0; i < knowledgeList.length; i++) {
			let kModel: Knowledge = new Knowledge(knowledgeList[i].text, 0, knowledgeList[i].id);
			kModel.remediationList = knoRemList.filter(item => item.AKId === knowledgeList[i].AKId);
			kModel.answerList = knowledgeAnswerList.filter(item => item.AKId === knowledgeList[i].AKId)
			kModel.jptId = knowledgeList[i].ATId;
			kModel.orderNum = knowledgeList[i].orderNum;
			kModel.answer = knowledgeList[i].answer;
			kModel.assId = knowledgeList[i].AKId;
			kModel.type = knowledgeList[i].Type;
			kModel.assessmentNote = knowledgeList[i].note;
			kModel.isTestQuestion = knowledgeList[i].IsTestQuestion;
			kModel.isVisualQuestion = knowledgeList[i].IsVisualQuestion;
			kModel.booleanAnswerCorrect = knowledgeList[i].IsCorrect;
			kModel.fileType = knowledgeList[i].FileType
			kModel.isImageAttached = knowledgeList[i].ImageAttached
			if (!kModel.assessmentNote) {
				kModel.assessmentNote = '';
			}
			kModel.reasonNA = knowledgeList[i].reasonNA;
			if (!kModel.reasonNA) {
				kModel.reasonNA = '';
			}
			knowledgeModelList.push(kModel)
		}
		return knowledgeModelList;
	}

	private generatePerformanceListModel(perList: any, checkList: any, remList: any) {
		let performanceModelList = []
		for (let i = 0; i < perList.length; i++) {
			let pModel: Performance = new Performance(perList[i].id, perList[i].text, true)
			pModel.checkList = checkList.filter(item => item.jppId === perList[i].APId);
			pModel.remediationList = remList.filter(item => item.APId === perList[i].APId);
			pModel.jptId = perList[i].ATId
			pModel.orderNum = perList[i].orderNum
			pModel.answer = perList[i].answer
			pModel.assId = perList[i].APId
			pModel.assessmentNote = perList[i].note
			if (!pModel.assessmentNote) {
				pModel.assessmentNote = '';
			}
			pModel.reasonNA = perList[i].reasonNA
			if (!pModel.reasonNA) {
				pModel.reasonNA = '';
			}
			pModel.checkList = this.pipe.transform(pModel.checkList, 'orderNum')
			performanceModelList.push(pModel)
		}
		return performanceModelList;
	}

	private isValidRegAgencyConnectedTask(regList: any): boolean {
		let found: boolean = true
		if (this.selectedItems.length > 0) {
			if (regList.length > 0) {
				this.selectedItems.forEach(element => {
					if (regList.filter(item => {
						if (item.RegulatoryAgencyId == element.id) {
							return true
						} else {
							return false
						}
					}).length == 0) {
						found = false
					}
				});
			} else {
				found = false
			}
		}
		return found
	}

	private generateTaskListModel(taskList: any, perList: any, knoList: any, remList: any, docList: any, regList: any) {
		let taskModelList = [];
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task
			if (taskList[i].isTaskGroup) {
				taskModel = new Task(taskList[i].ATId, taskList[i].text);
				taskModel.regulatoryList = [];
				taskModel.performanceList = [];
				taskModel.knowledgeList = [];
				taskModel.knowledgeQuestionList = []
				taskModel.remediationList = [];
				taskModel.documentList = []
				taskModel.taskList = []
			} else {
				taskModel = new Task(taskList[i].id, taskList[i].text);
				taskModel.regulatoryList = regList.filter(item => item.ATId === taskList[i].ATId)
				taskModel.performanceList = perList.filter(item => item.jptId === taskList[i].ATId);
				taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].ATId).filter(item => item.isTestQuestion == 0);
				taskModel.knowledgeQuestionList = knoList.filter(item => item.jptId === taskList[i].ATId).filter(item => item.isTestQuestion == 1);
				taskModel.remediationList = remList.filter(item => item.ATId === taskList[i].ATId);
				taskModel.documentList = docList.filter(item => item.assId === taskList[i].ATId);
				taskModel.assStatus = taskList[i].status;
				taskModel.criticalityValue = taskList[i].criticality;
				taskModel.assessmentScore = taskList[i].assessmentScore == null ? 0 : +taskList[i].assessmentScore.toFixed(0);
				taskModel.performanceList = this.pipe.transform(taskModel.performanceList, 'orderNum')
				taskModel.knowledgeList = this.pipe.transform(taskModel.knowledgeList, 'orderNum')
				taskModel.knowledgeQuestionList = this.pipe.transform(taskModel.knowledgeQuestionList, 'orderNum')
				taskModel.regulatoryList = this.pipe.transform(taskModel.regulatoryList, 'alphabetic')
			}
			taskModel.jpccId = taskList[i].ACCId;
			taskModel.orderNum = taskList[i].orderNum;
			taskModel.assId = taskList[i].ATId;
			taskModel.tgId = taskList[i].TGId
			taskModel.isTaskGroup = taskList[i].isTaskGroup
			taskModel.taskGroup = taskList[i].tgText
			taskModel.taskGroupId = taskList[i].taskGroupId
			taskModel.isYes = taskList[i].isYes
			taskModel.isNA = taskList[i].isNA
			taskModel.reasonNA = taskList[i].reasonNA
			if (taskModel.isTaskGroup) {
				taskModelList.push(taskModel);
			} else {
				if (this.isValidRegAgencyConnectedTask(taskModel.regulatoryList)) {
					taskModelList.push(taskModel);
				}
			}
		}
		taskModelList = this.modifyTaskList(taskModelList)
		return taskModelList;
	}

	private generateTotalTaskList(taskList: any): any {
		this.taskTotalList = []
		taskList.forEach(task => {
			if (!task.isTaskGroup) {
				this.taskTotalList.push(task)
			} else {
				task.taskList.forEach(tgTask => {
					this.taskTotalList.push(tgTask)
				});
			}
		});
		return this.taskTotalList.length
	}

	private generateTotalNaTaskList(taskList: any): any {
		this.taskNATotalList = []
		taskList.forEach(task => {
			if (!task.isTaskGroup) {
				if (task.isNA) {
					this.taskNATotalList.push(task)
				} else {
					//nothing
				}

			} else {
				task.taskList.forEach(tgTask => {
					if (tgTask.isNA) {
						this.taskNATotalList.push(tgTask)
					}
				});
			}
		});
		return this.taskNATotalList.length
	}

	public generateCCLevelTotaltask(taskList: any): any {
		let tempTaskList = []
		taskList.forEach(task => {
			tempTaskList.push(task.id)
		});
		return tempTaskList.length
	}

	public modifyTaskList(taskList: any): any {
		let returnTaskList = []
		let tempTaskList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {
				returnTaskList.push(task)
			} else {
				if (task.taskGroupId != null) {
					tempTaskList.push(task)
				} else {
					returnTaskList.push(task)
				}
			}
		});
		// console.log('[modifyTaskList] (returnTaskList) : ' + JSON.stringify(returnTaskList));
		// console.log('[modifyTaskList] (tempTaskList) : ' + JSON.stringify(tempTaskList));
		tempTaskList.forEach(task => {
			if (task.taskGroupId != null && task.taskGroupId != 'null') {
				let tg = returnTaskList.find(item => item.assId === task.taskGroupId);
				tg.taskList.push(task)
			} else {
				returnTaskList.push(task)
			}
		});
		let finalTaskList = []
		returnTaskList.forEach(task => {
			if (task.isTaskGroup) {
				if (task.taskList.length != 0) {
					task.taskList = this.pipe.transform(task.taskList, 'orderNum')
					finalTaskList.push(task)
				}
			} else {
				finalTaskList.push(task)
			}
		})
		// console.log('[modifyTaskList] (returnTaskList) : ' + JSON.stringify(returnTaskList));

		return finalTaskList

	}

	/**
	 * [validate cc item for 0-knowledge and 0-performance]
	 * this is not practical because user can not create 0-k 0-p cc item
	 * @param  {any}    ccList [description]
	 * @return {[type]}        [description]
	 */
	private validateCCList(ccList: any) {
		ccList.forEach(ccItem => {
			if (ccItem.taskList.length != 0) {
				ccItem.taskList.forEach(task => {
					if (task.performanceList.length != 0) {
						//do nothing
					} else {
						if (task.knowledgeList.length != 0) {
							//do nothing
						} else {
							task.assStatus = 3
						}
					}
				});
			} else {
				ccItem.assStatus = 3
			}
		});

		ccList.forEach(ccItem => {
			let completedTaskCount: number = 0;
			ccItem.taskList.forEach(task => {
				if (task.assStatus == 3) {
					completedTaskCount++
				}
			});
			if (completedTaskCount == ccItem.taskList.length) {
				ccItem.assStatus = 3
			}
		});
	}

	private getFileType(fileExtension: string) {
		var mediatype;
		if (fileExtension == 'pdf') {
			mediatype = 'application/pdf';
		} else if (fileExtension == 'docx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		} else if (fileExtension == 'doc') {
			mediatype = 'application/msword';
		} else if (fileExtension == 'ppt') {
			mediatype = 'application/vnd.ms-powerpoint';
		} else if (fileExtension == 'pptx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		} else if (fileExtension == 'xls') {
			mediatype = 'application/vnd.ms-excel';
		} else if (fileExtension == 'xlsx') {
			mediatype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		} else if (fileExtension == "png" || fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "bmp" || fileExtension == "tiff" || fileExtension == "gif") {
			mediatype = "image/" + fileExtension;
		}
		return mediatype;
	}

	//show and hide collapse panel (button click event)
	public showCollapsePanel(itemId: any, itemType: string, remediationId: any) {
		this.showingDetatilPanel = itemType
		switch (itemType) {
			case 'coreCompetency':
				this.showCCDetail = this.changeVarableToShowHideItem(this.showCCDetail, itemId);
				this.showTaskDetail = '';
				this.showPerformanceDetail = '';
				this.showKnowledgeDetail = '';
				this.showDocumentDetail = '';
				this.showCheckListDetail = '';
				this.showTaskRemediationDetail = '';
				this.showPerformanceRemediationDetail = '';
				this.showCheckListRemediationDetail = '';
				this.showKnowledgeRemediationDetail = '';
				break;
			case 'task':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
				this.showTaskGroupDetail = ''
				this.showPerformanceDetail = '';
				this.showKnowledgeDetail = '';
				this.showDocumentDetail = '';
				this.showCheckListDetail = '';
				this.showTaskRemediationDetail = '';
				this.showPerformanceRemediationDetail = '';
				this.showCheckListRemediationDetail = '';
				this.showKnowledgeRemediationDetail = '';
				break;
			case 'taskGroup':
				this.showTaskDetail = ''
				this.showTaskGroupDetail = this.changeVarableToShowHideItem(this.showTaskGroupDetail, itemId);
				this.showPerformanceDetail = '';
				this.showKnowledgeDetail = '';
				this.showDocumentDetail = '';
				this.showCheckListDetail = '';
				this.showTaskRemediationDetail = '';
				this.showPerformanceRemediationDetail = '';
				this.showCheckListRemediationDetail = '';
				this.showKnowledgeRemediationDetail = '';
				break;
			case 'taskGroupTask':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
				this.showPerformanceDetail = '';
				this.showKnowledgeDetail = '';
				this.showDocumentDetail = '';
				this.showCheckListDetail = '';
				this.showTaskRemediationDetail = '';
				this.showPerformanceRemediationDetail = '';
				this.showCheckListRemediationDetail = '';
				this.showKnowledgeRemediationDetail = '';
				break
			case 'performance':
				this.showPerformanceDetail = this.changeVarableToShowHideItem(this.showPerformanceDetail, itemId);
				this.showCheckListDetail = ''
				this.showPerformanceRemediationDetail = '';
				this.showCheckListRemediationDetail = '';
				break;
			case 'knowledgeRemediation':
				this.showKnowledgeDetail = this.changeVarableToShowHideItem(this.showKnowledgeDetail, itemId)
				this.showKnowledgeRemediationDetail = '';
				break;
			case 'document':
				this.showDocumentDetail = this.changeVarableToShowHideItem(this.showDocumentDetail, itemId)
				break;
			case 'checkListRemediation':
				this.showCheckListDetail = this.changeVarableToShowHideItem(this.showCheckListDetail, itemId)
				console.log('checklist == ' + this.showCheckListDetail
				)
				this.showCheckListRemediationDetail = '';
				break;
			case 'taskRemediationDetail':
				this.selectedTaskRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedTaskRemediationAttachment == null || this.selectedTaskRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedTaskRemediationAttachment);
				}
				else if (this.selectedTaskRemediationAttachment.length == 1 && this.selectedTaskRemediationAttachment[0].id == '') {
					this.selectedTaskRemediationAttachment = [];
				}
				this.showTaskRemediationDetail = this.changeVarableToShowHideItem(this.showTaskRemediationDetail, itemId)
				break;
			case 'performanceRemediationDetail':
				this.selectedPerformanceRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedPerformanceRemediationAttachment == null || this.selectedPerformanceRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedPerformanceRemediationAttachment);
				}
				else if (this.selectedPerformanceRemediationAttachment.length == 1 && this.selectedPerformanceRemediationAttachment[0].id == '') {
					this.selectedPerformanceRemediationAttachment = [];
				}
				this.showPerformanceRemediationDetail = this.changeVarableToShowHideItem(this.showPerformanceRemediationDetail, itemId)
				break;
			case 'knowledgeRemediationDetail':
				this.selectedKnowledgeRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedKnowledgeRemediationAttachment == null || this.selectedKnowledgeRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedKnowledgeRemediationAttachment);
				}
				else if (this.selectedKnowledgeRemediationAttachment.length == 1 && this.selectedKnowledgeRemediationAttachment[0].id == '') {
					this.selectedKnowledgeRemediationAttachment = [];
				}
				this.showKnowledgeRemediationDetail = this.changeVarableToShowHideItem(this.showKnowledgeRemediationDetail, itemId)
				break;
			case 'checklistRemediationDetail':
				this.selectedChecklistRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedChecklistRemediationAttachment == null || this.selectedChecklistRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedChecklistRemediationAttachment);
				}
				else if (this.selectedChecklistRemediationAttachment.length == 1 && this.selectedChecklistRemediationAttachment[0].id == '') {
					this.selectedChecklistRemediationAttachment = [];
				}
				this.showCheckListRemediationDetail = this.changeVarableToShowHideItem(this.showCheckListRemediationDetail, itemId)
				break;
			default:
				break;
		}
	}

	private getRemediationAttachmentsByRemediationId(remediationId: any, attachments: any) {
		this.remediationService.getRemediationAttachmentsByRemediationId(remediationId).subscribe(
			data => {
				if (data.remediationAttachmentList.length > 0) {
					for (var i = 0; i < data.remediationAttachmentList.length; i++) {
						var fileExtension = data.remediationAttachmentList[i].FileName.substr((data.remediationAttachmentList[i].FileName.lastIndexOf('.') + 1));
						attachments.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
						this.remediationAttachmentList.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
					}
				}
				else {
					this.remediationAttachmentList.push({ remediationId: remediationId, id: '', text: '', fileName: '' });
				}

			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	public fileIconButtonClickEvent(fileName, attachmentId, ext): any {
		var fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
		var mediaType = this.getFileType(fileExtension);
		this.isLoading = true
		this.remediationService.getFileContent(fileName, attachmentId, mediaType)
			.subscribe(fileData => {
				this.isLoading = false
				FileSaver.saveAs(fileData, fileName);
			});

	}

	public docFileIconButtonClickEvent(fileName, attachmentId, ext): any {
		var mediaType = this.getFileType(ext);
		this.isLoading = true
		this.assessmentService.getDocumentContent(attachmentId, ext, mediaType)
			.subscribe(fileData => {
				this.isLoading = false
				FileSaver.saveAs(fileData, fileName + "." + ext);
			});
	}


	private changeVarableToShowHideItem(val: string, text: string): string {
		if (val != text) {
			val = text;
		} else {
			val = '';
		}

		return val;
	}

	download() {
		this.isLoading = true;
		this.assessmentService.generateCompetencyAssessment(this.assessmentModel.id).subscribe(
			data => {
				this.assessmentService.getCompetencyAssessmentContent(this.assessmentModel.id)
					.subscribe(fileData => {
						this.isLoading = false;
						FileSaver.saveAs(fileData, "CompetencyAssessment_" + this.userName + "_" + this.assessmentModel.assessmentCompletedDate.toLocaleDateString() + ".pdf");
					});
			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
		);

	}

	directToSelfAssessmentReview(row: any) {
		let assessmentModel: AssessmentModel = new AssessmentModel(this.assessmentModel.id);
		assessmentModel.selfAssessmentCompletedDate = this.assessmentModel.selfAssessmentCompletedDate;
		assessmentModel.userName = this.userName
		assessmentModel.firstName = this.assessmentModel.firstName
		assessmentModel.lastName = this.assessmentModel.lastName
		assessmentModel.jobProfileDesc = this.assessmentModel.jobProfileDesc;
		this.assessmentService.setSelectedAssessmentModel(assessmentModel)
		this.sharedService.change('SelfAssessmentReview')
	}

	directToRemediationReport() {
		this.assessmentModel.userName = this.userName
		this.assessmentService.setSelectedUserId(this.userId)
		this.assessmentService.setSelectedAssessmentModel(this.assessmentModel)
		this.assessmentService.setSelectedItem('72')
		this.sharedService.change('RemediationReport')
	}

	public getImageURL(knowledgeId: any, answerId: any, fileType: any) {
		return this.visualImagesPath + "/" + knowledgeId + "/" + answerId + "." + fileType
	}

	clear() {
		this.showCCDetail = '';
		this.showTaskDetail = '';
		this.showTaskGroupDetail = ''
		this.showPerformanceDetail = '';
		this.showKnowledgeDetail = '';
		this.showCheckListDetail = '';
		this.showTaskRemediationDetail = '';
		this.showPerformanceRemediationDetail = '';
		this.showCheckListRemediationDetail = '';
		this.showKnowledgeRemediationDetail = '';
		this.showDocumentDetail = '';
		this.showingDetatilPanel = '';
		this.showofdPanel = false
		this.showCandidateStrengthPanel = false
	}

	expandAllTasksInAssessment() {
		this.clear()
		this.expandAllTasks = !this.expandAllTasks
	}

	showAll() {
		this.allClicked = true
		this.isLoading = true
		this.clear()
		this.selectedItems = []
		this.showCCHierarchy()
		this.isLoading = false
	}

	showRegulatoryAgency() {
		this.allClicked = false
		this.clear()
		this.selectedItems = []
	}

	loadAllRegulatoryAgencies() {
		this.regulatoryAgencyService.getAllRegulatoryAgency()
			.subscribe(
			data => {
				this.regAgencyList = [];
				this.regShortList = []
				for (var i = 0; i < data.regulatoryAgencyList.length; i++) {
					this.regAgencyList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].Description + " - " + data.regulatoryAgencyList[i].ShortName });
					this.regShortList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].ShortName })
				}
				if (this.regAgencyList.length > 0) {
					this.placeholderText = 'Select regulatory agency'
				} else {
					this.placeholderText = 'No items available'
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});

	}

	public filterTasks(items: any) {
		this.isLoading = true
		this.selectedItems = items
		this.showCCHierarchy()
		this.isLoading = false
	}

	public showCCHierarchy() {
		this.assessmentModel.jobProfile.coreCompetencyList = []
		this.assessmentModel.jobProfile.coreCompetencyList = this.generateCCListFromData(this.data);
		this.assessmentModel.jobProfile.coreCompetencyList = this.pipe.transform(this.assessmentModel.jobProfile.coreCompetencyList, 'orderNum')

		if (this.assessmentModel.jobProfile.coreCompetencyList.length == 0) {
			this.noResults = true
		} else {
			this.noResults = false
		}
	}

	print() {
		this.isLoading = true
		let popupWindow;
		let content = this.loadHtmlData()
		popupWindow = window.open(
			"",
			"_blank",
			"width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
		);
		popupWindow.document.open();
		popupWindow.document.write(
			'<html><head><link rel="stylesheet" type="text/css" href="style.css" />' +
			'</head><body onload="window.print()">' +
			content +
			"</html>"
		);
		popupWindow.document.close();
		this.isLoading = false
	}

	printTaskContent(items: any, itemCategory: any, paddingLeft: any) {
		var reportHTML = ''

		for (var k = 0; k < items.length; k++) {
			var item = items[k]
			var colorCode = "#c0c0c0"
			if (item.answer == 3) {
				colorCode = "#5cb85c"
			} else if (item.answer == 2) {
				colorCode = "#d9534f"
			}
			reportHTML += '<div style="padding:10px;margin-left:' + paddingLeft + 'px;border-style:solid;border-color:' + colorCode + ';border-width:1px;border-radius:0.25rem;" >&nbsp;&nbsp;'

			reportHTML += (k + 1) + '.&nbsp;'

			reportHTML += item[itemCategory]

			reportHTML += '</div>'

			// if (k == items.length - 1) {
			reportHTML += '<br>'
			// }

			if (itemCategory != 'knowledge') {
				if (item.checkList.length > 0) {
					reportHTML += '<div style="padding:0px 0px 0px 5px;margin-left:' + (paddingLeft + 20) + 'px;font-weight:bold;padding-bottom:10px;">Check List</div>'
				}
				//else if (k != items.length - 1) {
				//	reportHTML += '<br>'
				//}

				for (var m = 0; m < item.checkList.length; m++) {
					var checkList = item.checkList[m]
					var clColorCode = "#c0c0c0"
					if (checkList.answer == 3) {
						clColorCode = "#5cb85c"
					} else if (checkList.answer == 2) {
						clColorCode = "#d9534f"
					}

					reportHTML += '<div style="padding:10px;margin-left:' + (paddingLeft + 40) + 'px;border-style:solid;border-color:' + clColorCode + ';border-width:1px;border-radius:0.25rem;" >&nbsp;&nbsp;'

					reportHTML += (m + 1) + '.&nbsp;'

					reportHTML += checkList.text

					reportHTML += '</div>'
					//if ((m != item.checkList.length - 1) || (k != items.length - 1 && m == item.checkList.length - 1)) {
					reportHTML += '<br>'
					//}
				}
			}

		}
		return reportHTML
	}

	printKnowledgeQuestions(knowledgeQuestionList: any, marginLeft: any) {
		var reportHTML = ''
		for (var k = 0; k < knowledgeQuestionList.length; k++) {
			var knowledge = knowledgeQuestionList[k]
			var colorCode = "#d9534f"
			if (knowledge.answer == 3) {
				colorCode = "#5cb85c"
			}
			reportHTML += '<div style="padding:5px;margin-left:' + marginLeft + 'px;border-style:solid;border-color:' + colorCode + ';border-width:1px;border-radius:0.25rem;" >&nbsp;&nbsp;'
			reportHTML += (k + 1) + '.&nbsp;'

			reportHTML += knowledge.knowledge
			if (knowledge.isImageAttached === 1) {
				var imageURL = this.getImageURL(knowledge.id, knowledge.id, knowledge.fileType)
				reportHTML += '<div style="margin-top: 0.5rem;margin-bottom: 0.5rem"><img src="' + imageURL + '" style="max-height: 250px;max-width: 80%;height: auto;margin-left: 0.25rem;"></div>'
			}

			if (knowledge.type == 'Boolean') {
				var contentTrue = ''
				if ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)) {
					contentTrue = 'checked'
				}
				var bgCode = ''
				if (knowledge.booleanAnswerCorrect == 1) {
					bgCode = "#d4edda"
				}
				if (knowledge.booleanAnswerCorrect == 0 && knowledge.answer === 2) {
					bgCode = "#f08080"
				}
				reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="padding:0.25rem;line-height:1.5;-webkit-print-color-adjust: exact !important;background-color:' + bgCode + ' !important;"><input type="radio" ' + contentTrue + '></span> True</div>'
				var contentFalse = ''
				if ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)) {
					contentFalse = 'checked'
				}
				var bgCodeFalse = ''
				if (knowledge.booleanAnswerCorrect == 0) {
					bgCodeFalse = "#d4edda"
				}
				if (knowledge.booleanAnswerCorrect == 1 && knowledge.answer === 2) {
					bgCodeFalse = "#f08080"
				}
				reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="padding:0.25rem;line-height:1.5;-webkit-print-color-adjust: exact !important;background-color:' + bgCodeFalse + ' !important;"><input type="radio" ' + contentFalse + '></span> False</div>'
			}
			for (var m = 0; m < knowledge.answerList.length; m++) {
				var knowledgeAnswer = knowledge.answerList[m]
				var ansCode = ''
				if (knowledgeAnswer.IsCorrect == 1) {
					ansCode = "#d4edda"
				} else if (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1) {
					ansCode = "#f08080"
				}
				var checked = ''
				if (knowledgeAnswer.GivenAnswer) {
					checked = "checked"
				}
				if (knowledge.type == 'Single') {
					reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="vertical-align:top;padding:0.25rem;line-height:1.5;height: 8mm;-webkit-print-color-adjust: exact !important;background-color:' + ansCode + ' !important;"><input style="vertical-align:top" type="radio" ' + checked + '></span> '
					if (knowledgeAnswer.text) {
						reportHTML += '<span class="m-0 mt-1 ml-2 float-right">' + knowledgeAnswer.text + '</span>'
					}
					if (knowledgeAnswer.FileType) {
						var imageURL = this.getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)
						reportHTML += '<img src="' + imageURL + '" style="max-height: 250px;max-width: 80%;height: auto;margin-left: 0.25rem;">'
					}
					reportHTML += '</div>'
				}
				if (knowledge.type == 'Multiple') {
					reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="vertical-align:top;padding:0.25rem;line-height:1.5;height: 8mm;-webkit-print-color-adjust: exact !important;background-color:' + ansCode + ' !important;"><input style="vertical-align:top" type="checkbox" ' + checked + '></span> '
					if (knowledgeAnswer.text) {
						reportHTML += '<span class="m-0 mt-1 ml-2 float-right">' + knowledgeAnswer.text + '</span>'
					}
					if (knowledgeAnswer.FileType) {
						var imageURL = this.getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)
						reportHTML += '<img src="' + imageURL + '" style="max-height: 250px;max-width: 80%;height: auto;margin-left: 0.25rem;">'
					}
					reportHTML += '</div>'
				}
			}
			reportHTML += '</div><br>'

		}
		return reportHTML
	}

	getStyle(score: any) {
		if (score < 50) {
			return '#dc3545'
		} else if (score >= 50 && score < 80) {
			return '#ffc107'
		} else {
			return '#28a745'
		}
	}

	getTaskScoreContent(task: any) {
		var reportHTML = ''
		if (task.isNA) {
			reportHTML = '<span style="padding: 1px 1px 1px 1px;-webkit-print-color-adjust: exact !important;background-color:#c0c0c0 !important;">N/A</span> '
		} else {
			reportHTML = '<span style="padding: 1px 1px 1px 1px;-webkit-print-color-adjust: exact !important;background-color:' + this.getStyle(task.assessmentScore) + ' !important;">' + task.assessmentScore + '%</span> '
		}
		return reportHTML
	}

	printTaskRegulatoryAgencies(task: any, paddingLeft: any) {
		var reportHTML = '<div style="padding:0px 5px 15px 5px;margin-left:' + paddingLeft + 'px">'
		for (var i = 0; i < task.regulatoryList.length; i++) {
			reportHTML += '<span style="padding: 1px 1px 1px 1px;-webkit-print-color-adjust: exact !important;background-color:#563F7A !important;color: #fff !important;display: inline-block;margin-bottom:3px;font-size: 0.8rem;">' + task.regulatoryList[i].text + '</span>&nbsp;'
		}
		reportHTML += '</div>'
		return reportHTML
	}

	loadHtmlData() {
		var reportData: Array<any> = []
		var reportColumns: Array<any> = []
		let reportHTML = '<h1>' + this.userName + '\'s Assessment</h1>';
		reportHTML += '<div style="font-size: 1.1rem">Job Profile: ' + this.assessmentModel.jobProfileDesc + '</div>'
		reportHTML += '<div style="padding:10px 0px 0px 0px;font-size: 1.1rem">Assessment Completed Date: ' + this.assessmentModel.assessmentCompletedDate.toLocaleDateString() + '</div>'
		if (this.selectedItems.length > 0) {
			var items = ''
			this.selectedItems.forEach(item => {
				if (items) {
					items += ", "
				}
				items += this.regShortList.find(reg => reg.id == item.id).text
			})
			reportHTML += '<div style="padding:10px 0px 0px 0px;font-size: 1.1rem">Regulatory Agency(s): ' + items + '</div>'
		}
		reportHTML += '<div style="padding:20px 0px 5px 0px;font-size: 1.1rem"><b>Final Assessment Score: <span style="padding: 1px 1px 1px 1px;-webkit-print-color-adjust: exact !important;background-color:' + this.getStyle(this.assessmentModel.assessmentScore) + ' !important;">' + this.assessmentModel.assessmentScore + '%</span></b></div>'
		if (this.allClicked) {
			reportHTML += '<div style="padding:5px 0px 5px 0px;font-size: 1.1rem"><b>Knowledge Check Score: ' + this.correct + ' out of ' + this.total + ' is correct</b></div>'
		}
		for (var i = 0; i < this.assessmentModel.jobProfile.coreCompetencyList.length; i++) {
			var ccItem = this.assessmentModel.jobProfile.coreCompetencyList[i]

			reportHTML += '<div style="padding:0px 5px 0px 0px;"><h2><span style="padding: 1px 1px 1px 1px;-webkit-print-color-adjust: exact !important;background-color:' + this.getStyle(ccItem.assessmentScore) + ' !important;">' + ccItem.assessmentScore + '%</span>&nbsp;' + ccItem.text + '</h2></div>'
			//ccItem.taskList = this.pipe.transform(ccItem.taskList, 'orderNum')
			for (var j = 0; j < ccItem.taskList.length; j++) {
				var task = ccItem.taskList[j]
				if (task.isTaskGroup) {
					reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:20px;-webkit-print-color-adjust: exact !important;background-color:#c0c0c0 !important;"><h3>' + task.taskGroup + '</h3></div>'
					//task.taskList = this.pipe.transform(task.taskList, 'orderNum')
					for (var k = 0; k < task.taskList.length; k++) {
						var tgTask = task.taskList[k]

						reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:40px"><h3  '
						if (tgTask.regulatoryList.length > 0) {
							reportHTML += 'style="margin-block-end: 0.2em;" '
						}
						reportHTML += '>' + this.getTaskScoreContent(tgTask) + ' ' + tgTask.text + '</h3></div>'
						if (tgTask.regulatoryList.length > 0) {
							reportHTML += this.printTaskRegulatoryAgencies(tgTask, 40)
						}

						if (tgTask.performanceList.length > 0) {
							reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:50px;font-weight:bold;padding-bottom:10px;">Performance</div>'
							reportHTML += this.printTaskContent(tgTask.performanceList, "text", 70)
						}
						if (tgTask.knowledgeList.length > 0) {
							reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:50px;font-weight:bold;padding-bottom:10px;">Knowledge</div>'
							reportHTML += this.printTaskContent(tgTask.knowledgeList, "knowledge", 70)
						}
						if (tgTask.knowledgeQuestionList.length > 0) {
							reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:50px;font-weight:bold;padding-bottom:10px;">Knowledge Question</div>'
							reportHTML += this.printKnowledgeQuestions(tgTask.knowledgeQuestionList, 70)
						}
					}
				} else {
					reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:20px"><h3 '
					if (task.regulatoryList.length > 0) {
						reportHTML += 'style="margin-block-end: 0.2em;" '
					}
					reportHTML += '>' + this.getTaskScoreContent(task) + ' ' + task.text + '</h3></div>'
					if (task.regulatoryList.length > 0) {
						reportHTML += this.printTaskRegulatoryAgencies(task, 20)
					}
					if (task.performanceList.length > 0) {
						reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:40px;font-weight:bold;padding-bottom:10px;">Performance</div>'
						reportHTML += this.printTaskContent(task.performanceList, "text", 60)
					}
					if (task.knowledgeList.length > 0) {
						reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:40px;font-weight:bold;padding-bottom:10px;">Knowledge</div>'
						reportHTML += this.printTaskContent(task.knowledgeList, "knowledge", 60)
					}
					if (task.knowledgeQuestionList.length > 0) {
						reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:40px;font-weight:bold;padding-bottom:10px;">Knowledge Question</div>'
						reportHTML += this.printKnowledgeQuestions(task.knowledgeQuestionList, 60)
					}
				}
			}
		}
		return reportHTML
	}

	private getTaskContent(cc: any, task: any, tgTaskText: any) {
		var items = ''
		task.regulatoryList.forEach(item => {
			if (items) {
				items += ", "
			}
			items += item.ShortName
		})
		let data = []

		//task.performanceList = this.pipe.transform(task.performanceList, 'orderNum')
		for (var j = 0; j < task.performanceList.length; j++) {
			var performance = task.performanceList[j]
			temp = {
				"First Name": this.assessmentModel.firstName,
				"Last Name": this.assessmentModel.lastName,
				"Job Profile": this.assessmentModel.jobProfileDesc,
				"Date of Assessment": this.assessmentModel.assessmentCompletedDate.toLocaleDateString(),
				"Final Assessment Score": this.assessmentModel.assessmentScore,
				"Core Competency": cc.text,
				"Task Group": tgTaskText,
				"Task": task.text,
				"Task Regulatory Agency(s)": items,
				"Task Yes or N/A": task.isNA ? 'N/A' : task.isYes ? 'Yes' : '',
				"Task Reason for N/A": task.reasonNA,
				"Task Score": task.isNA ? '' : task.assessmentScore,
				"Performance": performance.text,
				"Performance Yes or No or N/A": performance.answer == 1 ? 'N/A' : (performance.answer == 2 ? "No" : "Yes"),
				"Performance Reason for N/A": performance.reasonNA,
				"Performance Note": performance.assessmentNote,
				"Knowledge": "",
				"Knowledge Yes or No or N/A": "",
				"Knowledge Reason for N/A": "",
				"Knowledge Note": "",
				"Knowledge Question": "",
				"Knowledge Question Answer": "",
				"Knowledge Question Answer Yes or No": "",
				"Candidates Knowledge Question Answer Chosen 1 or 0": "",
				"Candidates Knowledge Question Answer Correct Yes or No": "",
				"Candidate Answered the Complete Question Correct Yes or No": "",
				"Knowledge Check Score": this.correct + " out of " + this.total + " is correct",
				"Check List": "",
				"Check List Yes or No or N/A": "",
				"Check List Reason for N/A": ""
			}
			data.push(temp)

			//performance.checkList = this.pipe.transform(performance.checkList, 'orderNum')
			for (var k = 0; k < performance.checkList.length; k++) {
				var checkList = performance.checkList[k]
				temp = {
					"First Name": this.assessmentModel.firstName,
					"Last Name": this.assessmentModel.lastName,
					"Job Profile": this.assessmentModel.jobProfileDesc,
					"Date of Assessment": this.assessmentModel.assessmentCompletedDate.toLocaleDateString(),
					"Final Assessment Score": this.assessmentModel.assessmentScore,
					"Core Competency": cc.text,
					"Task Group": tgTaskText,
					"Task": task.text,
					"Task Regulatory Agency(s)": items,
					"Task Yes or N/A": task.isNA ? 'N/A' : task.isYes ? 'Yes' : '',
					"Task Reason for N/A": task.reasonNA,
					"Task Score": task.isNA ? '' : task.assessmentScore,
					"Performance": performance.text,
					"Performance Yes or No or N/A": performance.answer == 1 ? 'N/A' : (performance.answer == 2 ? "No" : "Yes"),
					"Performance Reason for N/A": performance.reasonNA,
					"Performance Note": performance.assessmentNote,
					"Knowledge": "",
					"Knowledge Yes or No or N/A": "",
					"Knowledge Reason for N/A": "",
					"Knowledge Note": "",
					"Knowledge Question": "",
					"Knowledge Question Answer": "",
					"Knowledge Question Answer Yes or No": "",
					"Candidates Knowledge Question Answer Chosen 1 or 0": "",
					"Candidates Knowledge Question Answer Correct Yes or No": "",
					"Candidate Answered the Complete Question Correct Yes or No": "",
					"Knowledge Check Score": this.correct + " out of " + this.total + " is correct",
					"Check List": checkList.text,
					"Check List Yes or No or N/A": checkList.answer == 1 ? 'N/A' : (checkList.answer == 2 ? "No" : "Yes"),
					"Check List Reason for N/A": checkList.reasonNA
				}

				data.push(temp)
			}

		}

		//task.knowledgeList = this.pipe.transform(task.knowledgeList, 'orderNum')
		for (var i = 0; i < task.knowledgeList.length; i++) {
			var knowledge = task.knowledgeList[i]
			var temp = {
				"First Name": this.assessmentModel.firstName,
				"Last Name": this.assessmentModel.lastName,
				"Job Profile": this.assessmentModel.jobProfileDesc,
				"Date of Assessment": this.assessmentModel.assessmentCompletedDate.toLocaleDateString(),
				"Final Assessment Score": this.assessmentModel.assessmentScore,
				"Core Competency": cc.text,
				"Task Group": tgTaskText,
				"Task": task.text,
				"Task Regulatory Agency(s)": items,
				"Task Yes or N/A": task.isNA ? 'N/A' : task.isYes ? 'Yes' : '',
				"Task Reason for N/A": task.reasonNA,
				"Task Score": task.isNA ? '' : task.assessmentScore,
				"Performance": "",
				"Performance Yes or No or N/A": "",
				"Performance Reason for N/A": "",
				"Performance Note": "",
				"Knowledge": knowledge.knowledge,
				"Knowledge Yes or No or N/A": knowledge.answer == 1 ? 'N/A' : (knowledge.answer == 2 ? "No" : "Yes"),
				"Knowledge Reason for N/A": knowledge.reasonNA,
				"Knowledge Note": knowledge.assessmentNote,
				"Knowledge Question": "",
				"Knowledge Question Answer": "",
				"Knowledge Question Answer Yes or No": "",
				"Candidates Knowledge Question Answer Chosen 1 or 0": "",
				"Candidates Knowledge Question Answer Correct Yes or No": "",
				"Candidate Answered the Complete Question Correct Yes or No": "",
				"Knowledge Check Score": this.correct + " out of " + this.total + " is correct",
				"Check List": "",
				"Check List Yes or No or N/A": "",
				"Check List Reason for N/A": ""
			}
			data.push(temp)
		}

		//task.knowledgeQuestionList = this.pipe.transform(task.knowledgeQuestionList, 'orderNum')
		for (var l = 0; l < task.knowledgeQuestionList.length; l++) {
			var knowledge = task.knowledgeQuestionList[l]
			var answerList = []
			if (knowledge.type == 'Boolean') {
				answerList.push({ 'text': 'True', 'IsCorrect': knowledge.booleanAnswerCorrect == 1 ? 1 : 0, 'GivenAnswer': ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)) ? 1 : 0, 'IsGivenAnswerCorrect': knowledge.answer == 3 ? 1 : 0 })
				answerList.push({ 'text': 'False', 'IsCorrect': knowledge.booleanAnswerCorrect == 0 ? 1 : 0, 'GivenAnswer': ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)) ? 1 : 0, 'IsGivenAnswerCorrect': knowledge.answer == 3 ? 1 : 0 })
			} else {
				answerList = knowledge.answerList
			}
			for (var m = 0; m < answerList.length; m++) {
				let knowledgeAnswer = answerList[m]
				temp = {
					"First Name": this.assessmentModel.firstName,
					"Last Name": this.assessmentModel.lastName,
					"Job Profile": this.assessmentModel.jobProfileDesc,
					"Date of Assessment": this.assessmentModel.assessmentCompletedDate.toLocaleDateString(),
					"Final Assessment Score": this.assessmentModel.assessmentScore,
					"Core Competency": cc.text,
					"Task Group": tgTaskText,
					"Task": task.text,
					"Task Regulatory Agency(s)": items,
					"Task Yes or N/A": task.isNA ? 'N/A' : task.isYes ? 'Yes' : '',
					"Task Reason for N/A": task.reasonNA,
					"Task Score": task.isNA ? '' : task.assessmentScore,
					"Performance": "",
					"Performance Yes or No or N/A": "",
					"Performance Reason for N/A": "",
					"Performance Note": "",
					"Knowledge": "",
					"Knowledge Yes or No or N/A": "",
					"Knowledge Reason for N/A": "",
					"Knowledge Note": "",
					"Knowledge Question": knowledge.knowledge,
					"Knowledge Question Answer": knowledgeAnswer.FileType ? "Visual Question" : knowledgeAnswer.text,
					"Knowledge Question Answer Yes or No": knowledgeAnswer.IsCorrect == 1 ? "Yes" : "No",
					"Candidates Knowledge Question Answer Chosen 1 or 0": knowledgeAnswer.GivenAnswer == 1 ? "1" : "0",
					"Candidates Knowledge Question Answer Correct Yes or No": knowledgeAnswer.IsGivenAnswerCorrect == 1 ? "Yes" : "No",
					"Candidate Answered the Complete Question Correct Yes or No": knowledge.answer == 3 ? "Yes" : "No",
					"Knowledge Check Score": this.correct + " out of " + this.total + " is correct",
					"Check List": "",
					"Check List Yes or No or N/A": "",
					"Check List Reason for N/A": ""
				}
				data.push(temp)
			}
		}

		return data;
	}

	public formatDate(date) {
		date = date.split("/");
		var year = date[2]
		year = year.substring(2);
		var month = date[0];
		var day = date[1];

		if (month.length == 1) {
			month = "0" + month;
		}

		if (day.length == 1) {
			day = "0" + day;
		}

		var Date = month + day + year
		return Date;
	}

	public exportToExcel() {
		this.isLoading = true
		var data = []
		var outputName = this.userName + '\'s Assessment_' + this.formatDate(this.assessmentModel.assessmentCompletedDate.toLocaleDateString())
		this.assessmentModel.jobProfile.coreCompetencyList = this.pipe.transform(this.assessmentModel.jobProfile.coreCompetencyList, 'orderNum')
		for (var i = 0; i < this.assessmentModel.jobProfile.coreCompetencyList.length; i++) {
			var cc = this.assessmentModel.jobProfile.coreCompetencyList[i]
			//cc.taskList = this.pipe.transform(cc.taskList, 'orderNum')
			for (var j = 0; j < cc.taskList.length; j++) {
				var task = cc.taskList[j]
				if (task.isTaskGroup) {
					//task.taskList = this.pipe.transform(task.taskList, 'orderNum')
					task.taskList.forEach(tgTask => {
						data = data.concat(this.getTaskContent(cc, tgTask, task.taskGroup))
					});
				} else {
					data = data.concat(this.getTaskContent(cc, task, ""))
				}
			}
		}
		this.reportService.exportAsExcelFile(data, outputName);
		this.isLoading = false
	}

}
