<div class="col-lg-12">
	<div class="col" style="overflow:hidden">
		<div class="float-left">
			<h4>Organization</h4>
		</div>
		<div class="float-right">
			<button type="button" (click)="addNode()" class="btn btn-outline-warning btn-sm mr-2" [disabled]="!isAddOrgCodeAllowed">
				<i class="fa fa-plus fa-fw"></i>&nbsp;Add Org Code
			</button>
			<button type="button" (click)="renameNode()" class="btn btn-outline-warning btn-sm mr-2" [disabled]="!isModifyOrgCodeAllowed">
				<i class="fa fa-pencil-square-o fa-fw"></i>&nbsp;Edit Org Code
			</button>
			<button type="button" (click)="deleteNode()" class="btn btn-outline-warning btn-sm" [disabled]="!isDeleteOrgCodeAllowed">
				<i class="fa fa-trash fa-fw"></i>&nbsp;Delete Org Code
			</button>
		</div>
	</div>
	<hr>
</div>

<alert></alert>
<!-- org code create -->
<div id="tree">	
</div>

<!-- add org code model-->
<modal class="modal fade" #addOrgcodemodel>
	<div class="modal-header">
		<h5 class="modal-title">Add Org Code</h5>
	</div>
	<div class="modal-body">
		<form [formGroup]="addOrgcodeForm">
			<div class="form-group">
				<label for="name">Org Code:
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<input noSpaces maxlength="100" class="form-control" type="text" id="orgcode" formControlName="orgcode" name="orgcode">
			</div>
			<div *ngIf="orgtypes">
				<div class="form-group">
					<label for="name">Org Type:
						<sup>
							<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
						</sup>
					</label>
					<!-- <select class="form-control" formControlName="orgtype" name="orgtype" id="orgtype" [(ngModel)]="selectedOrgtype" required>
                        <option *ngFor="let orgtype of orgtypes" [ngValue]="orgtype.Id" > {{orgtype.Orgtype}} </option>
						  </select> -->
					<ng-select [allowClear]="true" [items]="orgtypes" placeholder="Select org type" name="orgtype" id="orgtype" formControlName="orgtype">
					</ng-select>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-warning" (click)="addNodeSave()" [disabled]="!addOrgcodeForm.valid">Add</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
	</div>
</modal>

<!-- edit org code model-->
<modal class="modal fade" #editOrgcodemodel>
	<div class="modal-header">
		<h5 class="modal-title">Edit Org Code</h5>
	</div>
	<div class="modal-body">
		
		<form [formGroup]="editOrgcodeForm">
			<input class="form-control" type="hidden" id="editorgcodeId" formControlName="editorgcodeId" name="editorgcodeId" [(ngModel)]="selectededitorgcodeId">
			<div class="form-group">
				<label for="name">Org Code:
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<input noSpaces maxlength="100" class="form-control" type="text" id="editorgcode" formControlName="editorgcode" name="editorgcode" [(ngModel)]="selectedEditOrgcode">
			</div>
			<div *ngIf="orgtypes">
				<div class="form-group">
					<label for="name">Org Type:
						<sup>
							<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
						</sup>
					</label>
					<select class="form-control" formControlName="editorgtype" name="editorgtype" id="editorgtype" [ngModel]="selectedEditOrgtype">
						<option *ngFor="let editorgtype of editorOrgTypes" [ngValue]="editorgtype.Id"> {{editorgtype.Orgtype}} </option>
					</select>
				</div>
			</div>
			<div class="form-group">
				<label for="name">Status:
					<sup>
						<i class="fa fa-asterisk" aria-hidden="true" style="color:red; font-size:0.6em"></i>
					</sup>
				</label>
				<!-- <select class="form-control" formControlName="status" name="status" id="status" [ngModel]="selectedStatus">	
					<option [ngValue]="1">Active </option>
					<option [ngValue]="0">Inactive </option>
				</select> -->
				<select class="form-control" formControlName="status" name="status" id="status">
					<option [ngValue]="1">Active</option>
					<option [ngValue]="0">Inactive</option>
				  </select>				  
				  
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-warning" (click)="editNodeSave()" [disabled]="!editOrgcodeForm.valid">Save</button>
		<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
	</div>
</modal>