import { Component, OnInit, Input, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

import { AssessmentService } from "../assessment.service";
import { AlertService } from "../../util/alert/alert.service";
import { NoteService } from '../../complib/note/note.service'
import { NAReasonService } from '../../complib/nareason/nareason.service';

import { JobProfile } from "../../jobprofile/jobprofile.model";
import { CoreCompetency } from "../../complib/corecompetency/corecompetency.model";
import { Task } from "../../complib/task/task.model";
import { Performance } from "../../complib/performance/performance.model";
import { Knowledge } from "../../complib/knowledge/knowledge.model";
import { CheckList } from "../../complib/checklist/checklist.model";
import { AssessmentDocument } from "./assessmentdocument.model";
import { AssessmentModel } from "../../assessment/assessment.model";
import { Opportunity } from "./opportunity.model";

import { OrderArrayByType } from "../../util/orderarraybytype.pipe";
import { PreventParentClickEvent } from "../../util/preventparentclick.directive";
import { ModalComponent } from "ng2-bs3-modal/ng2-bs3-modal";
import { Constants } from "../../util/const/constants";
import * as FileSaver from "file-saver";
import { SharedService } from '../../home/shared.service'
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: "app-pahierarchy",
	templateUrl: "./pahierarchy.component.html",
	styleUrls: ["./pahierarchy.component.scss"]
})

export class PAHierarchyComponent {
	@ViewChild("addDocumentModal", { static: true }) addDocumentModal: ModalComponent;
	@ViewChild('changeAnswersModal', { static: true }) changeAnswersModal: ModalComponent
	@ViewChild('changeYesAnswersModal', { static: true }) changeYesAnswersModal: ModalComponent
	@ViewChild('completeAssessmentModal', { static: true }) completeAssessmentModal: ModalComponent
	@ViewChild('addANoteModal', { static: true }) addANoteModal: ModalComponent
	@ViewChild('addReasonForNAModal', { static: true }) addReasonForNAModal: ModalComponent
	@ViewChild("fileInput", { static: true }) el: ElementRef;
	@ViewChild('SignaturePad1', { static: false }) signaturePad1: SignaturePad;
	@ViewChild('SignaturePad2', { static: false }) signaturePad2: SignaturePad;
	@ViewChildren('container1') public sigContainer1: QueryList<ElementRef>;

	private signaturePadOptions: Object = {};

	@Input() jobProfileModel: JobProfile;
	@Input() assessmentModel: AssessmentModel;

	public assessment: AssessmentModel

	public selectedCCItem: CoreCompetency;
	public showccHierarchyPanel: boolean = false;
	public showofdPanel: boolean = false;
	public currentPerformanceNote: string = ''
	public currentKnowledgeNote: string = ''
	public isCCCompleted: boolean = false
	public isAnsweredCCValid: boolean = false
	public showAssmentCompletePanel: boolean = false
	public showCandidateStrengthsButton: boolean = false;
	public showOpportunityforDevelopmentButton: boolean = true;
	public assessmentCompleted: boolean = false;
	//used these variables to change answer for the already NA task items
	public selectedAnswer: number
	public selectedType: string
	public selectedItem: any
	public selectedParentItem: any

	public showTaskDetail: string = "";
	public showTaskGroupDetail: string = ''
	public showPerformanceDetail: string = "";
	public showKnowledgeDetail: string = ""
	public showDocumentDetail: string = "";
	public showCheckListDetail: string = ""
	public showCandidateStrengthPanel: boolean = false

	// public answerList: Array<Select2OptionData> = [];
	// public answerOptions: Select2Options = {
	// 	placeholder: "Select answer",
	// 	width: "80%",
	// 	theme: "classic"
	// };
	public ofdCategoryList: Array<any> = [];
	public selectedOFDCategory: string = ''

	public addDocumentForm: FormGroup;
	public opportunityForm: FormGroup;
	public candidateStrengthsForm: FormGroup;
	public assessmentCompleteForm: FormGroup;
	//documnet category
	public categoryList: Array<any> = [];
	public selectedTask: Task = null;
	public selectedTaskGroup: Task = null;
	public selectedDocument: File = null;
	public selectedPerformance: Performance = null
	public selectedKnowledge: Knowledge = null
	public isPerformance: boolean = false
	public showError: boolean = false;
	public constants;
	public changeOpportunityValue = [];
	public isLoading: boolean = false;
	public selectedCCIndex: number = null;
	public selectedTaskIndex: number = null;
	public categoryId: any;

	public completeAssessmentModalBody: string = Constants.MODAL_BODY_COMPLETE_ASSESSMENT_PART1
	public assessmentDefaultReasonNA: string = Constants.MODAL_BODY_COMPLETE_ASSESSMENT_PART2
	public changeAnswersModalBody: string = Constants.MODAL_BODY_OVERRIDE_ANSWERS
	public changeYesAnswersModalBody: string = Constants.MODAL_BODY_OVERRIDE_ANSWERS_YES
	public defaultReasonNA: string = ""
	//true - change N/A task elements answers
	//false - change task's isNA which has answers for elements
	public changeYESBtnEvent: boolean = false

	//true - change Yes task elements answers
	//false - change task's isYes which has answers for elements
	public changeYESAnswerEvent: boolean = false

	//note table related variables
	private selectedNote: any
	public selectedNoteList: Array<any> = [];
	public data: Array<any> = [];
	public filteredData: Array<any> = [];
	public searchNote: string = "";
	itemsPerPage: number = 10;
	currentPage: number = 1;
	maxNoOfPages: number = 10;
	total: number = 1;
	itemsPerPage1: number = 10;
	currentPage1: number = 1;
	maxNoOfPages1: number = 10;
	total1: number = 1;
	paginatedResults = [];
	numPages: number = 1;
	numPages1: number = 1;
	public searchReason: string = "";
	reasonList: Array<any> = [];
	reasonItem: any = null
	reasonItemType: any = null
	public filteredReasonData: Array<any> = [];
	reasonNAResults = [];
	selectedReasonNA: any = null
	selectedReason: any = null
	assessorId: string = ''
	stortedSelectedUserRole: string = ''
	loggedUserId: string = ''
	selectedUserRole: string = ''
	disableforAdmin: boolean = false;

	constructor(public assessmentService: AssessmentService, public alertService: AlertService, private sharedService: SharedService,
		private formBuilder: FormBuilder, public noteService: NoteService, private changeDetectorRef: ChangeDetectorRef, private detectorRef: ChangeDetectorRef, private naReasonService: NAReasonService,private sanitizer: SanitizationService) {
		//add values to answer list
		// this.answerList.push({ id: "3", text: "Yes" });
		// this.answerList.push({ id: "2", text: "No" });
		// this.answerList.push({ id: "1", text: "N/A" });

		this.assessmentService.getOpportunityCategories().subscribe(
			data => {
				data.categoryList.forEach(category => {
					this.ofdCategoryList.push({
						'id': category.Id,
						'text': category.Category
					})
				});
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)

		// this.categoryList.push("Proof of Trainee");		
		this.constants = Constants;
		this.assessment = this.assessmentService.getSelectedAssessmentModel()
		this.assessorId = this.assessment.assessorId
		this.stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
		this.loggedUserId = localStorage.getItem('userId');
		this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
		if ((this.assessorId == this.loggedUserId) || (this.selectedUserRole[0][1] == 'Admin' && (this.assessorId == this.loggedUserId))) {
			this.disableforAdmin = false
		} else {
			this.disableforAdmin = true
		}
	}

	ngOnInit() {
		this.categoryList = [{ id: 'Procedure', text: 'Procedure' }, { id: 'Overview', text: 'Overview' }, { id: 'Policy', text: 'Policy' }];
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.addDocumentForm = this.formBuilder.group({
			category: new FormControl(null, Validators.required),
			file: new FormControl(null, Validators.required),
			note: new FormControl(null)
		});

		this.opportunityForm = new FormGroup({
			category: new FormControl(null, Validators.required),
			opportunityText: new FormControl({ value: null, disabled: this.disableforAdmin }, Validators.required)
		});

		this.assessmentCompleteForm = new FormGroup({
			candidateSignature: new FormControl('', Validators.required),
			assessorSignature: new FormControl('', Validators.required),
			assessorCertification: new FormControl(null, Validators.required)
		})
		this.setDisableForCompleteButton(this.assessmentModel);
		this.candidateStrengthsForm = this.formBuilder.group({
			candidateStrengths: new FormControl({ value: this.assessmentModel.candidateStrengths, disabled: (this.assessmentCompleted || this.disableforAdmin) }, Validators.required)
		});
		this.showCandidateStrengthsButton = true;
		if (this.assessmentModel.status == "Completed" || this.assessmentModel.assessorCompleted == 1) {
			this.assessmentCompleted = true;
			this.candidateStrengthsForm.get("candidateStrengths").disable()
			this.showCandidateStrengthsButton = this.assessmentModel.candidateStrengths != null ? true : false;
			this.showOpportunityforDevelopmentButton = this.assessmentModel.opportunityList.length > 0 ? true : false;
		}


	}

	ngAfterViewInit() {
		// this.beResponsive();
		// console.log('[ngAfterViewInit] (assessmentModel) ' + JSON.stringify(this.assessmentModel))
		// console.log('[ngAfterViewInit] (jobProfileModel) ' + JSON.stringify(this.jobProfileModel))		
	}

	public selectCCItem(ccItem: CoreCompetency, index: number) {
		this.selectedCCItem = ccItem;
		this.selectedCCIndex = index;
		this.showccHierarchyPanel = true;
	}

	/**
	 * [change ofd category value]
	 * @param  {any}    event [description]
	 * @return {[type]}       [description]
	 */
	// public changeOFDCategory(event: any) {
	// 	this.opportunityForm.get('category').setValue(event.value);
	// }

	/**
	 * close button click event in ccItem
	 * @method closeCCItem
	 */
	public closeCCItem(): void {
		this.resetAllVariable()
		//update core competency status
		//this.updateCoreCompetency(this.selectedCCItem)
	}

	/** show and collape panels */
	public showCollapsePanel(item: any, itemType: string) {
		switch (itemType) {
			case 'task':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, item.id)
				this.showPerformanceDetail = ''
				this.showTaskGroupDetail = ''
				this.showKnowledgeDetail = ''
				break;
			case 'taskGroup':
				this.showTaskGroupDetail = this.changeVarableToShowHideItem(this.showTaskGroupDetail, item.id)
				this.showPerformanceDetail = ''
				this.showTaskDetail = ''
				this.showKnowledgeDetail = ''
				break;
			case 'taskGroupTask':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, item.id)
				this.showPerformanceDetail = ''
				this.showKnowledgeDetail = ''
				break
			case 'performance':
				this.showPerformanceDetail = this.changeVarableToShowHideItem(this.showPerformanceDetail, item.id)
				this.currentPerformanceNote = item.assessmentNote
				break;
			case 'knowledge':
				this.showKnowledgeDetail = this.changeVarableToShowHideItem(this.showKnowledgeDetail, item.id)
				this.currentKnowledgeNote = item.assessmentNote
				break;
			case 'document':
				this.showDocumentDetail = this.changeVarableToShowHideItem(this.showDocumentDetail, item.id)
				break;
			case 'checkList':
				this.showCheckListDetail = this.changeVarableToShowHideItem(this.showCheckListDetail, item.id)
				break;
		}
	}
	private changeVarableToShowHideItem(val: string, text: string): string {
		if (val != text) {
			val = text;
		} else {
			val = "";
		}
		return val;
	}

	changeReasonForNA(item: any, ccItem: any, itemType: any) {
		this.reasonItem = item
		this.selectedReasonNA = item.reasonNA
		this.reasonItemType = itemType
		this.loadAllReasons()
	}

	saveReasonForNA() {
		switch (this.reasonItemType) {
			case 'task':
				this.saveTaskReasonNA()
				break;
			case 'performance':
				this.savePerformanceReasonNA()
				break;
			case 'knowledge':
				this.saveKnowledgeReasonNA()
				break;
			case 'checkList':
				this.saveChecklistReasonNA()
				break;
		}
	}

	saveTaskReasonNA() {
		let taskItem = this.reasonItem
		taskItem.reasonNA = this.selectedReason.reason
		taskItem.reasonNAId = this.selectedReason.id
		taskItem.assessmentId = this.assessmentModel.id;

		this.assessmentService.updateTaskReasonForNA(taskItem).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.reasonItem.reasonNA = this.selectedReason.reason
				this.reasonItem.reasonNAId = this.selectedReason.id
				this.reasonItem.performanceList.forEach(element => {
					element.reasonNA = this.selectedReason.reason
					element.reasonNAId = this.selectedReason.id
					element.checkList.forEach(cItem => {
						cItem.reasonNA = this.selectedReason.reason
						cItem.reasonNAId = this.selectedReason.id
					});
				});
				this.reasonItem.knowledgeList.forEach(element => {
					element.reasonNA = this.selectedReason.reason
					element.reasonNAId = this.selectedReason.id
				})
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);

	}

	saveKnowledgeReasonNA() {
		let knowItem: Knowledge = new Knowledge(this.reasonItem.id, this.reasonItem.text);
		knowItem.reasonNA = this.selectedReason.reason
		knowItem.reasonNAId = this.selectedReason.id
		knowItem.assessmentId = this.assessmentModel.id;
		knowItem.assId = this.reasonItem.assId;

		this.assessmentService.updateKnowledgeReasonForNA(knowItem).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.reasonItem.reasonNA = this.selectedReason.reason
				this.reasonItem.reasonNAId = this.selectedReason.id
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);

	}

	saveChecklistReasonNA() {
		let clItem: CheckList = new CheckList(this.reasonItem.id, this.reasonItem.text);
		clItem.reasonNA = this.selectedReason.reason
		clItem.reasonNAId = this.selectedReason.id
		clItem.assessmentId = this.assessmentModel.id;
		clItem.assId = this.reasonItem.assId;

		this.assessmentService.updateCheckListReasonForNA(clItem).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.reasonItem.reasonNA = this.selectedReason.reason
				this.reasonItem.reasonNAId = this.selectedReason.id
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);

	}

	savePerformanceReasonNA() {
		let perItem: Performance = new Performance(this.reasonItem.id, this.reasonItem.text);
		perItem.reasonNA = this.selectedReason.reason
		perItem.reasonNAId = this.selectedReason.id
		perItem.assessmentId = this.assessmentModel.id;
		perItem.assId = this.reasonItem.assId;

		this.assessmentService.updatePerformanceReasonForNA(perItem).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.reasonItem.reasonNA = this.selectedReason.reason
				this.reasonItem.reasonNAId = this.selectedReason.id
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);

	}

	private showCandidateStrength() {
		if (this.showofdPanel) {
			this.showofdPanel = !this.showofdPanel;
		}
		this.showCandidateStrengthPanel = !this.showCandidateStrengthPanel;
		if (this.showCandidateStrengthPanel) {
			this.candidateStrengthsForm.get('candidateStrengths').setValue(this.assessmentModel.candidateStrengths);
		}

	}

	private showHideOpportunityPanel() {
		if (this.showCandidateStrengthPanel) {
			this.showCandidateStrengthPanel = !this.showCandidateStrengthPanel;
		}
		this.showofdPanel = !this.showofdPanel;
	}
	//show/hide assessment complete form
	// public showCompleteAssessmentPanel() {
	// 	this.showAssmentCompletePanel = !this.showAssmentCompletePanel
	// }

	/**
	 * [resetAllVariable description]
	 * @method resetAllVariable
	 */
	private resetAllVariable(): void {
		this.showccHierarchyPanel = false
		this.showofdPanel = false
		this.currentPerformanceNote = ''
		this.showTaskDetail = ''
		this.showPerformanceDetail = ''
		this.showDocumentDetail = ''
		this.showCandidateStrengthPanel = false;
	}

	// private assessorDrawComplete() {
	// 	this.assessmentCompleteForm.get("assessorSignature").setValue(this.signaturePad2.toDataURL());
	// }
	NATasksAndCompleteAssessment() {
		let notAnsweredTaskList = []
		let taskGroupList = []
		let CCList = []
		this.jobProfileModel.coreCompetencyList.forEach(CC => {
			CCList.push(CC.assId)
			CC.taskList.forEach(task => {
				if (!task.isTaskGroup) {
					if (task.assStatus != 3) {
						notAnsweredTaskList.push(task)
					}
				} else {
					var taskGroupFound: boolean = false
					task.taskList.forEach(taskElement => {
						if (taskElement.assStatus != 3) {
							taskGroupFound = true
							notAnsweredTaskList.push(taskElement)
						}
					})
					if (taskGroupFound) {
						if (!taskGroupList.includes(item => {
							if (item == task.assId) {
								return true
							} else {
								return false
							}
						})) {
							taskGroupList.push(task)
						}
					}
				}
			})
		})

		this.isLoading = true;
		this.assessmentService.updateTasksToNA(this.assessmentModel.id, notAnsweredTaskList, taskGroupList, CCList)
			.subscribe(
			data => {
				notAnsweredTaskList.forEach(task => {
					this.updateTaskElementsToNAComplete(task, data.defaultReasonNA)
				})
				taskGroupList.forEach(taskGrp => {
					taskGrp.assStatus = 3
				})
				this.jobProfileModel.coreCompetencyList.forEach(CC => {
					CC.assStatus = 3
				})
				this.completeAssessment()
			},
			error => {
				this.isLoading = false
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}

	updateTaskElementsToNA(task: Task, reasonNA: any) {
		task.performanceList.forEach(per => {
			per.answer = 1
			per.reasonNA = reasonNA
			per.checkList.forEach(cl => {
				cl.answer = 1
				cl.reasonNA = reasonNA
			})
		})
		task.knowledgeList.forEach(kno => {
			kno.answer = 1
			kno.reasonNA = reasonNA
		})
		task.reasonNA = reasonNA
		task.isNA = true
		task.assStatus = 3
	}

	updateTaskElementsToNAComplete(task: Task, reasonNA: any) {
		task.performanceList.forEach(per => {
			if (per.answer == 0) {
				per.answer = 1
				per.reasonNA = reasonNA
			}
			per.checkList.forEach(cl => {
				if (cl.answer == 0) {
					cl.answer = 1
					cl.reasonNA = reasonNA
				}
			})
		})
		task.knowledgeList.forEach(kno => {
			if (kno.answer == 0) {
				kno.answer = 1
				kno.reasonNA = reasonNA
			}
		})
		if (task.assStatus == 0) {
			task.reasonNA = reasonNA
			task.isNA = true
		}
		task.assStatus = 3
	}

	completeBtnClickEvent() {
		// this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
		// this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
		if (!this.isCCCompleted && this.isAnsweredCCValid) {
			this.isLoading = true;
			this.naReasonService.getDefaultReasonForNA()
				.subscribe(
				data => {
					this.isLoading = false
					if (data.row.length > 0) {
						this.defaultReasonNA = data.row[0].Reason
					}
					this.completeAssessmentModal.open()
				},
				error => {
					this.isLoading = false
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				)

		} else {
			this.completeAssessment()
		}
	}

	completeAssessment() {
		this.isLoading = true;
		this.assessmentService.completeAssessment(this.assessmentModel.id)
			.subscribe(
			data => {
				this.isLoading = false
				this.alertService.clear()
				this.alertService.success(data.message);
				// this.showHierarchy = true
				// this.assessmentModel.status = 'Completed'
				this.assessmentCompleted = true;
				this.candidateStrengthsForm.get("candidateStrengths").disable()
				this.assessmentModel.assessorCompleted = 1
				this.setDisableForCompleteButton(this.assessmentModel)
			},
			error => {
				this.isLoading = false
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}

	// public beResponsive() {
	// 	if (this.signaturePad1) {
	// 		this.signaturePad1.clear();
	// 		this.signaturePad2.clear();
	// 	}
	// 	this.size(this.sigContainer1.first);
	// }

	// public size(container: ElementRef) {
	// 	let width = container.nativeElement.clientWidth;
	// 	this.signaturePadOptions = {// passed through to szimek/signature_pad constructo
	// 		'canvasWidth': ((width / 12) * 5) - 20,
	// 	};
	// }

	// clearSignaturePad1() {
	// 	this.signaturePad1.clear();
	// 	this.assessmentCompleteForm.get("candidateSignature").setValue("");
	// }

	// clearSignaturePad2() {
	// 	this.signaturePad2.clear();
	// 	this.assessmentCompleteForm.get("assessorSignature").setValue("");
	// }


	//<editor-fold> UPDATE ANSWER
	public changeAnswer(answer: number, type: string, item: any, parentItem: any, task: Task, taskGroup: Task) {
		if (task.isNA) {
			this.changeYESBtnEvent = true //modal yes button event
			this.changeAnswersModalBody = Constants.MODAL_BODY_TASK_OVERRIDE_NA
			this.selectedTask = task
			this.selectedAnswer = answer
			this.selectedType = type
			this.selectedItem = item
			this.selectedParentItem = parentItem
			this.changeAnswersModal.open()
		} else if (task.isYes) {
			this.changeYESAnswerEvent = true //modal yes button event
			this.changeYesAnswersModalBody = Constants.MODAL_BODY_TASK_OVERRIDE_YES
			this.selectedTask = task
			this.selectedAnswer = answer
			this.selectedType = type
			this.selectedItem = item
			this.selectedParentItem = parentItem
			this.changeYesAnswersModal.open()
		} else {
			switch (type) {
				case "performance":
					this.updateAnswerOfPerformance(answer, item, parentItem, taskGroup);
					break;
				case "knowledge":
					this.updateAnswerOfKnowledge(answer, item, parentItem, taskGroup);
					break;
				case "checkList":
					this.updateAnswerOfCheckList(answer, item, parentItem);
					break;
				default:
					break;
			}
		}
	}

	//remove N/A from task
	private removeNAFromTask() {
		let task: Task = this.selectedTask
		task.assessmentId = this.assessmentModel.id
		this.assessmentService.updateTaskIsNA(task, false).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				task.isNA = false
				task.reasonNA = data.defaultReasonNA
				task.reasonNAId = data.defaultReasonNAId
				switch (this.selectedType) {
					case "performance":
						this.updateAnswerOfPerformance(this.selectedAnswer, this.selectedItem, this.selectedParentItem, null);
						break;
					case "knowledge":
						this.updateAnswerOfKnowledge(this.selectedAnswer, this.selectedItem, this.selectedParentItem, null);
						break;
					case "checkList":
						this.updateAnswerOfCheckList(this.selectedAnswer, this.selectedItem, this.selectedParentItem);
						break;
					default:
						break;
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	//remove Yes from task
	private removeYesFromTask() {
		let task: Task = this.selectedTask
		task.assessmentId = this.assessmentModel.id
		this.assessmentService.updateTaskIsYes(task, false).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				task.isNA = false
				task.isYes = false
				task.reasonNA = null
				task.reasonNAId = null
				switch (this.selectedType) {
					case "performance":
						this.updateAnswerOfPerformance(this.selectedAnswer, this.selectedItem, this.selectedParentItem, null);
						break;
					case "knowledge":
						this.updateAnswerOfKnowledge(this.selectedAnswer, this.selectedItem, this.selectedParentItem, null);
						break;
					case "checkList":
						this.updateAnswerOfCheckList(this.selectedAnswer, this.selectedItem, this.selectedParentItem);
						break;
					default:
						break;
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	/** update answer of the check list */
	private updateAnswerOfCheckList(answer: number, item: CheckList, parentItem: Performance) {
		let clItem: CheckList = new CheckList(item.id)
		clItem.answer = answer
		clItem.assId = item.assId;
		clItem.assessmentId = this.assessmentModel.id;

		this.assessmentService.updateAnswerOfCheckList(clItem).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				item.answer = answer;
				item.reasonNA = data.defaultReasonNA
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}
	/** update answer of knowledge */
	private updateAnswerOfKnowledge(answer: number, item: Knowledge, parentItem: Task, taskGroup: Task) {
		let knoItem: Knowledge = new Knowledge(item.knowledge, item.isTestQuestion);
		let previousAnswer = item.answer
		let previousParentStatus = parentItem.assStatus
		knoItem.answer = answer;
		knoItem.assId = item.assId;
		knoItem.jpccId = item.jpccId;
		knoItem.jptId = item.jptId;
		knoItem.assessmentId = this.assessmentModel.id;
		item.answer = answer;
		let isAllTaskNA = this.allNATasksInCC(parentItem, this.selectedCCItem)
		let isAllTaskItemNA = this.checkTaskItemsIsNA(parentItem)

		if (isAllTaskItemNA && isAllTaskNA && answer == 1) {
			if (previousAnswer == 0) {
				item.answer = 0
				parentItem.assStatus = 0
			} else {
				item.answer = previousAnswer
				parentItem.assStatus = previousParentStatus
			}
			this.alertService.clear();
			this.alertService.error(Constants.ERROR_ALL_NA_TASKS);
		} else {
			parentItem.assStatus = this.getTaskStatus(parentItem)
			parentItem.isNA = this.isTaskNA(parentItem)
			if (parentItem.isNA == 1) {
				parentItem.assStatus = 3
			} else {
				parentItem.isYes = this.isTaskYes(parentItem)
				if (parentItem.isYes == 1) {
					parentItem.assStatus = 3
				}
			}
			var taskGrpId = null
			var taskGroupStatus = null
			if (parentItem.taskGroupId) {
				if (taskGroup) {
					taskGrpId = taskGroup.assId
					taskGroup.assStatus = this.getTaskGroupStatus(parentItem)
					taskGroupStatus = taskGroup.assStatus
				}
			}
			this.selectedCCItem.assStatus = this.getCoreCompetencyStatus()
			this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
			this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
			this.isLoading = true

			this.assessmentService.updateAnswerOfKnowledge(knoItem, parentItem.assStatus, taskGrpId, taskGroupStatus, this.selectedCCItem.assStatus, parentItem.isNA, parentItem.isYes).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					item.reasonNA = data.defaultReasonNA
					if (parentItem.isNA) {
						parentItem.reasonNA = data.defaultReasonNA
					}
					this.isLoading = false
					//this.updateTask(parentItem);
				},
				error => {
					item.answer = previousAnswer;
					parentItem.assStatus = previousParentStatus;
					this.isLoading = false
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
		}
	}
	/** update answer of performance */
	private updateAnswerOfPerformance(answer: number, item: Performance, parentItem: Task, taskGroup: Task) {
		let perItem: Performance = new Performance(item.id, item.text);
		let previousAnswer = item.answer
		let previousParentStatus = parentItem.assStatus
		perItem.answer = answer;
		perItem.assId = item.assId;
		perItem.jpccId = item.jpccId;
		perItem.jptId = item.jptId;
		perItem.assessmentId = this.assessmentModel.id;
		item.answer = answer;
		let isAllTaskNA = this.allNATasksInCC(parentItem, this.selectedCCItem)
		let isAllTaskItemNA = this.checkTaskItemsIsNA(parentItem)

		if (this.checkAnswersOfCheckList(item)) {
			if (isAllTaskItemNA && isAllTaskNA && answer == 1) {
				if (previousAnswer == 0) {
					item.answer = 0
					parentItem.assStatus = 0
				} else {
					item.answer = previousAnswer
					parentItem.assStatus = previousParentStatus
				}
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_ALL_NA_TASKS);
			} else {
				parentItem.assStatus = this.getTaskStatus(parentItem)
				parentItem.isNA = this.isTaskNA(parentItem)
				if (parentItem.isNA == 1) {
					parentItem.assStatus = 3
				} else {
					parentItem.isYes = this.isTaskYes(parentItem)
					if (parentItem.isYes == 1) {
						parentItem.assStatus = 3
					}
				}
				var taskGrpId = null
				var taskGroupStatus = null
				if (parentItem.taskGroupId) {
					if (taskGroup) {
						taskGrpId = taskGroup.assId
						taskGroup.assStatus = this.getTaskGroupStatus(parentItem)
						taskGroupStatus = taskGroup.assStatus
					}
				}
				this.selectedCCItem.assStatus = this.getCoreCompetencyStatus()
				this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
				this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
				this.isLoading = true

				this.assessmentService.updateAnswerOfPerformance(perItem, parentItem.assStatus, taskGrpId, taskGroupStatus, this.selectedCCItem.assStatus, parentItem.isNA, parentItem.isYes).subscribe(
					data => {
						this.alertService.clear();
						this.alertService.success(data.message);
						item.reasonNA = data.defaultReasonNA
						if (parentItem.isNA) {
							parentItem.reasonNA = data.defaultReasonNA
						}
						this.isLoading = false
						//this.updateTask(parentItem);
					},
					error => {
						item.answer = previousAnswer;
						parentItem.assStatus = previousParentStatus;
						this.isLoading = false
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
				);
			}
		} else {
			item.answer = 0
			parentItem.assStatus = 0
			this.alertService.clear();
			this.alertService.error(Constants.ERROR_HAS_NO_ANSWER_CHECKLIST);
		}
	}

	/**
	 * [checkAnswersOfCheckList check answers of check list before change the answers of performance]
	 * @param  {Performance} performance [description]
	 * @return {boolean}                 [description]
	 */
	private checkAnswersOfCheckList(performance: Performance): boolean {
		let noAnswerCount: number = 0
		let clList: any = performance.checkList
		clList.forEach(clItem => {
			if (clItem.answer == 0) {
				noAnswerCount++
			} else {
				//do nothing
			}
		})
		if (noAnswerCount == 0) {
			return true
		} else {
			return false
		}
	}

	private getTaskStatus(task: Task) {
		var taskStatus = 0;
		let perListStatus: any = this.getListStatus(task.performanceList);
		let knoListStatus: any = this.getListStatus(task.knowledgeList);

		if (perListStatus.hasAnswerCount == task.performanceList.length) {
			//all performance has answers check knowledge
			if (knoListStatus.hasAnswerCount == task.knowledgeList.length) {
				taskStatus = 3;
			} else {
				taskStatus = 2;
			}
		} else {
			//performanceList has items with no answer			
			if (knoListStatus.hasAnswerCount == task.knowledgeList.length || knoListStatus.hasAnswerCount > 0 || perListStatus.hasAnswerCount > 0) {
				taskStatus = 2;
			} else {
				taskStatus = 0;
			}
		}
		return taskStatus;
	}

	private getTaskGroupStatus(task: Task) {
		var taskGrpStatus = 0
		if (task.taskGroupId != null && task.taskGroupId != 'null') {
			let taskGroup: Task = this.selectedCCItem.taskList.find(x => x.id === task.taskGroupId)
			let ccListStatus: any = this.getTaskListStatus(taskGroup.taskList)

			if (ccListStatus.completeTaskCount == taskGroup.taskList.length) {
				taskGrpStatus = 3
			} else {
				if (ccListStatus.notCompleteTaskCount == taskGroup.taskList.length) {
					taskGrpStatus = 0
				} else {
					taskGrpStatus = 2
				}
			}
		}
		return taskGrpStatus
	}

	private getCoreCompetencyStatus() {
		var ccStatus = 0
		let actualCCTaskList: any = this.getActualTaskList(this.selectedCCItem.taskList)
		let ccListStatus: any = this.getTaskListStatus(actualCCTaskList)

		if (ccListStatus.completeTaskCount == actualCCTaskList.length) {
			ccStatus = 3
		} else {
			if (ccListStatus.notCompleteTaskCount == actualCCTaskList.length) {
				ccStatus = 0
			} else {
				ccStatus = 2
			}
		}
		return ccStatus;
	}

	private isTaskNA(task: Task) {
		var isNA = 0
		let isAllPerformanceNA: boolean = this.checkAllAnswers(task.performanceList, 1)
		let isAllKnowledgeNA: boolean = this.checkAllAnswers(task.knowledgeList, 1)

		if (isAllPerformanceNA && isAllKnowledgeNA) {
			isNA = 1
		} else {
			//do nothing
		}
		return isNA
	}

	private isTaskYes(task: Task) {
		var isYes = 0
		let isAllPerformanceYes: boolean = this.checkAllAnswers(task.performanceList, 3)
		let isAllKnowledgeYes: boolean = this.checkAllAnswers(task.knowledgeList, 3)

		if (isAllPerformanceYes && isAllKnowledgeYes) {
			isYes = 1
		} else {
			//do nothing
		}
		return isYes
	}

	/** update task and sesrver call */
	private updateTask(task: Task) {
		let perListStatus: any = this.getListStatus(task.performanceList);
		let knoListStatus: any = this.getListStatus(task.knowledgeList);

		if (perListStatus.hasAnswerCount == task.performanceList.length) {
			//all performance has answers check knowledge
			if (knoListStatus.hasAnswerCount == task.knowledgeList.length) {
				task.assStatus = 3;
			} else {
				task.assStatus = 2;
			}
		} else {
			//performanceList has items with no answer			
			if (knoListStatus.hasAnswerCount == task.knowledgeList.length || knoListStatus.hasAnswerCount > 0 || perListStatus.hasAnswerCount > 0) {
				task.assStatus = 2;
			} else {
				task.assStatus = 0;
			}
		}

		this.updateTaskToNAByElementsAnswers(task)

		this.assessmentService.updateStatusOfTask(task).subscribe(
			data => {
				// this.alertService.clear();
				// this.alertService.success(data.message);
				this.changeTaskGroupStatus(task)
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	/**
	 * [update corecompetency status]
	 * @param  {CoreCompetency} coreCompetency [description]
	 * @return {[type]}                        [description]
	 */
	private updateCoreCompetency(coreCompetency: CoreCompetency) {
		let actualCCTaskList: any = this.getActualTaskList(coreCompetency.taskList)
		let ccListStatus: any = this.getTaskListStatus(actualCCTaskList)

		if (ccListStatus.completeTaskCount == actualCCTaskList.length) {
			coreCompetency.assStatus = 3
			this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
			this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
		} else {
			if (ccListStatus.notCompleteTaskCount == actualCCTaskList.length) {
				coreCompetency.assStatus = 0
			} else {
				coreCompetency.assStatus = 2
			}
			this.isCCCompleted = false
			this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
		}

		this.assessmentService.updateStatusOfCoreCompetency(coreCompetency).subscribe(
			data => {
				this.alertService.clear()
				// this.alertService.success(data.message)
				this.setDisableForCompleteButton(this.assessmentModel)
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	/**
	 * [cc cclist status]
	 * @param  {any}     ccList [description]
	 * @return {boolean}        [description]
	 */
	public checkCCStatus(ccList: any): boolean {
		let ccCount: number = ccList.length
		let completedCount: number = 0

		let returnVal: boolean = false
		ccList.forEach(ccItem => {
			if (ccItem.assStatus == 3) {
				completedCount++
			}
		});

		if (ccCount != 0) {
			if (ccCount == completedCount) {
				returnVal = true
			} else {
				returnVal = false
			}
		} else {
			returnVal = false
		}

		// console.log('[checkCCStatus] ' + JSON.stringify(completedCount + '/' + ccCount))

		return returnVal
	}

	/**
	 * [get answer count and no answer count of performance/knowledge list]
	 * @param  {any} list [performance/knowledge list]
	 * @return {any}      [list of count ]
	 */
	private getListStatus(list: any): any {
		let noAnswerCount: number = 0
		let hasAnswerCount: number = 0

		list.forEach(item => {
			if (item.answer != 0) {
				hasAnswerCount++
			} else {
				noAnswerCount++
			}
		});

		let returnStatus: any = {
			'noAnswerCount': noAnswerCount,
			'hasAnswerCount': hasAnswerCount
		}
		return returnStatus
	}

	/**
	 * [get complete/notcomplete/inprogress count of task list]
	 * @param  {any} list [task list of core competency]
	 * @return {any}      [list of count]
	 */
	private getTaskListStatus(list: any): any {
		let completeTaskCount: number = 0
		let notCompleteTaskCount: number = 0
		let inProgressTaskCount: number = 0

		list.forEach(item => {
			if (item.assStatus == 3) {
				completeTaskCount++
			} else if (item.assStatus == 2) {
				inProgressTaskCount++
			} else if (item.assStatus == 0) {
				notCompleteTaskCount++
			} else {
				//do nothing
			}
		})

		let returnStatus: any = {
			'completeTaskCount': completeTaskCount,
			'notCompleteTaskCount': notCompleteTaskCount,
			'inProgressTaskCount': inProgressTaskCount
		}
		return returnStatus
	}

	/**
	 * check all answers of the list for the same answer 
	 * @param list 
	 * @return boolean - true if all answers are the same then false
	 */
	private checkAllAnswers(list: any, answer: any): boolean {
		let naAnswerCount: number = 0
		let returnVal: boolean = false

		list.forEach(element => {
			if (element.answer == answer) {
				naAnswerCount++
			}
		});

		if (naAnswerCount == list.length) {
			returnVal = true
		} else {
			returnVal = false
		}

		return returnVal
	}

	private setDisableForCompleteButton(assessmentModel: AssessmentModel) {
		if (assessmentModel.assessorCompleted == 1) {
			this.isCCCompleted = false
			this.isAnsweredCCValid = false
		} else {
			switch (assessmentModel.status) {
				case 'New':
					this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
					this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
					break
				case 'Started':
					this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
					this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
					break
				case 'Completed':
					this.isCCCompleted = false
					this.isAnsweredCCValid = false
					break
				default:
					break
			}
		}
	}
	//</editor-fold> UPDATE ANSWER

	/**
	 * update the note of the performance
	 * @method submitNote
	 * @param  {Performance} performance [description]
	 * @return {[void]}                  [description]
	 */
	private submitNote(performance: Performance) {
		performance.assessmentId = this.assessmentModel.id
		let oldAssessmentNote = performance.assessmentNote
		performance.assessmentNote = this.sanitizer.sanitize(this.currentPerformanceNote)
		this.assessmentService.updatePerformanceNote(performance).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message)

				// this.currentPerformanceNote = performance.assessmentNote
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
				performance.assessmentNote = oldAssessmentNote
			}
		)
	}

	/**
	 * update the note of the knowledge
	 * @method submitKnowledgeNote
	 * @param  {Knowledge} knowledge [description]
	 * @return {[void]}                  [description]
	 */
	private submitKnowledgeNote(knowledge: Knowledge) {
		knowledge.assessmentId = this.assessmentModel.id
		let oldAssessmentNote = knowledge.assessmentNote
		knowledge.assessmentNote = this.sanitizer.sanitize(this.currentKnowledgeNote)
		this.assessmentService.updateKnowledgeNote(knowledge).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message)
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
				knowledge.assessmentNote = oldAssessmentNote
			}
		)
	}

	fileChangeEvent(fileInput: any) {

		this.selectedDocument = null;
		this.addDocumentForm.get('file').setValue(null);
		if (fileInput.target.files.length > 0) {
			let fileName = fileInput.target.files[0].name;
			let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
			let array: Array<string> = ["xlsx", "xls", "doc", "docx", "ppt", "pptx", "txt", "pdf", "jpg", "jpeg", "png", "tiff", "gif", "bmp"];
			// if (!array.includes(ext)) {
			if (!this.isExtExists(array, ext)) {
				this.showError = true;
			} else {
				this.showError = false;
				this.selectedDocument = fileInput.target.files[0];
				this.addDocumentForm.get('file').setValue(fileInput.target.files[0].name);
			}
		} else {
			this.showError = false;
		}
	}

	public isExtExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	private addDocumentsToTask(task: Task, taskIndex: number) {
		this.selectedTask = task;
		this.selectedTaskIndex = taskIndex;
		this.addDocumentModal.open();
	}

	addDocument() {
		this.isLoading = true;
		let assessmentDocument = new AssessmentDocument('', this.selectedTask.jpccId, this.selectedTask.assId, this.addDocumentForm.value.category, this.addDocumentForm.value.file, this.sanitizer.sanitize(this.addDocumentForm.value.note));
		assessmentDocument.assessmentId = this.assessmentModel.id
		this.assessmentService.uploadAssessmentDocument(this.selectedDocument).subscribe(
			data => {
				this.alertService.clear()
				let fileName = data.file.filename;
				let documentId = fileName.substring(0, fileName.lastIndexOf("."));
				assessmentDocument.id = documentId;
				assessmentDocument.ext = fileName.substring(fileName.lastIndexOf(".") + 1);
				this.assessmentService.addDocument(assessmentDocument).subscribe(
					data => {
						this.isLoading = false;
						this.alertService.clear();
						this.alertService.success(data.message);
						this.addDocumentForm.reset();
						this.el.nativeElement.value = "";
						assessmentDocument.fileName = assessmentDocument.fileName.substring(0, assessmentDocument.fileName.lastIndexOf("."));
						//this.selectedCCItem.taskList[this.selectedTaskIndex].documentList.push(assessmentDocument);
						// this.jobProfileModel.coreCompetencyList[this.selectedCCIndex].taskList[this.selectedTaskIndex].documentList.push(assessmentDocument);
						this.selectedTask.documentList.push(assessmentDocument)
					},
					error => {
						this.isLoading = false;
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
				)
			},
			error => {
				this.isLoading = false;
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	private getFileType(fileExtension: string) {
		var mediatype;
		if (fileExtension == "pdf") {
			mediatype = "application/pdf";
		} else if (fileExtension == "docx") {
			mediatype = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
		} else if (fileExtension == "doc") {
			mediatype = "application/msword";
		} else if (fileExtension == "ppt") {
			mediatype = "application/vnd.ms-powerpoint";
		} else if (fileExtension == "pptx") {
			mediatype = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
		} else if (fileExtension == "xls") {
			mediatype = "application/vnd.ms-excel";
		} else if (fileExtension == "xlsx") {
			mediatype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		} else if (fileExtension == "png" || fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "bmp" || fileExtension == "tiff" || fileExtension == "gif") {
			mediatype = "image/" + fileExtension;
		}
		return mediatype;
	}

	fileIconButtonClickEvent(documentId, fileName, fileExt): any {
		var mediaType = this.getFileType(fileExt);
		this.assessmentService.getDocumentContent(documentId, fileExt, mediaType)
			.subscribe(fileData => {
				FileSaver.saveAs(fileData, fileName + "." + fileExt);
			});
	}

	removeDocument(task, document, taskIndex) {
		this.selectedTask = task
		this.selectedTaskIndex = taskIndex;
		document.assessmentId = this.assessmentModel.id
		this.assessmentService.removeDocument(document).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				var tempArray = this.selectedTask.documentList.filter(item => item.id !== document.id);
				// this.jobProfileModel.coreCompetencyList[this.selectedCCIndex].taskList[this.selectedTaskIndex].documentList = tempArray;
				this.selectedTask.documentList = tempArray
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	displayAlertForOpportunity(message: string) {
		this.alertService.clear();
		this.alertService.error(message);
	}

	saveCandidateStrengths() {
		this.assessmentService.updateCandidateStrengths(this.assessmentModel.id, this.sanitizer.sanitize(this.candidateStrengthsForm.value.candidateStrengths)).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message)
				this.assessmentModel.candidateStrengths = this.candidateStrengthsForm.value.candidateStrengths;
				this.showCandidateStrengthPanel = false;
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});
	}

	cancelCandidateStrengths() {
		this.showCandidateStrengthPanel = false;
	}

	//add opportunity for core Competency
	public addOpportunity() {
		let category = null;
		if (this.opportunityForm.get("category").value != null) {
			if (this.opportunityForm.get("category").value.length > 0) {
				category = this.opportunityForm.get("category").value[0].id;
			}
		}
		let opportunityText = this.sanitizer.sanitize(this.opportunityForm.value.opportunityText);
		let opportunityLength = this.assessmentModel.opportunityList.length;
		let orderNo = 1;
		if (opportunityLength > 0) {
			orderNo = this.assessmentModel.opportunityList[opportunityLength - 1].orderNo + 1;
		}
		// let opportunityCategoryId = this.opportunityForm.value.category;
		let opportunityCategoryId = category;
		let opportunityCategoryText = this.ofdCategoryList.find(x => x.id === opportunityCategoryId).text;
		if (this.assessmentModel.opportunityList.find(x => (x.text === opportunityText && x.categoryText === opportunityCategoryText)) != null) {
			this.alertService.clear();
			this.alertService.error(Constants.ERROR_DUPLICAT_OPPORTUNITY);
		}
		else {
			let param: any = {
				assId: this.assessmentModel.id,
				catgoryNo: opportunityCategoryId,
				value: this.sanitizer.sanitize(opportunityText),
				orderNo: orderNo
			};

			this.assessmentService.addOpportunityForAssessment(param).subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					let opportunity: Opportunity = new Opportunity(
						opportunityCategoryText,
						this.categoryId,
						this.sanitizer.sanitize(opportunityText),
						this.assessmentModel.id,
						orderNo
					);
					this.assessmentModel.opportunityList.push(opportunity);
					this.opportunityForm.controls['opportunityText'].setValue('');
					$('#category').val(this.selectedOFDCategory).change();
					this.opportunityForm.reset();
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
		}
	}

	public deleteOpportunity(item: any, i: any) {
		let param: any = {
			assId: this.assessmentModel.id,
			orderNo: item.orderNo
		};

		this.assessmentService.deleteOpportunityOfAssessment(param).subscribe(
			data => {
				this.alertService.clear();
				this.alertService.success(data.message);
				this.assessmentModel.opportunityList.splice(i, 1);
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	public cancelAddOpportunity() {
		this.showofdPanel = !this.showofdPanel
	}

	//get task list without task groups
	private getActualTaskList(taskList: any): any {
		let returnTaskList = []
		taskList.forEach(task => {
			if (task.isTaskGroup) {
				task.taskList.forEach(tgt => {
					returnTaskList.push(tgt)
				});
			} else {
				returnTaskList.push(task)
			}
		});
		return returnTaskList
	}

	private updateTaskToNA(task: any, taskGroup: any, isNA: any) {
		task.assessmentId = this.assessmentModel.id;
		if (isNA) {
			task.assStatus = 3
		} else {
			task.assStatus = 0
		}
		var taskGrpId = null
		var taskGrpStatus = null
		if (task.taskGroupId) {
			taskGrpId = taskGroup.assId
			taskGroup.assStatus = this.getTaskGroupStatus(task)
			taskGrpStatus = taskGroup.assStatus
		}
		this.selectedCCItem.assStatus = this.getCoreCompetencyStatus()
		this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
		this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
		this.isLoading = true

		this.assessmentService.updateTaskItemsIsNA(task, isNA, taskGrpId, taskGrpStatus, this.selectedCCItem.assStatus).subscribe(
			data => {
				this.isLoading = false
				this.alertService.clear();
				this.alertService.success(data.message);
				task.reasonNA = data.defaultReasonNA
				task.isYes = 0
				this.updateTaskElementsAnswers(task, isNA, data.defaultReasonNA)
			},
			error => {
				task.assStatus = 0;
				taskGroup.assStatus = 0;
				this.isAnsweredCCValid = false;
				this.isLoading = false
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	private updateTaskToYes(task: any, taskGroup: any, isYes: any) {
		task.assessmentId = this.assessmentModel.id;
		if (isYes) {
			task.assStatus = 3
		} else {
			task.assStatus = 0
		}
		var taskGrpId = null
		var taskGrpStatus = null
		if (task.taskGroupId) {
			taskGrpId = taskGroup.assId
			taskGroup.assStatus = this.getTaskGroupStatus(task)
			taskGrpStatus = taskGroup.assStatus
		}
		this.selectedCCItem.assStatus = this.getCoreCompetencyStatus()
		this.isCCCompleted = this.checkCCStatus(this.jobProfileModel.coreCompetencyList)
		this.isAnsweredCCValid = this.isAnsweredCCListCorrect()
		this.isLoading = true

		this.assessmentService.updateTaskItemsIsYes(task, isYes, taskGrpId, taskGrpStatus, this.selectedCCItem.assStatus).subscribe(
			data => {
				this.isLoading = false
				this.alertService.clear();
				this.alertService.success(data.message);
				task.reasonNA = null
				var answer = 0
				if (isYes) {
					answer = 3
				}
				this.updateTaskElementsAnswersToYes(task, answer)
			},
			error => {
				task.assStatus = 0;
				taskGroup.assStatus = 0;
				this.isAnsweredCCValid = false;
				this.isLoading = false
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
		)
	}

	//task not applicable button click event
	private taskNAClickEvent(task: Task, taskGroup: Task, ccItem: CoreCompetency) {
		this.selectedTask = task
		this.selectedTaskGroup = taskGroup
		let canUpdateAnswer = this.checkTaskItemsAnswers(task)

		if (task.isNA) {

			task.assessmentId = this.assessmentModel.id;
			this.updateTaskToNA(task, taskGroup, 0)
		} else {
			if (this.allNATasksInCC(task, ccItem)) {
				this.alertService.clear();
				this.alertService.error(Constants.ERROR_ALL_NA_TASKS);
			} else {
				//update answer of the task elements
				//set answer as NA/NA==1
				//this.updateTaskElementsAnswers(task, 1)
				if (canUpdateAnswer) {
					this.updateTaskToNA(task, taskGroup, 1)
				} else {
					this.changeYESBtnEvent = false //modal yes button event
					this.changeAnswersModalBody = Constants.MODAL_BODY_OVERRIDE_ANSWERS
					this.changeAnswersModal.open()
				}
			}
		}
	}

	//check if at least one task in a CC is answered or not to enable the complete assessment button
	private isTaskAnsweredInCC(ccItem: CoreCompetency) {
		let valid = false;
		let totalAnsweredTaskCount: number = 0;
		let totalAnsweredNATaskCount: number = 0;

		ccItem.taskList.forEach(task => {
			if (!task.isTaskGroup) {
				if (task.assStatus == 3) {
					totalAnsweredTaskCount = totalAnsweredTaskCount + 1;
					if (task.isNA) {
						totalAnsweredNATaskCount = totalAnsweredNATaskCount + 1;
					}
				}
			} else {
				task.taskList.forEach(taskElement => {
					if (taskElement.assStatus == 3) {
						totalAnsweredTaskCount = totalAnsweredTaskCount + 1
						if (taskElement.isNA) {
							totalAnsweredNATaskCount = totalAnsweredNATaskCount + 1
						}
					}
				})
			}
		});

		if (totalAnsweredTaskCount > 0 && (totalAnsweredTaskCount - totalAnsweredNATaskCount) > 0) {
			valid = true;
		} else {
			//do nothing
		}
		return valid;
	}


	public isAnsweredCCListCorrect() {
		let valid = true;
		this.jobProfileModel.coreCompetencyList.forEach(CC => {
			if (!this.isTaskAnsweredInCC(CC)) {
				valid = false;
			}
		})
		return valid;
	}

	private taskYesClickEvent(task: Task, taskGroup: Task, ccItem: CoreCompetency) {
		this.selectedTask = task
		this.selectedTaskGroup = taskGroup
		let canUpdateAnswer = this.checkTaskItemsAnswers(task)

		if (task.isYes) {

			task.assessmentId = this.assessmentModel.id;
			this.updateTaskToYes(task, taskGroup, 0)
		} else {

			if (canUpdateAnswer) {
				this.updateTaskToYes(task, taskGroup, 1)
			} else {
				this.changeYESAnswerEvent = false //modal yes button event
				this.changeYesAnswersModalBody = Constants.MODAL_BODY_OVERRIDE_ANSWERS_YES
				this.changeYesAnswersModal.open()
			}

		}
	}

	allNATasksInCC(task: Task, ccItem: CoreCompetency) {
		var result: boolean = false;
		var totalTaskCount: number = 0
		var totalNATaskCount: number = 0;

		ccItem.taskList.forEach(element => {
			if (element.id != task.id) {
				if (element.isTaskGroup) {
					element.taskList.forEach(taskElement => {
						if (taskElement.id != task.id) {
							totalTaskCount = totalTaskCount + 1
							if (taskElement.isNA) {
								totalNATaskCount = totalNATaskCount + 1
							}
						}
					})
				} else {
					totalTaskCount = totalTaskCount + 1
					if (element.isNA == 1) {
						totalNATaskCount = totalNATaskCount + 1
					}
				}
			} else {
				//do nothing
			}
		})
		if (totalTaskCount == totalNATaskCount) {
			result = true
		}
		return result;
	}

	private checkTaskItemsAnswersForYes(task: Task): any {
		let pefrAnswerCount: number = 0
		let pefrCLAnswerCount: number = 0
		let knowAnswerCount: number = 0

		task.performanceList.forEach(element => {
			let perf: Performance = element
			if (perf.answer != 0 && perf.answer != 3) {
				pefrAnswerCount++
			}
			perf.checkList.forEach(element => {
				if (element.answer != 0 && element.answer != 3) {
					pefrCLAnswerCount++
				}
			});
		});

		task.knowledgeList.forEach(element => {
			if (element.answer != 0 && element.answer != 3) {
				knowAnswerCount++
			}
		});
		let countValues = { 'pefrAnswerCount': pefrAnswerCount, 'pefrCLAnswerCount': pefrCLAnswerCount, 'knowAnswerCount': knowAnswerCount }
		let canUpdateAnswer: boolean = false
		if (pefrAnswerCount == 0 && pefrCLAnswerCount == 0 && knowAnswerCount == 0) {
			canUpdateAnswer = true
		} else {
			canUpdateAnswer = false
		}
		return canUpdateAnswer
	}

	private checkTaskItemsAnswers(task: Task): any {
		let pefrAnswerCount: number = 0
		let pefrCLAnswerCount: number = 0
		let knowAnswerCount: number = 0

		task.performanceList.forEach(element => {
			let perf: Performance = element
			if (perf.answer != 0) {
				pefrAnswerCount++
			}
			perf.checkList.forEach(element => {
				if (element.answer != 0) {
					pefrCLAnswerCount++
				}
			});
		});

		task.knowledgeList.forEach(element => {
			if (element.answer != 0) {
				knowAnswerCount++
			}
		});
		let countValues = { 'pefrAnswerCount': pefrAnswerCount, 'pefrCLAnswerCount': pefrCLAnswerCount, 'knowAnswerCount': knowAnswerCount }
		let canUpdateAnswer: boolean = false
		if (pefrAnswerCount == 0 && pefrCLAnswerCount == 0 && knowAnswerCount == 0) {
			canUpdateAnswer = true
		} else {
			canUpdateAnswer = false
		}
		return canUpdateAnswer
	}

	private checkTaskItemsIsNA(task: Task): any {
		let pefrAnswerCount: number = 0
		let knowAnswerCount: number = 0

		task.performanceList.forEach(element => {
			let perf: Performance = element
			if (perf.answer == 1) {
				pefrAnswerCount++
			}
		});

		task.knowledgeList.forEach(element => {
			if (element.answer == 1) {
				knowAnswerCount++
			}
		});

		let canUpdateAnswer: boolean = false
		if (task.performanceList.length != 0 && task.knowledgeList.length == 0) {
			if (pefrAnswerCount == task.performanceList.length) {
				canUpdateAnswer = true
			} else {
				canUpdateAnswer = false
			}

		} else if (task.performanceList.length == 0 && task.knowledgeList.length != 0) {
			if (knowAnswerCount == task.knowledgeList.length) {
				canUpdateAnswer = true
			} else {
				canUpdateAnswer = false
			}

		} else {
			if (pefrAnswerCount == task.performanceList.length && knowAnswerCount == task.knowledgeList.length) {
				canUpdateAnswer = true
			} else {
				canUpdateAnswer = false
			}
		}
		return canUpdateAnswer
	}

	//update all task element answers to N/A
	// private updateTaskElementsAnswers(task: Task, answer: any) {
	// 	task.assessmentId = this.assessmentModel.id
	// 	this.assessmentService.updateAllTaskElementAnswers(task, answer).subscribe(
	// 		data => {
	// 			console.log('NA Reason ' + data.NAReason)
	// 			task.performanceList.forEach(per => {
	// 				per.answer = answer
	// 				per.reasonNA = data.NAReason
	// 				per.checkList.forEach(cl => {
	// 					cl.answer = answer
	// 					cl.reasonNA = data.NAReason
	// 				})
	// 			})
	// 			task.knowledgeList.forEach(kno => {
	// 				kno.answer = answer
	// 				kno.reasonNA = data.NAReason
	// 			})
	// 			task.reasonNA = data.NAReason
	// 			if (answer == 1) {
	// 				task.assStatus = 3
	// 				task.isNA = true
	// 			} else {
	// 				task.assStatus = 0
	// 				task.isNA = false
	// 			}
	// 			console.log(task)
	// 			this.changeTaskGroupStatus(task)
	// 		},
	// 		error => {
	// 			this.alertService.clear();
	// 			let err = error.json();
	// 			this.alertService.handleError(err);
	// 		}
	// 	)
	// }

	private updateTaskElementsAnswers(task: Task, answer: any, reasonNA: any) {
		task.assessmentId = this.assessmentModel.id

		task.performanceList.forEach(per => {
			per.answer = answer
			per.reasonNA = reasonNA
			per.checkList.forEach(cl => {
				cl.answer = answer
				cl.reasonNA = reasonNA
			})
		})
		task.knowledgeList.forEach(kno => {
			kno.answer = answer
			kno.reasonNA = reasonNA
		})
		task.reasonNA = reasonNA
		if (answer == 1) {
			task.assStatus = 3
			task.isNA = true
		} else {
			task.assStatus = 0
			task.isNA = false
		}
		//this.selectedCCItem.assStatus = this.getCoreCompetencyStatus()
		//this.changeTaskGroupStatus(task)

	}

	/*update the answer of all the items under the task to Yes */
	private updateTaskElementsAnswersToYes(task: Task, answer: any) {
		task.assessmentId = this.assessmentModel.id

		task.performanceList.forEach(per => {
			per.answer = answer
			per.reasonNA = null
			per.checkList.forEach(cl => {
				cl.answer = answer
				cl.reasonNA = null
			})
		})
		task.knowledgeList.forEach(kno => {
			kno.answer = answer
			kno.reasonNA = null
		})
		task.reasonNA = null
		if (answer == 3) {
			task.assStatus = 3
			task.isYes = true
			task.isNA = false
		} else {
			task.assStatus = 0
			task.isYes = false
			task.isNA = false
		}
		//this.selectedCCItem.assStatus = this.getCoreCompetencyStatus()
	}

	//reset all items answers of task
	private resetAnswersOfTaskItems() {
		let task: Task = this.selectedTask
		//this.updateTaskElementsAnswers(this.selectedTask, 1)
		this.updateTaskToNA(this.selectedTask, this.selectedTaskGroup, 1)
	}

	//reset all items answers of task
	private resetAnswersOfYesTaskItems() {
		let task: Task = this.selectedTask
		//this.updateTaskElementsAnswers(this.selectedTask, 1)
		this.updateTaskToYes(this.selectedTask, this.selectedTaskGroup, 1)
	}

	//change assStatus of the task group
	private changeTaskGroupStatus(task: Task) {
		if (task.taskGroupId != null && task.taskGroupId != 'null') {
			let taskGroup: Task = this.selectedCCItem.taskList.find(x => x.id === task.taskGroupId)
			let ccListStatus: any = this.getTaskListStatus(taskGroup.taskList)

			if (ccListStatus.completeTaskCount == taskGroup.taskList.length) {
				taskGroup.assStatus = 3
			} else {
				if (ccListStatus.notCompleteTaskCount == taskGroup.taskList.length) {
					taskGroup.assStatus = 0
				} else {
					taskGroup.assStatus = 2
				}
			}

			this.assessmentService.updateStatusOfTask(taskGroup).subscribe(
				data => {
					// this.alertService.clear();
					// this.alertService.success(data.message);
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			);
		}
	}

	//If all the items are set to NA individually, we have to set the task to NA.
	private updateTaskToNAByElementsAnswers(task: Task) {
		let isAllPerformanceNA: boolean = this.checkAllAnswers(task.performanceList, 1)
		let isAllKnowledgeNA: boolean = this.checkAllAnswers(task.knowledgeList, 1)

		if (isAllPerformanceNA && isAllKnowledgeNA) {
			task.assessmentId = this.assessmentModel.id
			this.assessmentService.updateTaskIsNA(task, true).subscribe(
				data => {
					task.isNA = true
					task.reasonNA = data.defaultReasonNA
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
			)
		} else {

		}
	}

	/**
	 * add note button click event - open 
	 * @param performance 
	 */
	public showAddNoteModal(item: any, isPerformance: boolean) {
		if (isPerformance) {
			this.selectedPerformance = item
			this.isPerformance = true
		} else {
			this.selectedKnowledge = item
			this.isPerformance = false
		}
		this.data = []
		this.noteService.getAllNote().subscribe(
			data => {
				for (let i = 0; i < data.noteList.length; i++) {
					if (item.assessmentNote) {
						if (!item.assessmentNote.includes(data.noteList[i].Note)) {
							this.data.push({
								'id': i,
								'noteId': data.noteList[i].Id,
								'note': data.noteList[i].Note,
								'selectNote': '',
								'isSelect': false
							})
						}
					} else {
						this.data.push({
							'id': i,
							'noteId': data.noteList[i].Id,
							'note': data.noteList[i].Note,
							'selectNote': '',
							'isSelect': false
						})
					}
				}
				this.total = this.data.length;
				this.filteredData = this.data;
				this.pageChanged();
				this.addANoteModal.open('lg')
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
			}
		)
	}

	/**
	 * add selected note to selected performance
	 */
	public addNoteToPerformance() {
		let note: string = ''
		this.selectedNoteList.forEach(element => {
			note += element.note + ' '
		});
		if (this.isPerformance) {
			// this.selectedPerformance.assessmentNote += note
			this.currentPerformanceNote = this.currentPerformanceNote += note
		} else {
			// this.selectedKnowledge.assessmentNote += note
			this.currentKnowledgeNote = this.currentKnowledgeNote += note
		}

		this.selectedNote = null
		this.selectedNoteList = []
		this.addANoteModal.dismiss()
		this.isPerformance = false
		this.selectedPerformance = null
		this.selectedKnowledge = null
		// this.alertService.clear();
		// this.alertService.success(Constants.ADD_NOTE)
	}

	/**
	 * filter the table notes
	 */
	filter() {
		this.filteredData = this.data;
		if (this.searchNote != "") {
			this.filteredData = this.filteredData.filter((item: any) => {
				return item["note"].match(new RegExp(this.searchNote, "i"));
			});
		}
		this.pageChanged(1);
	}

	filterReasons() {
		this.filteredReasonData = this.reasonList;
		if (this.searchReason != "") {
			this.filteredReasonData = this.filteredReasonData.filter((item: any) => {
				return item["reason"].match(new RegExp(this.searchReason, "i"));
			});
		}
		this.reasonPageChanged(1);
	}

	/**
	 * page change event in pegination in the table
	 * @param page 
	 */
	pageChanged(page?: number): void {
		if (page) { this.currentPage = page; }
		let end = this.itemsPerPage * this.currentPage;
		let start = end - this.itemsPerPage;
		this.paginatedResults = this.filteredData.slice(start, end);
		this.numPages = Math.ceil(this.filteredData.length / this.itemsPerPage);
		this.numPages = this.numPages == 0 ? 1 : this.numPages;
		this.total = this.filteredData.length;
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * page change event in pegination in the reason for N/A table
	 * @param page 
	 */
	reasonPageChanged(page?: number): void {
		if (page) { this.currentPage1 = page; }
		let end = this.itemsPerPage1 * this.currentPage1;
		let start = end - this.itemsPerPage1;
		this.reasonNAResults = this.filteredReasonData.slice(start, end);
		this.numPages1 = Math.ceil(this.filteredReasonData.length / this.itemsPerPage);
		this.numPages1 = this.numPages1 == 0 ? 1 : this.numPages1;
		this.total1 = this.filteredReasonData.length;
		this.detectorRef.detectChanges();
	}

	/**
	 * click event of the check box in table
	 * @param e 
	 * @param i 
	 */
	setSelected(e, i) {
		let objIndex = this.data.findIndex((obj => obj.id == this.paginatedResults[i].id));
		this.selectedNote = this.data[objIndex];
		// console.log('[setSelected] (this.selectedNote) ' + JSON.stringify(this.selectedNote));
		if (e.target.checked) {
			this.selectedNote.isSelect = true
			this.selectedNoteList.push(this.selectedNote)
		} else {
			this.selectedNote.isSelect = false
			this.selectedNoteList = this.selectedNoteList.filter(item => item.id !== this.selectedNote.id)
		}
		// console.log('[setSelected] (this.selectedNoteList) ' + JSON.stringify(this.selectedNoteList));
	}

	setReasonSelected(e, i) {
		if (e.target.checked) {
			let objIndex = this.reasonList.findIndex((obj => obj.id == this.reasonNAResults[i].id));
			this.selectedReason = this.reasonList[objIndex];
		} else {
			this.selectedReason = null
		}
		this.reasonList.forEach(obj => {
			if (e.target.checked) {
				if (obj.id != this.reasonNAResults[i].id) {
					obj.isSelect = false
				} else {
					obj.isSelect = true
				}
			} else {
				obj.isSelect = false
			}
		})
	}

	private loadAllReasons() {
		this.data = [];
		// this.isLoading = true
		this.naReasonService.getAllNAReasons()
			.subscribe(
			data => {
				// this.isLoading = false
				this.reasonList = []
				for (let i = 0; i < data.reasonList.length; i++) {
					if (this.selectedReasonNA != data.reasonList[i].Reason) {
						this.reasonList.push({
							id: data.reasonList[i].Id,
							reason: data.reasonList[i].Reason,
							defaultNA: data.reasonList[i].NADefault,
							isSelect: false
						});
					}
				}
				this.filteredReasonData = this.reasonList
				this.reasonPageChanged(1)
				this.total1 = this.filteredReasonData.length
				this.addReasonForNAModal.open('lg')
			},
			error => {
				let err = error.json();
				this.alertService.clear();
				this.alertService.handleError(err);
				// this.isLoading = false
			}
			);
	}

}
