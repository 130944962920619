import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { Task } from './task.model';
import { TaskService } from './task.service';
import { AlertService } from '../../util/alert/alert.service';
import { SharedService } from '../../home/shared.service';
import { UserService } from '../../user/user.service';

import { TaskTagService } from '../../tag/tasktag/tasktag.service';
import { TagInputModule } from 'ngx-chips';
import { NoWhitespaceDirective } from "../../util/validator/nowhitespace.directive"
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-edittask',
	templateUrl: './edittask.component.html'
})
export class EditTaskComponent implements OnInit {

	public editTask: Task;
	public taskStatus: boolean;
	editTaskForm: FormGroup;

	public selectedTags = [];
	public activeTagList = [];
	public items = [];

	constructor(private sharedService: SharedService,
		private alertService: AlertService,
		private taskService: TaskService,
		private taskTagService: TaskTagService,
		private userService: UserService,
		private sanitizer: SanitizationService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
		this.editTask = taskService.getSelectedTaskForEdit();
		this.editTask.tagList.forEach(tag => {
			let item = { value: tag.value, id: tag.id }
			this.items.push(item)
		});
		this.taskStatus = this.editTask.status;
	}

	ngOnInit() {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.

		this.loadActiveTaskTags()
		this.editTaskForm = new FormGroup({
			id: new FormControl(this.editTask.id, Validators.required),
			task: new FormControl(this.editTask.text, Validators.required),
			taskTag: new FormControl('')
			// taskStatus : new FormControl(null, Validators.required)
		});
	}

	//navigate to all task component
	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	onSubmit() {
		if (this.checkNeedtoUpdateTask()) {
			let tempTask = new Task(this.editTask.id, this.sanitizer.sanitize(this.editTaskForm.value.task));
			tempTask.tagList = this.items;
			//console.log('[tempTask] ' + JSON.stringify(tempTask).toString());
			this.taskService.updateTaskByTaskId(tempTask)
				.subscribe(
				data => {
					this.alertService.clear();
					this.alertService.success(data.message);
					this.editTask.text = this.editTaskForm.value.task;
					// this.editTask.taskStatus = this.editTaskForm.value.taskStatus;
					this.editTask.tagList = [];
					this.items.forEach(tag => {
						let item = { value: tag.value, id: tag.id }
						this.editTask.tagList.push(item)
					});
				},
				error => {
					this.alertService.clear();
					let err = error.json();
					this.alertService.handleError(err);
				}
				);

		} else {
			//no need to update the task no changes
			this.alertService.clear();
			this.alertService.error('There are no changes to update.')
		}

	}

	checkNeedtoUpdateTask(): boolean {
		if (this.editTask.text != this.editTaskForm.value.task) {
			return true;
		} else if (this.compairArrayForChanges(this.editTask.tagList, this.items)) {
			return true;
			//} else if(this.editTask.taskStatus != this.editTaskForm.value.taskStatus) {
			//     return true;
		} else {
			return false;
		}
	}

	public compairArrayForChanges(arrayOld: any, arrayNew: any): boolean {
		//console.log('[compairArrayForChanges] (tagList)' + JSON.stringify(this.editTask.tagList).toString());
		let haveChanges: boolean = false;
		if (arrayOld.length != arrayNew.length) {
			haveChanges = true
		} else {
			let missing = []
			arrayNew.forEach(newItem => {
				if (!(this.isValueExists(arrayOld, newItem.value))) {
					missing.push(newItem)
				}
			});
			if (missing.length == 0) {
				haveChanges = false
			} else {
				haveChanges = true
			}
		}
		return haveChanges;
	}

	public isValueExists(list: any[], value: string): boolean {
		var isExists = false;
		list.forEach(a => {
			if (a.value == value) {
				isExists = true;
			}
		});
		return isExists;
	}

	public loadActiveTaskTags() {
		this.taskTagService.getActiveTaskTags()
			.subscribe(
			data => {
				//console.log('[loadActiveTaskTags] ' + JSON.stringify(data).toString());
				this.activeTagList = []
				data.taskTagList.forEach(element => {
					let item = { value: element.Description, id: element.Id }
					this.activeTagList.push(item);
				});
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			);
	}

	public addTags(event: any) {
		//console.log('[addTags]' + JSON.stringify(event).toString());
		this.items.push(event);
	};

	public removeTags(event: any) {
		// console.log('[removeTags](items be)' + JSON.stringify(this.items).toString());
		// console.log('[removeTags]' + JSON.stringify(event).toString());
		// console.log('[removeTags](items after)' + JSON.stringify(this.items).toString());
		this.items = this.items.filter(tag => tag.id !== event.id);
	};

}
