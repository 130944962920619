import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router'
import { UserService } from '../../user/user.service';
import { AlertService } from '../../util/alert/alert.service';
import { AssessmentService } from '../assessment.service';
import { RemediationService } from '../../complib/remediation/remediation.service';
import { CoreCompetency } from '../../complib/corecompetency/corecompetency.model';
import { Task } from '../../complib/task/task.model';
import { Knowledge } from '../../complib/knowledge/knowledge.model';
import { OrderArrayByType } from '../../util/orderarraybytype.pipe';
import * as FileSaver from "file-saver";
import { WidgetService } from '../../dashboard/widget.service';
import { SharedService } from "../../home/shared.service";
import { Constants } from '../../util/const/constants'
import { environment } from '../../../environments/environment';
import { CounterPipe } from '../../util/counter.pipe';
import { RegulatoryAgencyService } from '../../complib/regulatoryagency/regulatoryagency.service';
import { ReportService } from "../../report/report.service";
import { SanitizationService } from '../../sanitizer.service';
@Component({
	selector: 'app-selfassessment-review',
	templateUrl: './selfassessmentreview.component.html',
	styleUrls: ['./selfassessmentreview.component.scss', '../../home/header/header.component.scss',
		'../../report/remediationreport/assessmentremediationreport.component.scss']
})

export class SelfAssessmentReview implements OnInit {
	public constants: any
	public assessmentModel: any = null;
	public ccList: any = [];
	public showCCDetail: string = '';
	public showTaskDetail: string = '';
	public showKnowledgeDetail: string = '';
	public showRemediationDetail: string = '';
	public showTaskRemediationDetail: string = '';
	public showKnowledgeRemediationDetail: string = '';
	public remediationAttachmentList: any = [];
	public selectedTaskRemediationAttachment: any = [];
	public selectedKnowledgeRemediationAttachment: any = [];
	public showKnowledge: boolean = false;
	public selectedTask: Task;
	public selectedCC: CoreCompetency;
	public isCCCompleted: boolean = false;
	public selectedUserRole = [];
	public stortedSelectedUserRole = localStorage.getItem('selectedUserRole');
	public title: string = '';
	public isLoading: boolean = false
	public total: any = null;
	public correct: any = null;
	public allQuestions: boolean = false
	public missedQuestions: boolean = true
	public correctQuestions: boolean = false
	public data: any = null
	public expandAll: boolean = false
	public allCorrect: boolean = false
	public allIncorrect: boolean = false
	public visualImagesPath: string = environment.server_URL + '/images/knowledge/'
	public allClicked: boolean = true
	public regAgencyList: Array<any> = [];
	selectedItems = [];
	noResults: boolean = false
	placeholderText: any = ''
	public expandAllTasks: boolean = false
	public regShortList: Array<any> = []
	public accepted: boolean = false

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private remediationService: RemediationService,
		private assessmentService: AssessmentService, private alertService: AlertService, private widgetService: WidgetService, private pipe: OrderArrayByType,
		private sharedService: SharedService, private counterPipe: CounterPipe, private regulatoryAgencyService: RegulatoryAgencyService,
		private reportService: ReportService,private sanitizer: SanitizationService) {
		this.assessmentModel = this.assessmentService.getSelectedAssessmentModel();
		this.constants = Constants
		if (!userService.alreadyLoggedIn()) {
			this.router.navigateByUrl('/user/login');
		} else {
			if (this.selectedUserRole) {
				this.selectedUserRole = JSON.parse(this.stortedSelectedUserRole);
				if (this.selectedUserRole[0][1] != 'Candidate') {
					if (this.assessmentModel.userName) {
						this.title = this.assessmentModel.userName + "'s "
					}
					this.title += Constants.SELF_ASSESSMENT_REVIEW;
				}
				else {
					this.title = Constants.MY_SELF_ASSESSMENT_REVIEW;
				}
			}
			if (this.assessmentModel.id != null) {
				this.createJobProfileHierarchy();
				this.loadAllRegulatoryAgencies()
			}
		}


	}

	ngOnInit() {
	}

	loadAllRegulatoryAgencies() {
		this.regulatoryAgencyService.getAllRegulatoryAgency()
			.subscribe(
			data => {
				this.regAgencyList = [];
				this.regShortList = []
				for (var i = 0; i < data.regulatoryAgencyList.length; i++) {
					this.regShortList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].ShortName })
					this.regAgencyList.push({ id: data.regulatoryAgencyList[i].Id, text: data.regulatoryAgencyList[i].Description + " - " + data.regulatoryAgencyList[i].ShortName });
				}
				if (this.regAgencyList.length > 0) {
					this.placeholderText = 'Select regulatory agencies'
				} else {
					this.placeholderText = 'No items available'
				}
			},
			error => {
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			});

	}

	public showCCHierarchy() {
		this.ccList = this.generateCCListFromData(this.data);
		this.ccList = this.pipe.transform(this.ccList, 'orderNum')
		if (this.ccList.length > 0) {
			this.noResults = false
		} else {
			this.noResults = true
		}
		this.updateCCStatus();
		this.showKnowledge = false;
		this.selectedCC = null;
		this.selectedTask = null;
	}

	private createJobProfileHierarchy() {
		this.isLoading = true
		let param: any = {
			'assessmentId': this.assessmentModel.id,
			'includeComplete': true   //to get completed assessment data
		}
		this.assessmentService.getSelfAssessmentJobProfileHierarchy(param)
			.subscribe(
			data => {
				this.alertService.clear()
				this.data = data;
				this.assessmentService.getSelfAssessmentScore(this.assessmentModel.id)
					.subscribe(
					data => {
						this.isLoading = false
						this.total = data.total;
						this.correct = data.correct
						if (this.total && this.correct) {
							if (this.total - this.correct == 0) {
								this.allCorrect = true
							} else {
								this.allCorrect = false
							}
							if (this.correct == 0) {
								this.allIncorrect = true
							} else {
								this.allIncorrect = false
							}
						}
						this.showMissedQuestions()
					},
					error => {
						this.isLoading = false
						this.alertService.clear();
						let err = error.json();
						this.alertService.handleError(err);
					}
					)
			},
			error => {
				this.isLoading = false
				this.alertService.clear();
				let err = error.json();
				this.alertService.handleError(err);
			}
			)
	}

	expandAllKQs(e) {
		if (e.target.checked) {
			this.expandAll = true;
			this.expandAllTasks = false;
		} else {
			this.expandAll = false;
		}
	}

	showAll() {
		this.isLoading = true
		this.allQuestions = true
		this.missedQuestions = false
		this.correctQuestions = false
		//this.expandAll = false		
		this.showCCHierarchy()
		this.isLoading = false
	}

	showMissedQuestions() {
		this.isLoading = true
		this.allQuestions = false
		this.missedQuestions = true
		this.correctQuestions = false
		//this.expandAll = false		
		//this.selectedItems = []		
		this.showCCHierarchy()
		this.isLoading = false
	}

	showCorrectQuestions() {
		this.isLoading = true
		this.allQuestions = false
		this.missedQuestions = false
		this.correctQuestions = true
		//this.expandAll = false
		//this.selectedItems = []		
		this.showCCHierarchy()
		this.isLoading = false
	}

	public filterTasks(items: any) {
		this.isLoading = true
		this.selectedItems = items
		this.showCCHierarchy()
		this.isLoading = false
	}

	private isValidRegAgencyConnectedTask(regList: any): boolean {
		let found: boolean = true
		if (this.selectedItems.length > 0) {
			if (regList.length > 0) {
				this.selectedItems.forEach(element => {
					if (regList.filter(item => {
						if (item.RegulatoryAgencyId == element.id) {
							return true
						} else {
							return false
						}
					}).length == 0) {
						found = false
					}
				});
			} else {
				found = false
			}
		}
		return found
	}

	private updateCCStatus() {
		this.ccList = this.ccList.filter(item => item.taskList.length > 0)
		for (let i = 0; i < this.ccList.length; i++) {
			let ccItem = this.ccList[i];

			if (ccItem.taskList.filter(item => item.assStatus == 0).length == 0) {
				ccItem.assStatus = 1;
			} else {
				ccItem.assStatus = 0;
			}
		}
		if (this.ccList.filter(item => item.assStatus == 0).length == 0) {
			this.isCCCompleted = true;
		}
	}

	private generateCCListFromData(data: any): any {
		let corecompetencyList = [];

		for (let i = 0; i < data.result.length; i++) {
			let cci = data.result[i].ccItem
			let tl = data.result[i].taskList.filter(item => item.isTaskGroup == 0);
			let kl = data.result[i].knowledgeList;
			let knl = data.result[i].knowledgeAnswerList;
			let trml = data.result[i].taskRemediationList;
			let krml = data.result[i].knowledgeRemediationList;
			let rel = data.result[i].regulatoryAgencyList;
			let kml = this.generateKnowledgeListModel(kl, knl, krml);
			let tml = this.generateTaskListModel(rel, tl, kml, trml);
			if (tml.length > 0) {
				let ccItem: CoreCompetency = new CoreCompetency(cci.id, cci.text, true, tml);
				ccItem.orderNum = cci.orderNum
				ccItem.assId = cci.ACCId
				corecompetencyList.push(ccItem)
			}
		}
		return corecompetencyList;
	}

	private generateKnowledgeListModel(knowledgeList: any, knowledgeAnswerList: any, knowledgeRemediationList): any {
		let knowledgeModelList = []
		for (let i = 0; i < knowledgeList.length; i++) {
			let kModel: Knowledge = new Knowledge(knowledgeList[i].text, 1, knowledgeList[i].id)
			if ((knowledgeList[i].answer == 2 && this.missedQuestions) || (knowledgeList[i].answer == 3 && this.correctQuestions) || this.allQuestions) {
				kModel.answerList = knowledgeAnswerList.filter(item => item.AKId === knowledgeList[i].AKId)
				kModel.remediationList = knowledgeRemediationList.filter(item => item.AKId === knowledgeList[i].AKId);
				kModel.jptId = knowledgeList[i].ATId
				kModel.orderNum = knowledgeList[i].orderNum
				kModel.answer = knowledgeList[i].answer
				kModel.assId = knowledgeList[i].AKId
				kModel.type = knowledgeList[i].Type
				kModel.booleanAnswerCorrect = knowledgeList[i].IsCorrect
				kModel.isImageAttached = knowledgeList[i].ImageAttached
				kModel.fileType = knowledgeList[i].FileType
				knowledgeModelList.push(kModel)
			}
		}
		return knowledgeModelList;
	}

	private generateTaskListModel(regList: any, taskList: any, knoList: any, taskRemediationList: any) {
		let taskModelList = []
		for (let i = 0; i < taskList.length; i++) {
			let taskModel: Task = new Task(taskList[i].id, taskList[i].text)
			taskModel.knowledgeList = knoList.filter(item => item.jptId === taskList[i].ATId)
			taskModel.regulatoryList = regList.filter(item => item.ATId === taskList[i].ATId)
			if (taskModel.knowledgeList.length > 0) {
				taskModel.remediationList = taskRemediationList.filter(item => item.ATId === taskList[i].ATId)
				taskModel.jpccId = taskList[i].ACCId
				taskModel.orderNum = taskList[i].orderNum
				taskModel.assId = taskList[i].ATId
				taskModel.criticalityValue = taskList[i].criticality
				taskModel.isNA = taskList[i].isNA
				taskModel.reasonNA = taskList[i].reasonNA
				taskModel.assessmentScore = taskList[i].assessmentScore == null ? 0 : +taskList[i].assessmentScore.toFixed(0);
				taskModel.regulatoryList = regList.filter(item => item.ATId === taskList[i].ATId)
				taskModel.knowledgeQuestionList = knoList.filter(item => item.jptId === taskList[i].ATId).filter(item => item.isTestQuestion == 1);
				if (this.isValidRegAgencyConnectedTask(taskModel.regulatoryList)) {
					taskModelList.push(taskModel);
				}
				if (taskModel.knowledgeList.filter(item => item.answer == 0).length == 0) {
					taskModel.assStatus = 1
				} else {
					taskModel.assStatus = 0
				}
			}
		}
		taskModelList = this.pipe.transform(taskModelList, 'orderNum')
		return taskModelList;
	}

	public showCollapsePanel(itemId: any, itemType: string, remediationId: any) {
		switch (itemType) {
			case 'coreCompetency':
				this.showCCDetail = this.changeVarableToShowHideItem(this.showCCDetail, itemId);
				this.showTaskDetail = '';
				this.showKnowledge = false;
				break;
			case 'task':
				this.showTaskDetail = this.changeVarableToShowHideItem(this.showTaskDetail, itemId);
			case 'taskRemediation':
				this.showRemediationDetail = this.changeVarableToShowHideItem(this.showRemediationDetail, itemId);
			case 'knowledgeRemediation':
				this.showKnowledgeDetail = this.changeVarableToShowHideItem(this.showKnowledgeDetail, itemId)
				this.showKnowledgeRemediationDetail = '';
				break;
			case 'taskRemediationDetail':
				this.selectedTaskRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedTaskRemediationAttachment == null || this.selectedTaskRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedTaskRemediationAttachment);
				}
				else if (this.selectedTaskRemediationAttachment.length == 1 && this.selectedTaskRemediationAttachment[0].id == '') {
					this.selectedTaskRemediationAttachment = [];
				}
				this.showTaskRemediationDetail = this.changeVarableToShowHideItem(this.showTaskRemediationDetail, itemId)
				break;
			case 'knowledgeRemediationDetail':
				this.selectedKnowledgeRemediationAttachment = this.remediationAttachmentList.filter(x => x.remediationId == remediationId);
				if (this.selectedKnowledgeRemediationAttachment == null || this.selectedKnowledgeRemediationAttachment.length == 0) {
					this.getRemediationAttachmentsByRemediationId(remediationId, this.selectedKnowledgeRemediationAttachment);
				}
				else if (this.selectedKnowledgeRemediationAttachment.length == 1 && this.selectedKnowledgeRemediationAttachment[0].id == '') {
					this.selectedKnowledgeRemediationAttachment = [];
				}
				this.showKnowledgeRemediationDetail = this.changeVarableToShowHideItem(this.showKnowledgeRemediationDetail, itemId)
				break;
			default:
				break;
		}
	}

	private selectTask(ccItem: CoreCompetency, task: Task) {
		this.selectedTask = task;
		this.showKnowledge = true;
		this.selectedCC = ccItem;
		return false;
	}

	private changeVarableToShowHideItem(val: string, text: string): string {
		if (val != text) {
			val = text;
		} else {
			val = '';
		}

		return val;
	}

	private getRemediationAttachmentsByRemediationId(remediationId: any, attachments: any) {
		this.remediationService.getRemediationAttachmentsByRemediationId(remediationId).subscribe(
			data => {
				if (data.remediationAttachmentList.length > 0) {
					for (var i = 0; i < data.remediationAttachmentList.length; i++) {
						var fileExtension = data.remediationAttachmentList[i].FileName.substr((data.remediationAttachmentList[i].FileName.lastIndexOf('.') + 1));
						attachments.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
						this.remediationAttachmentList.push({ remediationId: remediationId, id: data.remediationAttachmentList[i].Id, text: fileExtension, fileName: data.remediationAttachmentList[i].FileName });
					}
				}
				else {
					this.remediationAttachmentList.push({ remediationId: remediationId, id: '', text: '', fileName: '' });
				}

			},
			error => {
				this.alertService.clear();
				const err = error.json();
				this.alertService.handleError(err);
			}
		);
	}

	public fileIconButtonClickEvent(fileName, attachmentId, itemType): any {
		var fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
		var mediaType = this.remediationService.getFileType(fileExtension);
		this.remediationService.getFileContent(fileName, attachmentId, mediaType)
			.subscribe(fileData => {
				FileSaver.saveAs(fileData, fileName);
			});
	}

	printTaskRegulatoryAgencies(task: any, paddingLeft: any) {
		var regList = this.pipe.transform(task.regulatoryList, 'alphabetic')
		var reportHTML = '<div style="padding:0px 5px 15px 5px;margin-left:' + paddingLeft + 'px">'
		for (var i = 0; i < regList.length; i++) {
			reportHTML += '<span style="padding: 1px 1px 1px 1px;-webkit-print-color-adjust: exact !important;background-color:#563F7A !important;color: #fff !important;display: inline-block;margin-bottom:3px;font-size: 0.8rem;">' + regList[i].ShortName + '</span>&nbsp;'
		}
		reportHTML += '</div>'
		return reportHTML
	}

	loadPrintHTML() {
		let reportHTML = '<h1>' + this.assessmentModel.userName + "'s Knowledge Check Review "
		if (this.missedQuestions) {
			reportHTML += '- Missed Questions'
		} else if (this.correctQuestions) {
			reportHTML += '- Correct Questions'
		}
		reportHTML += '</h1>'
		reportHTML += '<div>Knowledge Check Completed Date - ' + this.assessmentModel.selfAssessmentCompletedDate.toLocaleDateString() + '</div>'
		reportHTML += '<div>Knowledge Check Score - ' + this.correct + ' out of ' + this.total + ' is correct</div>'
		if (this.selectedItems.length > 0) {
			var items = ''
			this.selectedItems.forEach(item => {
				if (items) {
					items += ", "
				}
				items += this.regShortList.find(reg => reg.id == item.id).text
			})
			reportHTML += '<div style="padding:10px 0px 0px 0px;">Regulatory Agency(s): ' + items + '</div>'
		}

		var count = 0
		for (var i = 0; i < this.ccList.length; i++) {
			var ccItem = this.ccList[i]
			if (!this.expandAll) {
				reportHTML += '<div style="padding:0px 5px 0px 0px;"><h2><u>' + ccItem.text + '</u></h2></div>'
			}
			for (var j = 0; j < ccItem.taskList.length; j++) {
				var task = ccItem.taskList[j]
				if (!this.expandAll) {
					reportHTML += '<div style="padding:0px 5px 0px 5px;margin-left:20px"><h3 '
					if (task.regulatoryList.length > 0) {
						reportHTML += 'style="margin-block-end: 0.2em;" '
					}
					reportHTML += '>' + task.text + '</h3></div>'
					if (task.regulatoryList.length > 0) {
						reportHTML += this.printTaskRegulatoryAgencies(task, 20)
					}
				}
				for (var k = 0; k < task.knowledgeList.length; k++) {
					count += 1
					var knowledge = task.knowledgeList[k]
					var colorCode = "#d9534f"
					if (knowledge.answer == 3) {
						colorCode = "#5cb85c"
					}
					var mLeft = "margin-left:40px;"
					if (this.expandAll) {
						mLeft = ""
					}
					var mTop = ""
					if (this.expandAll && k == 0) {
						mTop = "margin-top:20px;"
					}
					reportHTML += '<div style="padding:5px;' + mLeft + mTop + 'border-style:solid;border-color:' + colorCode + ';border-width:1px;border-radius:0.25rem;" >&nbsp;&nbsp;'
					if (!this.expandAll) {
						reportHTML += (k + 1) + '.&nbsp;'
					} else {
						reportHTML += count + '.&nbsp;'
					}
					reportHTML += knowledge.knowledge
					if (knowledge.isImageAttached === 1) {
						var imageURL = this.getImageURL(knowledge.id, knowledge.id, knowledge.fileType)
						reportHTML += '<div style="margin-top: 0.5rem;margin-bottom: 0.5rem"><img src="' + imageURL + '" style="max-height: 250px;max-width: 80%;height: auto;margin-left: 0.25rem;"></div>'
					}

					if (knowledge.type == 'Boolean') {
						var contentTrue = ''
						if ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)) {
							contentTrue = 'checked'
						}
						var bgCode = ''
						if (knowledge.booleanAnswerCorrect == 1) {
							bgCode = "#d4edda"
						}
						if (knowledge.booleanAnswerCorrect == 0 && knowledge.answer === 2) {
							bgCode = "#f08080"
						}
						reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="padding:0.25rem;line-height:1.5;-webkit-print-color-adjust: exact !important;background-color:' + bgCode + ' !important"><input type="radio" ' + contentTrue + '></span> True</div>'
						var contentFalse = ''
						if ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)) {
							contentFalse = 'checked'
						}
						var bgCodeFalse = ''
						if (knowledge.booleanAnswerCorrect == 0) {
							bgCodeFalse = "#d4edda"
						}
						if (knowledge.booleanAnswerCorrect == 1 && knowledge.answer === 2) {
							bgCodeFalse = "#f08080"
						}
						reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="padding:0.25rem;line-height:1.5;-webkit-print-color-adjust: exact !important;background-color:' + bgCodeFalse + ' !important"><input type="radio" ' + contentFalse + '></span> False</div>'
					}
					for (var m = 0; m < knowledge.answerList.length; m++) {
						var knowledgeAnswer = knowledge.answerList[m]
						var ansCode = ''
						if (knowledgeAnswer.IsCorrect == 1) {
							ansCode = "#d4edda"
						} else if (knowledgeAnswer.IsCorrect == 0 && knowledgeAnswer.GivenAnswer == 1) {
							ansCode = "#f08080"
						}
						var checked = ''
						if (knowledgeAnswer.GivenAnswer) {
							checked = "checked"
						}
						if (knowledge.type == 'Single') {
							reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="vertical-align:top;padding:0.25rem;line-height:1.5;height: 8mm;-webkit-print-color-adjust: exact !important;background-color:' + ansCode + ' !important"><input style="vertical-align:top" type="radio" ' + checked + '></span> '
							if (knowledgeAnswer.text) {
								reportHTML += '<span class="m-0 mt-1 ml-2 float-right">' + knowledgeAnswer.text + '</span>'
							}
							if (knowledgeAnswer.FileType) {
								var imageURL = this.getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)
								reportHTML += '<img src="' + imageURL + '" style="max-height: 250px;max-width: 80%;height: auto;margin-left: 0.25rem;">'
							}
							reportHTML += '</div>'
						}
						if (knowledge.type == 'Multiple') {
							reportHTML += '<div style="padding:3px;margin-left:20px;margin-top:5px;"><span style="vertical-align:top;padding:0.25rem;line-height:1.5;height: 8mm;-webkit-print-color-adjust: exact !important;background-color:' + ansCode + ' !important"><input style="vertical-align:top" type="checkbox" ' + checked + '></span> '
							if (knowledgeAnswer.text) {
								reportHTML += '<span class="m-0 mt-1 ml-2 float-right">' + knowledgeAnswer.text + '</span>'
							}
							if (knowledgeAnswer.FileType) {
								var imageURL = this.getImageURL(knowledge.id, knowledgeAnswer.id, knowledgeAnswer.FileType)
								reportHTML += '<img src="' + imageURL + '" style="max-height: 250px;max-width: 80%;height: auto;margin-left: 0.25rem;">'
							}
							reportHTML += '</div>'
						}
					}
					reportHTML += '</div><br>'

				}
			}
			if (!this.expandAll) {
				reportHTML += '<br>'
			}
		}
		return reportHTML
	}

	print() {
		let popupWinindow;
		let content = this.loadPrintHTML();		
		popupWinindow = window.open(
			"",
			"_blank",
			"width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
		);
		popupWinindow.document.open();
		popupWinindow.document.write(
			'<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head><body onload="window.print()">' +
			content +
			"</html>"
		);
		popupWinindow.document.close();
	}

	public getImageURL(knowledgeId: any, answerId: any, fileType: any) {
		return this.visualImagesPath + "/" + knowledgeId + "/" + answerId + "." + fileType
	}

	showAllRegulatoryAgency() {
		this.allClicked = true
		this.isLoading = true
		this.allQuestions = false
		this.missedQuestions = true
		this.correctQuestions = false
		//this.expandAll = false
		//this.accepted = false
		this.selectedItems = []
		this.showCCHierarchy()
		this.isLoading = false
	}

	showRegulatoryAgency() {
		this.allClicked = false
		this.isLoading = true
		if (this.correctQuestions) {
			this.allQuestions = false
			this.missedQuestions = false
			this.correctQuestions = true
		} else if (this.missedQuestions) {
			this.allQuestions = false
			this.missedQuestions = true
			this.correctQuestions = false
		} else if (this.allQuestions) {
			this.allQuestions = true
			this.missedQuestions = false
			this.correctQuestions = false
		} else {

		}
		//this.expandAll = false
		//this.accepted = false
		this.showCCHierarchy()
		this.isLoading = false
		this.selectedItems = []
	}

	expandAllTasksInAssessment() {
		this.clear()
		this.accepted = false
		this.expandAll = false
		this.expandAllTasks = !this.expandAllTasks
	}

	clear() {
		this.showCCDetail = null
		this.showTaskDetail = null
		this.showTaskRemediationDetail = '';
	}

	public formatDate(date) {
		date = date.split("/");
		var year = date[2]
		year = year.substring(2);
		var month = date[0];
		var day = date[1];

		if (month.length == 1) {
			month = "0" + month;
		}

		if (day.length == 1) {
			day = "0" + day;
		}

		var Date = month + day + year
		return Date;
	}

	exportToExcel() {
		var data = []
		var outputName = this.assessmentModel.userName + '\'s Knowledge Check Review_' + this.formatDate(this.assessmentModel.selfAssessmentCompletedDate.toLocaleDateString())
		for (var i = 0; i < this.ccList.length; i++) {
			var cc = this.ccList[i]
			cc.taskList = this.pipe.transform(cc.taskList, 'orderNum')
			for (var j = 0; j < cc.taskList.length; j++) {
				var task = cc.taskList[j]
				data = data.concat(this.getTaskContent(cc, task))
			}
		}
		this.reportService.exportAsExcelFile(data, outputName);
	}

	private getTaskContent(cc: any, task: any) {
		var items = ''
		task.regulatoryList.forEach(item => {
			if (items) {
				items += ", "
			}
			items += item.ShortName
		})
		let data = []
		task.knowledgeQuestionList = this.pipe.transform(task.knowledgeQuestionList, 'orderNum')
		for (var l = 0; l < task.knowledgeQuestionList.length; l++) {
			var knowledge = task.knowledgeQuestionList[l]
			var answerList = []
			if (knowledge.type == 'Boolean') {
				answerList.push({ 'text': 'True', 'IsCorrect': knowledge.booleanAnswerCorrect == 1 ? 1 : 0, 'GivenAnswer': ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 1) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 0)) ? 1 : 0, 'IsGivenAnswerCorrect': knowledge.answer == 3 ? 1 : 0 })
				answerList.push({ 'text': 'False', 'IsCorrect': knowledge.booleanAnswerCorrect == 0 ? 1 : 0, 'GivenAnswer': ((knowledge.answer === 3 && knowledge.booleanAnswerCorrect === 0) || (knowledge.answer === 2 && knowledge.booleanAnswerCorrect === 1)) ? 1 : 0, 'IsGivenAnswerCorrect': knowledge.answer == 3 ? 1 : 0 })
			} else {
				answerList = knowledge.answerList
			}
			for (var m = 0; m < answerList.length; m++) {
				let knowledgeAnswer = answerList[m]
				var temp = {
					'First Name': this.assessmentModel.firstName,
					'Last Name': this.assessmentModel.lastName,
					'Job Profile': this.assessmentModel.jobProfileDesc,
					'Date of Assessment': this.assessmentModel.selfAssessmentCompletedDate.toLocaleDateString(),
					'Core Competency': cc.text,
					'Task': task.text,
					'Task Regulatory Agency(s)': items,
					'Task Yes or N/A': task.isNA ? 'N/A' : task.isYes ? 'Yes' : '',
					'Task Reason for N/A': task.reasonNA,
					'Task Score': task.isNA ? '' : task.assessmentScore,
					'Knowledge Question': knowledge.knowledge,
					'Knowledge Question Answer': knowledgeAnswer.FileType ? "Visual Question" : knowledgeAnswer.text,
					"Knowledge Question Answer Yes or No": knowledgeAnswer.IsCorrect == 1 ? "Yes" : "No",
					"Candidates Knowledge Question Answer Chosen 1 or 0": knowledgeAnswer.GivenAnswer == 1 ? "1" : "0",
					"Candidates Knowledge Question Answer Correct Yes or No": knowledgeAnswer.IsGivenAnswerCorrect == 1 ? "Yes" : "No",
					"Candidate Answered the Complete Question Correct Yes or No": knowledge.answer == 3 ? "Yes" : "No",
					'Knowledge Check Score': this.correct + " out of " + this.total + " is correct",
				}
				data.push(temp)
			}
		}
		return data
	}


}