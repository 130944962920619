import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { ModalComponent } from 'ng2-bs3-modal/ng2-bs3-modal';
import { user } from './user';
import { UserService } from './user.service';
import { SharedService } from '../home/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { SanitizationService } from '../sanitizer.service';
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	providers: [UserService]
})
export class ProfileComponent implements OnInit {
	curUser: user = new user('', '');
	myForm: FormGroup;
	public selectedTimeZone: string;
	public timeZoneList = [];
	public myProfileData = [];
	constructor(private userService: UserService, private alertService: AlertService, private router: Router, private sharedService: SharedService,private sanitizer: SanitizationService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.myForm = new FormGroup({
			firstName: new FormControl(null, Validators.required),
			lastName: new FormControl(null, Validators.required),
			email: new FormControl(null, [
				Validators.required,
				Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
			]),
			timeZone: new FormControl(null, Validators.required)
		});

		let countries = localStorage.getItem('timeZones');
		if (countries != '' && countries != null) {
			let countryData = countries.split('}')
			for (var i = 0; i < countryData.length - 2; i++) {
				let timeZoneData = countryData[i].split(':');
				let country = timeZoneData[3].split(',')[0].slice(1, -1);
				let timeZones = timeZoneData[4].split(',');
				let zones: Array<any> = []
				for (var j = 0; j < timeZones.length; j++) {
					if (timeZones.length == 1) {
						let zoneText = timeZones[j].slice(2, -2)
						zones.push({ id: i + j, text: zoneText });
					}
					else if (j == 0) {
						let zoneText = timeZones[j].slice(2, -1)
						zones.push({ id: i + j, text: zoneText });
					}
					else if (j == timeZones.length - 1) {
						let zoneText = timeZones[j].slice(1, -2)
						zones.push({ id: i + j, text: zoneText });
					}
					else {
						let zoneText = timeZones[j].slice(1, -1)
						zones.push({ id: i + j, text: zoneText });
					}
				}
				this.timeZoneList.push({ id: i + 1, text: country, children: zones });
			}
			this.timeZoneList = this.timeZoneList.sort((a, b) => { return a.text.localeCompare(b.text) });
		}

		this.userService.getUserData()
			.subscribe(
			data => {				
				this.myForm.setValue({ firstName: data[0].FirstName, lastName: data[0].LastName, email: data[0].Email, timeZone: [data[0].TimeZone] });
				this.myProfileData.push({
					firstName: data[0].FirstName,
					lastName: data[0].LastName,
					email: data[0].Email,
					timeZone: data[0].TimeZone
				});
			},
			error => {				
			}
			);
	}


	public selected(value: any): void {
		this.selectedTimeZone = value.text;		
	}

	public removed(value: any): void {
		this.selectedTimeZone = "";		
	}

	public refreshValue(value: any): void {
		this.selectedTimeZone = value.text;		
	}


	onSubmit() {
		if (this.checkNeedToUpdateUserProfile()) {
			let timeZone = null;
			if (this.selectedTimeZone != "") {
				timeZone = this.selectedTimeZone;
			}
			const tempUser = new user(this.sanitizer.sanitize(this.myForm.value.email), '', localStorage.getItem('userId'), this.sanitizer.sanitize(this.myForm.value.firstName), this.sanitizer.sanitize(this.myForm.value.lastName), false, '', '', '', '', '', '', '', '', timeZone);
			//   console.log('[onSubmit]'+ JSON.stringify(tempUser).toString());
			this.userService.updateUserDetails(tempUser)
				.subscribe(
				data => {					
					this.alertService.success('Successfully Saved');
					this.myProfileData[0].firstName = this.sanitizer.sanitize(this.myForm.value.firstName);
					this.myProfileData[0].lastName = this.sanitizer.sanitize(this.myForm.value.lastName);
					this.myProfileData[0].email = this.sanitizer.sanitize(this.myForm.value.email);
					this.myProfileData[0].timeZone = timeZone;
				},
				error => {
					let err = error.json();
					this.alertService.handleError(err);
				}
				);
		} else {
			//no need to update the user no changes
			this.alertService.clear();
			this.alertService.error("There are no changes to update.");
		}
	}

	selectedComponentItem(itemName: string) {
		this.sharedService.change(itemName);
	}

	public checkNeedToUpdateUserProfile(): boolean {
		// console.log('[this.myForm.value]'+ JSON.stringify(this.myForm.value).toString());
		// console.log('[myProfilleDAta]'+ JSON.stringify(this.myProfileData).toString());
		if (this.myForm.value.firstName != this.myProfileData[0].firstName) {
			return true;
		} else if (this.myForm.value.lastName != this.myProfileData[0].lastName) {
			return true;
		} else if (this.myForm.value.email != this.myProfileData[0].email) {
			return true;
		} else if (this.myForm.value.timeZone != this.myProfileData[0].timeZone) {
			return true;
		} else {
			return false;
		}
	}
}
