import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { SharedService } from '../home/shared.service';
import { AlertService } from '../util/alert/alert.service';
import { UserService } from '../user/user.service';

import { orgtype } from './orgtype';
import { OrgtypeService } from './orgtype.service';
import { NoWhitespaceDirective } from "../util/validator/nowhitespace.directive"
import { SanitizationService } from '../sanitizer.service';

@Component({
	selector: 'app-orgtype',
	templateUrl: './addorgtype.component.html'
})

export class AddOrgTypeComponent implements OnInit {
	addOrgtypeForm: FormGroup;

	constructor(private sharedService: SharedService, private orgtypeService: OrgtypeService, private alertServices: AlertService, private userService: UserService,
		private sanitizer: SanitizationService) {
		if (!userService.isValidLoggedIn()) {
			userService.redirectToLoginScreen();
		}
	}

	ngOnInit() {
		this.addOrgtypeForm = new FormGroup({
			orgtype: new FormControl(null, Validators.required)
		});
	}

	//submit function of add orgtype form
	saveAndNew() {
		const tempOrgType = new orgtype('', this.sanitizer.sanitize(this.addOrgtypeForm.value.orgtype));
		this.orgtypeService.addOrgtype(tempOrgType)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.alertServices.success(data.message);
				// this.addOrgtypeForm.reset();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	//submit and close function of add orgtype form
	save() {
		const tempOrgType1 = new orgtype('', this.sanitizer.sanitize(this.addOrgtypeForm.value.orgtype));
		this.orgtypeService.addOrgtype(tempOrgType1)
			.subscribe(
			data => {
				this.alertServices.clear();
				this.cancel();
				// this.cancel();
			},
			error => {
				this.alertServices.clear();
				let err = error.json();
				this.alertServices.handleError(err);
			}
			);
	}

	//cancel function of add orgtype form
	cancel() {
		this.sharedService.change('OrgType');
	}


}